import React,{ useEffect, useState }  from 'react';

import ViewMorePage from './Pages/ViewMorePage';
import Header from './Pages/Header';
import Footer from './Pages/Footer';
import axios from 'axios';
import { myConfig } from './config';

function ViewMore(props) {
    const [results , setResults] = useState([]);
    const [catId , setCatId] = useState("");
    const [subCatId , setSubCatId] = useState("");
      useEffect(() => {
        const fetchData = async () => {
          const result = await axios(
            myConfig.server_url+'contentDetailByCategoryId/?categoryId='+props.match.params.cId+'&contrycode=91',{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
          );
          //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
          setResults(result.data.list);
          setCatId(props.match.params.cId);
          setSubCatId(props.match.params.scId);
        };
        fetchData();

      }, []);
     

    return (
        [
            <Header />,
            <ViewMorePage data = {{results,catId,subCatId}} />,
            <Footer />
        ]
    );
}

export default ViewMore;