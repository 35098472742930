import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import Forward10Icon from '@material-ui/icons/Forward10';
import Replay10Icon from '@material-ui/icons/Replay10';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import Popover from "@material-ui/core/Popover";

// const useStyles = makeStyles((theme) => ({
//   "@media (max-width: 280px)": {

//       controlsWrapper: {
//         position: "absolute",
//         top: 0,
//         left: 0,
//         right: 0,
//         bottom: 0,
//         height: "100%",
//         width:"100%",
//         // background: "rgb(0 0 0 / 18%);",
//         // display: "flex",
//         marginTop:"14vw",
//         flexDirection: "column",
//         justifyContent: "space-around",
//       },

      
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
  

//       button: {
//         // margin: theme.spacing(1),
//       },
//       controlIcons: {
//         color: "white",
//         fontSize:31,
//         marginleft:"-9px",
//         padding:"0px",
//         transform: "scale(0.9)",
//         "&:hover": {
//           color: "#fff",
//           transform: "scale(1)",
//         },
//       },

//       bottomIcons: {
//         color: "#fff",
//         padding:"10px",
//         "&:hover": {
//           color: "#fff",
//         },
//       },

//       volumeSlider: {
//         width: 90,
//         color: "#fff",
//       },
// },

// "@media (min-width: 281px) and (max-width: 400px)": {

//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"22vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },

//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:35,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"13px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 401px) and (max-width:450px)": {

//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"26vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },

//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 451px) and (max-width:500px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"26vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },

// },

// "@media (min-width: 501px) and (max-width:600px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"29vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },

//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 601px) and (max-width:700px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"32vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 701px) and (max-width:765px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"35vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 766px) and (max-width:800px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"21vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 801px) and (max-width:900px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"23vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },

// },

// "@media (min-width: 901px) and (max-width:1000px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"25vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },

// },

// "@media (min-width: 1001px) and (max-width:1095px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"25vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 1096px) and (max-width:1150px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"27vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width: 1151px) and (max-width:1300px)": {
//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"27vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 31,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },

// "@media (min-width:1300px)": {

//   controlsWrapper: {
//     position: "absolute",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     width:"100%",
//     // background: "rgb(0 0 0 / 18%);",
//     // display: "flex",
//     marginTop:"22vw",
//     flexDirection: "column",
//     justifyContent: "space-around",
//   },

//   controlsWrappers: {
//     position: "absolute",
//     top: "11vw",
//     left: 0,
//     right: 0,
//     bottom: 0,
//     height: "100%",
//     // background: "rgba(0,0,0,0.6)",
//     // display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   },
  
  
//   controlIcones: {
//     color: "white",
//     fontSize: 50,
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },
  
//   button: {
//     // margin: theme.spacing(1),
//   },
//   controlIcons: {
//     color: "white",
//     fontSize:40,
//     marginleft:"-9px",
//     padding:"-1px",
//     transform: "scale(0.9)",
//     "&:hover": {
//       color: "#fff",
//       transform: "scale(1)",
//     },
//   },

//   // controlIcones: {
//   //   color: "white",
//   //   fontSize: 50,
//   //   transform: "scale(0.9)",
//   //   "&:hover": {
//   //     color: "#fff",
//   //     transform: "scale(1)",
//   //   },
//   // },


//   bottomIcons: {
//     color: "#fff",
//     padding:"20px",
//     "&:hover": {
//       color: "#fff",
//     },
//   },

//   volumeSlider: {
//     width: 90,
//     color: "#fff",
//   },
// },
// // controlsWrapper: {
// //   position: "absolute",
// //   top: 0,
// //   left: 0,
// //   right: 0,
// //   bottom: 0,
// //   height: "100%",
// //   width:"100%",
// //   // background: "rgb(0 0 0 / 18%);",
// //   // display: "flex",
// //   marginTop:"21vw",
// //   flexDirection: "column",
// //   justifyContent: "space-around",
// // },

// // button: {
// //   // margin: theme.spacing(1),
// // },
// // controlIcons: {
// //   color: "white",
// //   fontSize:31,
// //   transform: "scale(0.9)",
// //   "&:hover": {
// //     color: "#fff",
// //     transform: "scale(1)",
// //   },
// // },

// // bottomIcons: {
// //   color: "#fff",
// //   "&:hover": {
// //     color: "#fff",
// //   },
// // },

// // volumeSlider: {
// //   width: 100,
// //   color: "#fff",
// // },
// }));

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#fff",

    fontSize: 30,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },

  bottomIcons: {
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  },

  volumeSlider: {
    width: 100,
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid white",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    // height: 8,
    borderRadius: 4,
  },
  rail: {
    // height: 8,
    borderRadius: 4,
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const Controls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRewind,
      onPlayPause,
      onFastForward,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      onToggleFullScreen,
      volume,
      onVolumeChange,
      onBookmark,
    },
    ref,ref1
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div>
        {/* <div  className={classes.controlsWrappers}>
          <Grid
            container
            direction="column"
            justify="space-between"
            style={{ flexGrow: 1 }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              style={{ padding: 5 }}
            >
            </Grid>
            <Grid container direction="row" alignItems="center" justify="center">
              <IconButton
                onClick={onRewind}
                className={classes.controlIcones}
                aria-label="rewind"
              >
                <Replay10Icon
                  className={classes.controlIcones}
                  fontSize="inherit"
                />
              </IconButton>
              <IconButton
                onClick={onPlayPause}
                className={classes.controlIcones}
                aria-label="play"
              >
                {playing ? (
                  <PauseIcon fontSize="inherit" />
                ) : (
                  <PlayArrowIcon fontSize="inherit" />
                )}
              </IconButton>
              <IconButton
                onClick={onFastForward}
                className={classes.controlIcones}
                aria-label="forward"
              >
                <Forward10Icon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </div> */}
    
        <div ref={ref} className={classes.controlsWrapper}>
          <Grid
            container
            direction="column"
            justify="space-between"
            style={{ flexGrow: 1 }}
            rowSpacing={0.5}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
              style={{ padding: 5 }}
            >
            </Grid>
            {/* center controls */}
            <Grid container direction="row" alignItems="center" justify="center"  rowSpacing={1}>
              <IconButton
                onClick={onRewind}
                className={classes.controlIcones}
                aria-label="rewind"
              >
                <Replay10Icon
                  className={classes.controlIcones}
                  fontSize="inherit"
                  style={{ color: "#fff"}}
                />
              </IconButton>
              <IconButton
                onClick={onPlayPause}
                className={classes.controlIcones}
                aria-label="play"
              >
                {playing ? (
                  <PauseIcon fontSize="inherit" style={{ color: "#fff"}} />
                ) : (
                  <PlayArrowIcon fontSize="inherit"  style={{ color: "#fff"}}/>
                )}
              </IconButton>
              <IconButton
                onClick={onFastForward}
                className={classes.controlIcones}
                aria-label="forward"
              >
                <Forward10Icon fontSize="inherit" style={{ color: "#fff"}} />
              </IconButton>
            </Grid>
            {/* bottom controls */}
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ padding: 5 }}
              rowSpacing={{xs: 2,md: 3}}
            >
              <Grid item xs={11}>
                <PrettoSlider
                  min={0}
                  max={100}
                  width="80%"
                  ValueLabelComponent={(props) => (
                    <ValueLabelComponent {...props} value={elapsedTime} />
                  )}
                  aria-label="custom thumb label"
                  value={played * 100}
                  style={{color: "#fff"}}
                  onChange={onSeek}
                  onMouseDown={onSeekMouseDown}
                  onChangeCommitted={onSeekMouseUp}
                  onDuration={onDuration}
                />
              
              </Grid>
              <Grid item xs={1} alignItems="center">
              <Typography
                      variant="body1"
                      style={{ color: "#fff", marginLeft: 5, textAlign:"center" }}
                    >
                    {elapsedTime}
              </Typography>
              </Grid>
              <Grid item xs={11} rowSpacing={1}>
                <Grid container>
                      <Grid item  container alignItems="center">
                        <IconButton
                          onClick={onRewind}
                          className={classes.controlIcons}
                          aria-label="rewind"
                          >
                          <FastRewindIcon
                            className={classes.controlIcons}
                            fontSize="inherit"
                          />
                        </IconButton>
                        <IconButton
                          onClick={onPlayPause}
                          className={classes.bottomIcons}
                        >
                          {playing ? (
                            <PauseIcon />
                          ) : (
                            <PlayArrowIcon />
                          )}
                        </IconButton>

                        <IconButton
                          onClick={onFastForward}
                          className={classes.controlIcons}
                          aria-label="forward"
                        >
                          <FastForwardIcon fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          // onClick={() => setState({ ...state, muted: !state.muted })}
                          onClick={onMute}
                          className={`${classes.bottomIcons} ${classes.volumeButton}`}
                        >
                          {muted ? (
                            <VolumeMute />
                          ) : volume > 0.5 ? (
                            <VolumeUp />
                          ) : (
                            <VolumeDown />
                          )}
                        </IconButton>

                        <Slider
                          min={0}
                          max={100}
                          value={muted ? 0 : volume * 100}
                          onChange={onVolumeChange}
                          aria-labelledby="input-slider"
                          className={classes.volumeSlider}
                          onMouseDown={onSeekMouseDown}
                          onChangeCommitted={onVolumeSeekDown}
                        />
                      </Grid>
                </Grid>
              </Grid>
              <Grid item xs={1} alignItems="center">
                  <IconButton
                    onClick={onToggleFullScreen}
                    className={classes.bottomIcons}
                  >
                    <FullScreen fontSize="large" style={{ color: "#fff",textAlign:"left"}}/>
                  </IconButton>
              </Grid>
            </Grid>
          </Grid>
      </div>
      </div>
    );
  }
);

Controls.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
};
export default Controls;