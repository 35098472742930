import React, {Component} from 'react';

class Tic extends Component {
    render() {
	    return (
            <div className="tic">
                <div className="ticContent fl">
                    <div className="heading">The World of Sport in the Palm of your Hand.</div>
                    {/* <div className="content">Amet minim mollit non deserunt ullamco est sit<br />aliqua dolor do amet sint.</div> */}
                    <div className="imageFols">
                        <a href="javascript:void(0);"><img src="/asset/images/Group(1).svg" alt="" /></a>
                        <img src="/asset/images/Line5.svg" alt="" />
                        <a href="javascript:void(0);"><img src="/asset/images/Group.svg" alt="" /></a>
                    </div>
                </div>
                <div className="image fr">
                    <img src="/asset/images/Group36665.png" alt="" />
                </div>
                <div className="clr"></div>
            </div>
        )
    }
}

export default Tic;
