import React, {Component} from 'react';
import { myConfig } from '../config';
import axios from 'axios';
import Player from './Player';

class PlayerHeader extends Component {
    state = {
        loading : true
    };

    render() {
	    return (
                <div class="innerS">
                    <div class="container">
                        <div class="row">
                            <div class="col70 left fl">
                                <div class="player playerContainer">
                                        <Player srcPlayer={this.props.content[0]}/>
                                        
                                    </div>
                                <div class="osDetails">
                                    <div class="info fl">
                                        <div class="name">{this.props.content[0].contentTitle}</div>
                                        <div class="time">2021 • {this.props.content[0].duration}</div>
                                        {/*<div class="cap"></div>*/}
                                    </div>
                                    <div class="icons fr">
                                        {/* <a href="">
                                            <span class="watch">Watchlist</span>
                                        </a>
                                        <a href="">
                                            <span class="share">Share</span>
                                        </a>
                                        <a href="">
                                            <span class="download">Download</span>
                                        </a> */}
                                    </div>
                                    <div class="clr"></div>
                                </div>
                                <div class="description">
                                    <div class="cap">Description</div>
                                    <p>{this.props.content[0].contentDescription}</p>
                                </div>
                            </div>
                            <div class="col30 right fr">
                                <div class="title">Series for you </div>
                                {
                                    
                                    this.props.content[1].map((cat)=>{
                                        {  
                                            return(
                                                cat.subcategories.map((sCat)=>{
                                                    return(
                                                     sCat.contentDetails.slice(0,3).map((content1)=>{
                                                        return (
                                                            <div class="image fl">
                                                            <a href={"/details/"+content1.contentDtl.id+"/"+sCat.subcategoryId+"/"+cat.categoryId}>
                                                                <div class="h6">
                                                                    <img src={content1.contentDtl.thumbnilUrl} alt="" class="resize" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                        )
                                                     })
                                                    )
                                                     
                                                     
                                                 }) 
                                               
                                            )
                                           
                                               
                                         }
                                    })
                                }
                                
                                <div class="clr"></div>
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>
                </div>
        )
    }
}

export default PlayerHeader;
