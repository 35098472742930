import React, {Component} from 'react';

class Testimonial extends Component {
    render() {
	    return (
            <div className="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title">What our user's<br />
                                syas<a href="" className="fr">View More</a><div className="clr"></div></div>
                        </div>
                    </div>
                    <div className="clr"></div>
                    <div className="row">
                        <div className="testimonialSlider">
                            <div className="col">
                                <div className="testBlock">
                                    <div className="image">
                                        <img src="/asset/images/Rectangle1162.png" alt="" />
                                    </div>
                                    <div className="content">
                                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                    </div>
                                    <div className="rating">
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star2.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="testBlock">
                                    <div className="image">
                                        <img src="/asset/images/Rectangle1162.png" alt="" />
                                    </div>
                                    <div className="content">
                                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                    </div>
                                    <div className="rating">
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star2.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="testBlock">
                                    <div className="image">
                                        <img src="/asset/images/Rectangle1162.png" alt="" />
                                    </div>
                                    <div className="content">
                                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                    </div>
                                    <div className="rating">
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star2.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="testBlock">
                                    <div className="image">
                                        <img src="/asset/images/Rectangle1162.png" alt="" />
                                    </div>
                                    <div className="content">
                                        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                                    </div>
                                    <div className="rating">
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star5.svg" alt="" />
                                        <img src="/asset/images/star2.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="clr"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Testimonial;
