import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { myConfig } from "../config";
import { useHistory, useParams } from "react-router-dom";
import { Cancel, Flag, SettingsPhone } from "@material-ui/icons";
import codeJson from "../CountryCodes.json";
import lang from "./lang.json";
function LandingPageNew(props) {
  const [faqs, setFaqs] = useState([]);
  const [productdetails, setproductdetails] = useState({});
  const [lan, setlan] = useState({});
  const [trakinid, settrakingid] = useState("");
  const [countycoded, setcountrycode] = useState("");
  const [dubai, setdubai] = useState(/dubai/);
  const [zong, setzong] = useState(/zong/);
  const [virgin, setvirgin] = useState(/virgin/);
  const [voda, setvoda] = useState(/voda/);
  const [beeline, setbeeline] = useState(/beeline/);
  const [tmobile, settmobile] = useState(/tmobile/);
  const [orange, setorange] = useState(/orange/);
  const [sierra, setsierra] = useState(/sierra/);
  const [play, setplay] = useState(/play/);
  const [plus, setplus] = useState(/plus/);
  const [grameen, setgrameen] = useState(/grameen/);
  const [phone, getphone] = useState("");
  const [phones, getphones] = useState("");
  const [sCountryCode, setsCountrycode] = useState();
  const [userip, setuserip] = useState("");
  const [browser, setbrowser] = useState("");
  const [operator_id, setoperator_id] = useState(0);
  const [otp, getotp] = useState("");
  const [conuty, getcountry] = useState("");
  const [conutys, getcountrys] = useState("");
  const [cgflow, setcgflow] = useState(false);
  const [checkflow, setcheckflow] = useState(false);
  const [show1, setshow1] = useState(false);
  const [show2, setshow2] = useState(false);
  const [IsUpdate, setIsUpdate] = useState(false);
  const [playtype, setplantype] = useState("daily");
  const [service_no, setserviceno] = useState("skysport_daily");
  const [plan, setplan] = useState("daily");
  const [bulletImage, setbulletImage] = useState(
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAdCAYAAABbjRdIAAAAAXNSR0IArs4c6QAAAhBJREFUSEu9ljtoFVEQhr9fELSwU1Ja+OhUEIuA2oqN+ABtxSKKxGCl+MBGSETsLNSIFoIWivhqrCzEEDurhGgr+AAV1EoT8ZeBc0K82b2792bvnXbZ+c78Z878I/oYyizbm4Gvkj72ij8PC4Dt58BTYFySm4a2wvYBj4EJYEjS2yaBrbBlwDtgHTAHjAGXJM02Af0PlqQ8HgDgInAB+AQclRTVLimKYCuB98Bl4B5wFTgA3ATOSPrRLXERLFUXVR0OOSX9sb0HuAaEzCOSHnUDLIOtTtWFfHfTAVYBo8Aw8Aw4IelDJ9BCWEoesm2TtHVhQtuDSdK1wFnghqS/daDtYBuAaP1dkl60AJcDp4HzwBvgmKTpKmApLFX3BFghaXdRItsbozJge2qoUUm/y6BVsJ3AS2BT2cltR44jwBXgSxoGr4qAbWGputfAN+BOhUwD6V2uAW6FzJK+L/ynDuwQcL/qPgq+f07P5GH+Vgd2EHjQJeykpPl/68BCxmj3uhFucRs41ZGMtncAhZddQo4hHs8gmmpRVHVjtP7eGiWFK8QsHZP0q+PWt50fdczDdjGZqpmqOlTVuBpqk+AncA64vqRxZTsP4rCboojVYbiRQWw7G2crKJah5izGdjbPqC5HtPN44+ZpO9aCMMocMz1ZC2znhWc9EO3cu4XH9n4gLL8vq1x/llTbW8KPerl+/wOf+cwegfM+6gAAAABJRU5ErkJggg=="
  );
  const [aff_id, setaff_id] = useState(0);
  const [click_id, setclick_id] = useState("");
  const [camp_id, setcamp_id] = useState(0);
  let history = useHistory();
  let { id1 } = useParams();

  async function checkActiveEffId(effId) {
    var otpurl = myConfig.billing_url + "check_affiliate.php?aff_id=" + effId;
    console.log(otpurl);
    axios.get(otpurl).then((Response) => {
      if (Response.data == "ACTIVE") {
      } else {
        window.location.href = "/fail?active=false";
      }
    });
  }
  async function subConfirm() {
    //   if (window.confirm("ნომრის "+localStorage.getItem('bmsisdn')+" ბალანსიდან ყოველდღე ჩამოიჭრება სერვისის საფასური 2 ₾. ადასტურებ სერვისის აქტივაციას?") == true) {
    // if (window.confirm(myConfig.billing_url+"subscription.php?affiliate="+aff_id+"&cg_image_url=&mode=WAP&msisdn="+localStorage.getItem('bmsisdn')+"&product_id="+productdetails.product_id+"&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&aff_id="+aff_id+"&camp_id="+camp_id+"&other1=do_billing&other2=") == true) {

    var otpurl =
      myConfig.billing_url +
      "subscription.php?affiliate=" +
      localStorage.getItem("aff_id") +
      "&cg_image_url=&mode=WAP&msisdn=" +
      localStorage.getItem("bmsisdn") +
      "&product_id=" +
      productdetails.product_id +
      "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&aff_id=" +
      localStorage.getItem("aff_id") +
      "&camp_id=" +
      localStorage.getItem("camp_id") +
      "&other1=do_billing&other2=&aff_id=" +
      localStorage.getItem("aff_id") +
      "&aff_uniq_id=" +
      localStorage.getItem("click_id") +
      "&camp_id=" +
      localStorage.getItem("camp_id");
    var queryparams =
      "affiliate = " +
      localStorage.getItem("aff_id") +
      " | cg_image_url =  |mode = WAP | msisdn=" +
      localStorage.getItem("bmsisdn") +
      "| product_id = " +
      productdetails.product_id +
      "| result_url = https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F | camp_id= " +
      localStorage.getItem("camp_id") +
      "| other1 = do_billing | other2 = &aff_id=" +
      localStorage.getItem("aff_id") +
      "&aff_uniq_id=" +
      localStorage.getItem("click_id") +
      "&camp_id=" +
      localStorage.getItem("camp_id");
    var gettime = new Date();
    var veriftotpurlreq =
      gettime + "| REQ |" + localStorage.getItem("bmsisdn") + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams;
    let finalobj9 = {
      security: "col#c4",
      logData: veriftotpurlreq,
    };
    axios.post(myConfig.billing_url + "appLog.php", finalobj9);
    axios.get(otpurl).then((Response) => {
      var gettime = new Date();
      var veriftotpurlres =
        gettime + "| RES |" + localStorage.getItem("bmsisdn") + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
      var finalobj5 = {
        security: "col#c4",
        logData: veriftotpurlres,
      };
      axios.post(myConfig.billing_url + "appLog.php", finalobj5);
      localStorage.removeItem("bmsisdn");
      localStorage.removeItem("aff_id");
      if (Response.data.status == "OK") {
        window.location.href = "/welcome";
      } else {
        window.location.href = "/fail";
      }
    });
  }
  async function callCancel() {
    localStorage.removeItem("bmsisdn");
    localStorage.removeItem("aff_id");
    window.location.href = "/fail";
  }
  useEffect(() => {
    console.log(virgin.test(window.location.pathname))
    console.log(virgin.test(window.location.pathname))
    // console.log(window.location.href)
    // var url = new URL(window.location.href)
    // var plans = url.searchParams.get('plan')
    // var aff_ids = url.searchParams.get('aff_id')
    // var click_ids = url.searchParams.get('click_id')
    // var camp_ids = url.searchParams.get('camp_id')
    // setplan(plans != null ? plans : '')
    // setaff_id(aff_ids != null ? aff_ids : 0)
    // setclick_id(click_ids != null ? click_ids : 0)
    // setcamp_id(camp_ids != null ? camp_ids : 0)
    // console.log("plan" ,plan);
    localStorage.setItem("activetab", "promonew");
    var str = localStorage.getItem("lang");
    if (dubai.test(window.location.pathname) == true) {
      console.log("in", str);
      if (str == undefined) {
        str = localStorage.setItem("lang", "en");
      } else {
        console.log("else", str);
        if (str == "en" || str == "ar") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "en");
          str = "en";
        }
      }

      var getnumber = window.location.search.split("?");
      if (getnumber.length > 1) {
        var url = new URL(window.location.href);
        var plans = url.searchParams.get("plan");
        var aff_ids = url.searchParams.get("aff_id");
        var click_ids = url.searchParams.get("click_id");
        var camp_ids = url.searchParams.get("camp_id");
        setplan(plans);
        if (plans == "weekly") {
          localStorage.setItem("plan", "weekly");
        } else {
          localStorage.setItem("plan", "daily");
        }
        checkActiveEffId(aff_ids);
        setaff_id(aff_ids);
        setclick_id(click_ids);
        setcamp_id(camp_ids);
        setoperator_id(144);
      }

      setcheckflow(false);
      setcgflow(false);
    } else if (zong.test(window.location.pathname) == true) {
      console.log("in", str);
      if (str == undefined) {
        str = localStorage.setItem("lang", "zoen");
      } else {
        console.log("else", str);
        if (str == "zoen") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "zoen");
          str = "zoen";
        }
      }

      var getnumber = window.location.search.split("?");
      if (getnumber.length > 1) {
        var url = new URL(window.location.href);
        var plans = url.searchParams.get("plan");
        var aff_ids = url.searchParams.get("aff_id");
        var click_ids = url.searchParams.get("click_id");
        var camp_ids = url.searchParams.get("camp_id");
        setplan(plans);
        if (plans == "weekly") {
          localStorage.setItem("plan", "weekly");
        } else {
          localStorage.setItem("plan", "daily");
        }
        setaff_id(aff_ids);
        setclick_id(click_ids);
        setcamp_id(camp_ids);
        setoperator_id(144);
      }

      setcheckflow(false);
      setcgflow(false);
    } else if (grameen.test(window.location.pathname) == true) {
      console.log("in", str);
      if (str == undefined) {
        str = localStorage.setItem("lang", "en_gr");
      } else {
        console.log("else", str);
        if (str == "en_gr") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "en_gr");
          str = "en_gr";
        }
      }
      // var getnumber = window.location.search.split('?')
      var url = new URL(window.location.href);
      var plans = url.searchParams.get("plan");
      // var aff_ids = url.searchParams.get('aff_id')
      // var click_ids = url.searchParams.get('click_id')
      // var camp_ids = url.searchParams.get('camp_id')
      setplan(plans);
      if (window.location.search == "") {
        setcheckflow(true);
        setcgflow(false);
      } else {
        var getnumber = window.location.search.split("=");
        console.log(getnumber);
        if (getnumber[0] == "?plan") {
          console.log("in", str);
          setoperator_id(144);
          if (/daily/i.test(getnumber[1]) == true) {
            var strssssssssss = "skysport_" + getnumber[1];
            localStorage.setItem("plan", "daily");
            setserviceno(strssssssssss);
            setcheckflow(true);
            setcgflow(false);
          }
          if (/weekly/i.test(getnumber[1]) == true) {
            var strssssssssss = "skysport_" + getnumber[1];
            localStorage.setItem("plan", "weekly");
            setserviceno(strssssssssss);
            setcheckflow(true);
            setcgflow(false);
          }
          var strs = getnumber[2].split("&");
          setaff_id(parseInt(strs[0]));
          localStorage.setItem("aff_id", url.searchParams.get("aff_id"));
          //var bMsisdn = url.searchParams.get('aff_id')
          var strss = getnumber[3].split("&");
          setclick_id(strss[0]);
          console.log(getnumber, strss[0]);
          setcamp_id(parseInt(getnumber[4]));
        }
        if (getnumber[0] == "?msisdn") {
          setoperator_id(144);
          if (getnumber[1] == "") {
            completecgflow();
            setcheckflow(false);
            setcgflow(true);
            /*setcheckflow(false)
                setcgflow(false)*/
          } else {
            completecgflow();
            setcheckflow(false);
            setcgflow(true);
          }
        }
      }
      // if(plans == "weekly"){
      //   localStorage.setItem("plan","weekly")
      // }else{
      //   localStorage.setItem("plan","daily")
      // }
      // setaff_id(aff_ids)
      // setclick_id(click_ids)
      // setcamp_id(camp_ids)
      // setcheckflow(false)
      // setcgflow(false)
    } else if (tmobile.test(window.location.pathname) == true) {
      console.log("in", str);
      if (str == undefined) {
        str = localStorage.setItem("lang", "plten");
      } else {
        if (str == "plten") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "plten");
          str = "plten";
        }
      }

      var getnumber = window.location.search.split("?");
      if (getnumber.length > 1) {
        var url = new URL(window.location.href);
        var plans = url.searchParams.get("plan");
        var aff_ids = url.searchParams.get("aff_id");
        var click_ids = url.searchParams.get("click_id");
        var camp_ids = url.searchParams.get("camp_id");
        setplan(plans);
        if (plans == "weekly") {
          localStorage.setItem("plan", "weekly");
        } else {
          localStorage.setItem("plan", "daily");
        }
        setaff_id(aff_ids);
        setclick_id(click_ids);
        setcamp_id(camp_ids);
      }

      setcheckflow(false);
      setcgflow(false);
    } else if (orange.test(window.location.pathname) == true) {
      console.log("in", str);
      //ploen
      if (str == undefined) {
        str = localStorage.setItem("lang", "ploen");
        var getnumber = window.location.search.split("?");
        var burl = new URL(window.location.href);
        var urlAff_id = burl.searchParams.get("aff_id");
        var click_ids = burl.searchParams.get("click_id");
        var camp_ids = burl.searchParams.get("camp_id");
        localStorage.setItem("aff_id", urlAff_id);
        localStorage.setItem("click_id", click_ids);
        localStorage.setItem("camp_id", camp_ids);
        if (getnumber.length > 1) {
          setoperator_id(239);
          var chnages = getnumber[1].split("&");
          var strssssss = chnages[0].split("=");
          setplan(strssssss[1]);
          localStorage.setItem("plan", "daily");
          var strs = chnages[1].split("=");
          setaff_id(parseInt(strs[1]));

          var strss = chnages[2].split("=");
          setclick_id(strss[1]);
          var strsss = chnages[3].split("=");
          setcamp_id(parseInt(strsss[1]));
          console.log("tye in");
          if (chnages.length > 4) {
            console.log(chnages.length);
            var wifi = chnages[4].split("=");
            if (wifi[1] == "no") {
              setcheckflow(true);
              setcgflow(false);
            } else {
              setcheckflow(false);
              setcgflow(false);
            }
          } else {
            console.log("tye in");
            setcheckflow(true);
            setcgflow(false);
          }
        }
      } else {
        if (str == "ploen" || str == "ploen") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "ploen");
          str = "ploen";
        }
        var getnumber = window.location.search.split("?");
        if (getnumber.length > 1) {
          setoperator_id(239);
          var burl = new URL(window.location.href);
          var bMsisdn = burl.searchParams.get("dMsisdn");
          var urlAff_id = burl.searchParams.get("aff_id");
          var click_ids = burl.searchParams.get("click_id");
          var camp_ids = burl.searchParams.get("camp_id");
          if (urlAff_id != null && urlAff_id != undefined && urlAff_id != "") {
            localStorage.setItem("aff_id", urlAff_id);
          }
          if (click_ids != null && click_ids != undefined && click_ids != "") {
            localStorage.setItem("click_id", click_ids);
          }
          if (camp_ids != null && camp_ids != undefined && camp_ids != "") {
            localStorage.setItem("camp_id", camp_ids);
          }

          if (bMsisdn != null && bMsisdn != undefined && bMsisdn != "") {
            localStorage.setItem("bmsisdn", bMsisdn);
            setcheckflow(true);
            setcgflow(false);
          } else {
            var chnages = getnumber[1].split("&");
            var strssssss = chnages[0].split("=");
            setplan(strssssss[1]);
            localStorage.setItem("plan", "daily");
            var strs = chnages[1].split("=");
            setaff_id(parseInt(strs[1]));
            var strss = chnages[2].split("=");
            setclick_id(strss[1]);
            var strsss = chnages[3].split("=");
            setcamp_id(parseInt(strsss[1]));
            if (chnages.length > 4) {
              console.log(chnages.length);
              var wifi = chnages[4].split("=");

              if (wifi[1] == "no") {
                setcheckflow(true);
                setcgflow(false);
              } else {
                setcheckflow(false);
                setcgflow(false);
              }
            } else {
              console.log("tye in");
              setcheckflow(true);
              setcgflow(false);
            }
          }
        }
      }
    } else if (sierra.test(window.location.pathname) == true) {
      //oslen
      if (str == undefined) {
        str = localStorage.setItem("lang", "oslen");
        var getnumber = window.location.search.split("?");
        var burl = new URL(window.location.href);
        var urlAff_id = burl.searchParams.get("aff_id");
        var click_ids = burl.searchParams.get("click_id");
        var camp_ids = burl.searchParams.get("camp_id");
        var plan = burl.searchParams.get("plan");
        localStorage.setItem("aff_id", urlAff_id);
        localStorage.setItem("click_id", click_ids);
        localStorage.setItem("camp_id", camp_ids);
        if (getnumber.length > 1) {
          setoperator_id(239);
          var chnages = getnumber[1].split("&");
          var strssssss = chnages[0].split("=");
          setplan(plan);
          localStorage.setItem("plan", plan);
          var strs = chnages[1].split("=");
          setaff_id(parseInt(strs[1]));

          var strss = chnages[2].split("=");
          setclick_id(strss[1]);
          var strsss = chnages[3].split("=");
          setcamp_id(parseInt(strsss[1]));
          console.log("tye in");
          if (chnages.length > 4) {
            console.log(chnages.length);
            var wifi = chnages[4].split("=");
            if (wifi[1] == "no") {
              setcheckflow(true);
              setcgflow(false);
            } else {
              setcheckflow(false);
              setcgflow(false);
            }
          } else {
            console.log("tye in");
            setcheckflow(true);
            setcgflow(false);
          }
        }
      } else {
        if (str == "oslen" || str == "oslen") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "oslen");
          str = "oslen";
        }
        /**
         *   var burl = new URL(window.location.href)
        var bMsisdn = burl.searchParams.get('dMsisdn') 
        if(bMsisdn){
            console.log(bMsisdn);
        } 
         */
        var getnumber = window.location.search.split("?");
        if (getnumber.length > 1) {
          setoperator_id(239);
          var burl = new URL(window.location.href);
          var bMsisdn = burl.searchParams.get("dMsisdn");
          var urlAff_id = burl.searchParams.get("aff_id");
          var click_ids = burl.searchParams.get("click_id");
          var camp_ids = burl.searchParams.get("camp_id");
          if (urlAff_id != null && urlAff_id != undefined && urlAff_id != "") {
            localStorage.setItem("aff_id", urlAff_id);
          }
          if (click_ids != null && click_ids != undefined && click_ids != "") {
            localStorage.setItem("click_id", click_ids);
          }
          if (camp_ids != null && camp_ids != undefined && camp_ids != "") {
            localStorage.setItem("camp_id", camp_ids);
          }

          if (bMsisdn != null && bMsisdn != undefined && bMsisdn != "") {
            localStorage.setItem("bmsisdn", bMsisdn);
            setcheckflow(true);
            setcgflow(false);
          } else {
            var chnages = getnumber[1].split("&");
            var strssssss = chnages[0].split("=");
            setplan(strssssss[1]);
            localStorage.setItem("plan", strssssss[1]);
            var strs = chnages[1].split("=");
            setaff_id(parseInt(strs[1]));
            var strss = chnages[2].split("=");
            setclick_id(strss[1]);
            var strsss = chnages[3].split("=");
            setcamp_id(parseInt(strsss[1]));
            if (chnages.length > 4) {
              console.log(chnages.length);
              var wifi = chnages[4].split("=");

              if (wifi[1] == "no") {
                setcheckflow(true);
                setcgflow(false);
              } else {
                setcheckflow(false);
                setcgflow(false);
              }
            } else {
              console.log("tye in");
              setcheckflow(true);
              setcgflow(false);
            }
          }
        }
      }
    } else if (play.test(window.location.pathname) == true) {
      console.log("in", str);
      if (str == undefined) {
        str = localStorage.setItem("lang", "plpen");
      } else {
        if (str == "plpen") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "plpen");
          str = "plpen";
        }
      }

      var getnumber = window.location.search.split("?");
      if (getnumber.length > 1) {
        var url = new URL(window.location.href);
        var plans = url.searchParams.get("plan");
        var aff_ids = url.searchParams.get("aff_id");
        var click_ids = url.searchParams.get("click_id");
        var camp_ids = url.searchParams.get("camp_id");
        setplan(plans);
        if (plans == "weekly") {
          localStorage.setItem("plan", "weekly");
        } else {
          localStorage.setItem("plan", "daily");
        }
        setaff_id(aff_ids);
        setclick_id(click_ids);
        setcamp_id(camp_ids);
      }

      setcheckflow(false);
      setcgflow(false);
    } else if (plus.test(window.location.pathname) == true) {
      console.log("in", str);
      if (str == undefined) {
        str = localStorage.setItem("lang", "plppen");
      } else {
        if (str == "plppen") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "plppen");
          str = "plppen";
        }
      }

      var getnumber = window.location.search.split("?");
      if (getnumber.length > 1) {
        var url = new URL(window.location.href);
        var plans = url.searchParams.get("plan");
        var aff_ids = url.searchParams.get("aff_id");
        var click_ids = url.searchParams.get("click_id");
        var camp_ids = url.searchParams.get("camp_id");
        setplan(plans);
        if (plans == "weekly") {
          localStorage.setItem("plan", "weekly");
        } else {
          localStorage.setItem("plan", "daily");
        }
        setaff_id(aff_ids);
        setclick_id(click_ids);
        setcamp_id(camp_ids);
      }

      setcheckflow(false);
      setcgflow(false);
    } else if (virgin.test(window.location.pathname) == true) {
      var getnumber = window.location.search.split("?");
      if (str == undefined) {
        str = localStorage.setItem("lang", "ven");
      } else {
        if (str == "ven" || str == "var") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "ven");
          str = "ven";
        }
        // var getnumber = window.location.search.split('?')
        // if(getnumber.length > 1) {
        //   var chnages = getnumber[1].split('&')
        //   var strs = chnages[0].split('=')
        //   setplan(strs[1])
        //   localStorage.setItem("plan",'daily')
        //   var strs = chnages[1].split('=')
        //   console.log("strs[1]:"+strs[1])
        //   setaff_id(parseInt(strs[1]))
        //   var strss = chnages[2].split('=')
        //   setclick_id(parseInt(strss[1]))
        // }
      }
      if (getnumber.length > 1) {
        var chnages = getnumber[1].split("&");
        var strs = chnages[0].split("=");
        console.log(chnages, strs);
        if (strs[0] == "ref") {
          setoperator_id(238);
          var plans = strs[1].split("_");
          setplan(plans[0]);
          setaff_id(plans[1]);
          setclick_id(plans[2]);
          setcamp_id(plans[3]);
          var msisdn = chnages[2].split("=");
          getphone(msisdn[1]);
          getphones(msisdn[1]);
          localStorage.setItem("update", "0");
          var gettime = new Date();
          var veriftotpurlreq =
            virgin.test(window.location.pathname) == false || phones == ""
              ? gettime +
                "| REQ |" +
                conuty +
                phone +
                "|" +
                myConfig.billing_url +
                "subscription.php" +
                "| POST Parameters =>" +
                window.location.search
              : gettime + "| REQ |" + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + window.location.search;
          var finalobj9 = {
            security: "col#c4",
            logData: veriftotpurlreq,
          };
          axios.post(myConfig.billing_url + "appLog.php", finalobj9);

          axios.post(myConfig.billing_url + "stagingCount.php?aff_id=" + plans[1] + "&operator_id=" + 238 + "&auto_detect_mobile_count=1");

          var veriftotpurlreq =
            virgin.test(window.location.pathname) == false || phones == ""
              ? gettime +
                "| REQ |" +
                conuty +
                phone +
                "|" +
                myConfig.billing_url +
                "stagingCount.php" +
                "| POST Parameters =>" +
                "aff_id=" +
                plans[1] +
                "&operator_id=" +
                238 +
                "&auto_detect_mobile_count=1"
              : gettime +
                "| REQ |" +
                phone +
                "|" +
                myConfig.billing_url +
                "stagingCount.php" +
                "| POST Parameters =>" +
                "aff_id=" +
                plans[1] +
                "&operator_id=" +
                238 +
                "&auto_detect_mobile_count=1";
          var finalobj21 = {
            security: "col#c4",
            logData: veriftotpurlreq,
          };
          axios.post(myConfig.billing_url + "appLog.php", finalobj21);
        } else if (strs[0] == "plan") {
          setoperator_id(238);
          setplan(strs[1]);
          localStorage.setItem("plan", "daily");
          var strs = chnages[1].split("=");
          console.log("strs[1]:" + strs[1]);
          setaff_id(parseInt(strs[1]));
          localStorage.setItem("aff_id", parseInt(strs[1]));
          var strss = chnages[2].split("=");
          setclick_id(strss[1]);
          localStorage.setItem("click_id", strss[1]);
          var strsss = chnages[3].split("=");
          setcamp_id(parseInt(strsss[1]));
          localStorage.setItem("camp_id", parseInt(strsss[1]));
          setIsUpdate(true);
          localStorage.setItem("update", "1");
        }
      }

      setcheckflow(false);
      setcgflow(false);
    } else if (voda.test(window.location.pathname) == true) {
      if (str == undefined) {
        str = localStorage.setItem("lang", "voen");
        // var getnumber = window.location.search.split('=')
        // console.log(getnumber);
        // if (window.location.search == '') {
        //   setserviceno("skysport_daily")
        //   localStorage.setItem("plan","daily")
        //   setcheckflow(true)
        //   console.log("yes")
        //   setcgflow(false)
        // }else {
        //   console.log("in")
        //     if(getnumber[0] == "?plan") {
        //       if (/daily/i.test(getnumber[1]) == true) {
        //         var strssssssssss = "skysport_" + getnumber[1]
        //         localStorage.setItem("plan","daily")
        //         setserviceno(strssssssssss)
        //         setcheckflow(true)
        //         setcgflow(false)
        //       }
        //       if (/weekly/i.test(getnumber[1]) == true) {
        //         var strssssssssss = "skysport_" + getnumber[1]
        //         localStorage.setItem("plan","weekly")
        //         setserviceno(strssssssssss)
        //         setcheckflow(true)
        //         setcgflow(false)
        //       }

        //     }
        //     if (getnumber[0] == "?msisdn") {
        //       if (getnumber[1] == '') {
        //         completecgflow()
        //         setcheckflow(false)
        //         setcgflow(true)
        //         /*setcheckflow(false)
        //         setcgflow(false)*/
        //       }else {
        //         completecgflow()
        //         setcheckflow(false)
        //         setcgflow(true)
        //       }
        //     }
        //   }
      } else {
        if (str == "voen") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "voen");
          str = "voen";
        }
      }
      if (window.location.search == "") {
        // setserviceno("skysport_daily")
        // localStorage.setItem("plan","daily")
        setcheckflow(true);
        // console.log("yes")
        setcgflow(false);
      } else {
        var getnumber = window.location.search.split("=");
        console.log("voad watara");
        if (getnumber[0] == "?plan") {
          setoperator_id(240);
          if (/daily/i.test(getnumber[1]) == true) {
            var strssssssssss = "skysport_" + getnumber[1];
            localStorage.setItem("plan", "daily");
            setserviceno(strssssssssss);
            setcheckflow(true);
            setcgflow(false);
          }
          if (/weekly/i.test(getnumber[1]) == true) {
            var strssssssssss = "skysport_" + getnumber[1];
            localStorage.setItem("plan", "weekly");
            setserviceno(strssssssssss);
            setcheckflow(true);
            setcgflow(false);
          }
          var strs = getnumber[2].split("&");
          setaff_id(parseInt(strs[0]));
          var strss = getnumber[3].split("&");
          setclick_id(strss[0]);
          console.log(getnumber, strss[0]);
          setcamp_id(parseInt(getnumber[4]));
        }
        if (getnumber[0] == "?msisdn") {
          setoperator_id(240);
          if (getnumber[1] == "") {
            completecgflow();
            setcheckflow(false);
            setcgflow(true);
            /*setcheckflow(false)
                setcgflow(false)*/
          } else {
            completecgflow();
            setcheckflow(false);
            setcgflow(true);
          }
        }
      }
    } else if (beeline.test(window.location.pathname) == true) {
      if (str == undefined) {
        str = localStorage.setItem("lang", "boar");
        var getnumber = window.location.search.split("?");
        var burl = new URL(window.location.href);
        var urlAff_id = burl.searchParams.get("aff_id");
        var click_ids = burl.searchParams.get("click_id");
        var camp_ids = burl.searchParams.get("camp_id");
        localStorage.setItem("aff_id", urlAff_id);
        localStorage.setItem("click_id", click_ids);
        localStorage.setItem("camp_id", camp_ids);
        if (getnumber.length > 1) {
          setoperator_id(239);
          var chnages = getnumber[1].split("&");
          var strssssss = chnages[0].split("=");
          setplan(strssssss[1]);
          localStorage.setItem("plan", "daily");
          var strs = chnages[1].split("=");
          setaff_id(parseInt(strs[1]));

          var strss = chnages[2].split("=");
          setclick_id(strss[1]);
          var strsss = chnages[3].split("=");
          setcamp_id(parseInt(strsss[1]));
          console.log("tye in");
          if (chnages.length > 4) {
            console.log(chnages.length);
            var wifi = chnages[4].split("=");
            if (wifi[1] == "no") {
              setcheckflow(true);
              setcgflow(false);
            } else {
              setcheckflow(false);
              setcgflow(false);
            }
          } else {
            console.log("tye in");
            setcheckflow(true);
            setcgflow(false);
          }
        }
      } else {
        if (str == "boar" || str == "boen") {
          str = localStorage.getItem("lang");
        } else {
          localStorage.setItem("lang", "boar");
          str = "boar";
        }
        /**
         *   var burl = new URL(window.location.href)
        var bMsisdn = burl.searchParams.get('dMsisdn') 
        if(bMsisdn){
            console.log(bMsisdn);
        } 
         */
        var getnumber = window.location.search.split("?");
        if (getnumber.length > 1) {
          setoperator_id(239);
          var burl = new URL(window.location.href);
          var bMsisdn = burl.searchParams.get("dMsisdn");
          var urlAff_id = burl.searchParams.get("aff_id");
          var click_ids = burl.searchParams.get("click_id");
          var camp_ids = burl.searchParams.get("camp_id");
          if (urlAff_id != null && urlAff_id != undefined && urlAff_id != "") {
            localStorage.setItem("aff_id", urlAff_id);
          }
          if (click_ids != null && click_ids != undefined && click_ids != "") {
            localStorage.setItem("click_id", click_ids);
          }
          if (camp_ids != null && camp_ids != undefined && camp_ids != "") {
            localStorage.setItem("camp_id", camp_ids);
          }

          if (bMsisdn != null && bMsisdn != undefined && bMsisdn != "") {
            localStorage.setItem("bmsisdn", bMsisdn);
            setcheckflow(true);
            setcgflow(false);
          } else {
            var chnages = getnumber[1].split("&");
            var strssssss = chnages[0].split("=");
            setplan(strssssss[1]);
            localStorage.setItem("plan", "daily");
            var strs = chnages[1].split("=");
            setaff_id(parseInt(strs[1]));
            var strss = chnages[2].split("=");
            setclick_id(strss[1]);
            var strsss = chnages[3].split("=");
            setcamp_id(parseInt(strsss[1]));
            if (chnages.length > 4) {
              console.log(chnages.length);
              var wifi = chnages[4].split("=");

              if (wifi[1] == "no") {
                setcheckflow(true);
                setcgflow(false);
              } else {
                setcheckflow(false);
                setcgflow(false);
              }
            } else {
              console.log("tye in");
              setcheckflow(true);
              setcgflow(false);
            }
          }
        }
      }
      // setcheckflow(false)
      // setcgflow(false)
    }

    // console.log("en", str);
    if (str == "en") {
      console.log("en");
      setlan(lang.en);
    } else if (str == "zoen") {
      setlan(lang.zoen);
    } else if (str == "ar") {
      setlan(lang.ar);
    } else if (str == "var") {
      setlan(lang.var);
    } else if (str == "ven") {
      setlan(lang.ven);
    } else if (str == "voen") {
      setlan(lang.voen);
    } else if (str == "boen") {
      setlan(lang.boen);
    } else if (str == "boar") {
      setlan(lang.boar);
    } else if (str == "plten") {
      setlan(lang.plten);
    } else if (str == "ploen") {
      setlan(lang.ploen);
    } else if (str == "oslen") {
      setlan(lang.oslen);
    } else if (str == "plpen") {
      setlan(lang.plpen);
    } else if (str == "plppen") {
      setlan(lang.plppen);
    } else if (str == "en_gr") {
      setlan(lang.en_gr);
    }
  });

  useEffect(() => {
    var countryCodes;

    if (
      dubai.test(window.location.pathname) == true ||
      zong.test(window.location.pathname) == true ||
      virgin.test(window.location.pathname) == true ||
      voda.test(window.location.pathname) == true ||
      beeline.test(window.location.pathname) == true ||
      tmobile.test(window.location.pathname) == true ||
      orange.test(window.location.pathname) == true ||
      sierra.test(window.location.pathname) == true ||
      play.test(window.location.pathname) == true ||
      plus.test(window.location.pathname) == true ||
      grameen.test(window.location.pathname) == true
    ) {
      console.log("in here");
      const fetchDatas = async () => {
        var str = window.location.pathname;
        const dataes = codeJson.filter((i) => i.link1 === str);
        console.log("dataes", dataes);
        if (dataes.length > 0) {
          window.sessionStorage.setItem("path", dataes[0].link1);
          window.sessionStorage.setItem("dialCode", dataes[0].dial_code.slice(1));

          getcountry(dataes[0].dial_code.slice(1));
          getcountrys("+" + dataes[0].dial_code.slice(1));
          history.push(window.location.pathname);
        } else {
          history.push("/notfound");
        }
        const dataArrays = codeJson.filter((i) => i.link1 === str);
        setproductdetails(dataArrays[0]);
      };
      fetchDatas();
    } else {
      console.log("else here");
      const fetchData = async () => {
        const result = await axios(myConfig.billing_url + "countryCode.php");
        //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
        window.sessionStorage.setItem("sCountryCode", result.data.geoplugin_countryCode);
        setuserip(result.data.geoplugin_request);
        var str = window.location.pathname;
        const dataes = codeJson.filter((i) => i.link1 === str);
        console.log("dataes", dataes);
        if (dataes.length > 0) {
          window.sessionStorage.setItem("path", dataes[0].link1);
          history.push(window.location.pathname);
        } else {
          history.push("/notfound");
        }

        const dataArray = codeJson.filter((i) => i.code === result.data.geoplugin_countryCode);
        console.log(dataArray);
        if (dataArray.length > 0) {
          // setproductdetails(dataArray[0])
          window.sessionStorage.setItem("dialCode", dataArray[0].dial_code.slice(1));

          getcountry(dataArray[0].dial_code.slice(1));
          getcountrys("+" + dataArray[0].dial_code.slice(1));
        }

        const dataArrays = codeJson.filter((i) => i.link1 === str);
        setproductdetails(dataArrays[0]);
      };
      fetchData();
    }

    // localStorage.removeItem("lang")

    var browser = (function () {
      var test = function (regexp) {
        return regexp.test(window.navigator.userAgent);
      };
      switch (true) {
        case test(/edg/i):
          return "Microsoft Edge";
        case test(/trident/i):
          return "Microsoft Internet Explorer";
        case test(/firefox|fxios/i):
          return "Mozilla Firefox";
        case test(/opr\//i):
          return "Opera";
        case test(/ucbrowser/i):
          return "UC Browser";
        case test(/samsungbrowser/i):
          return "Samsung Browser";
        case test(/chrome|chromium|crios/i):
          return "Google Chrome";
        case test(/safari/i):
          return "Apple Safari";
        default:
          return "Other";
      }
    })();
    setbrowser(browser);

    $("#subscribeMsisdn").show();
    $("#verify").hide();
  }, []);

  useEffect(() => {
    if (virgin.test(window.location.pathname) == true) {
      if (localStorage.getItem("update") == "1") {
        var str =
          localStorage.getItem("plan") +
          "_" +
          localStorage.getItem("aff_id") +
          "_" +
          localStorage.getItem("click_id") +
          "_" +
          localStorage.getItem("camp_id") +
          "_" +
          Math.floor(1000 + Math.random() * 9000);
        console.log(str);
        window.location.href =
          "http://sdp.altruistindia.com:8080/reports/header.php?UserName=IbuDigital&Password=d5ba5e842aace15863f3c34eda86251e&Ref=" + str;
      }
    }
  }, [localStorage.getItem("click_id") != null ? localStorage.getItem("click_id") : ""]);

  // const newdata = async (uuid) => {
  //   console.log("yes")
  //   var partnerid = Buffer.from('ibudigital:MegaPlus220120','utf-8').toString('base64')
  //   var auth = Buffer.from('ibudigital:MegaPlus220120','base64').toString('base64')
  //   var setdata = Buffer.from(window.location.href,'utf-8').toString('base64')

  //   function sha512(text) {
  //     return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(text)).then(buf => {
  //       return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
  //     });
  //   }
  //   var sh;

  //   var convert = await sha512(uuid+'|'+partnerid).then(x => sh = x);
  //   console.log(sh)

  //   var resrun = await axios('https://prod.digitaltechnology.co/he/session?trxid='+uuid+'&partnerid='+partnerid+'&signature='+sh+'&lang=en&responseURL='+setdata+'&productid=1201',{
  //     // mode:'no-cors',
  //     // redirect:'follow',
  //     headers:{
  //       "Access-Control-Allow-Origin": "*",
  //       'Authorization': partnerid
  //     }
  //   })
  //         .then(Response => {
  //          console.log("yes",Response)
  //         //   if (Response.redirected = false) {
  //           axios('https://prod.digitaltechnology.co/he/session?trxid='+uuid+'&partnerid='+partnerid+'&signature='+sh+'&lang=en&responseURL='+setdata+'&productid=1201',{
  //               // mode:'no-cors',
  //               // redirect:'follow',
  //               headers:{
  //                 "Access-Control-Allow-Origin": "*",
  //                 'Authorization': partnerid
  //               }
  //             })
  //             // newdata(uuid)
  //           // }
  //         })
  //         // console.log(resrun)
  // }

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(myConfig.server_url + "listAllFAQ/", {
        headers: { Authorization: "Basic " + Buffer.from(`${myConfig.user}:${myConfig.password}`, "utf8").toString("base64") },
      });
      //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
      setFaqs(result.data.listData);
    };
    fetchData();
    const dataArray = codeJson.filter((i) => i.code === window.sessionStorage.getItem("sCountryCode"));
    // if(dataArray.length>0){
    //     window.sessionStorage.setItem("dialCode", dataArray[0].dial_code.slice(1))
    // }else{
    //     window.sessionStorage.setItem("dialCode", '971')
    // }
    // getcountry(window.sessionStorage.getItem("dialCode"))
  }, []);

  const checknumber = (e) => {
    if (e.target.value.length > 0) {
      getphone(e.target.value);
      $("#msisdn").removeClass("buttondis");
    } else {
      getphone(e.target.value);
      $("#msisdn").addClass("buttondis");
    }
  };

  const checkotp = (e) => {
    // console.log(e.target.value.length);
    if (e.target.value.length == 4) {
      getotp(e.target.value);
      $("#msisdn").removeClass("buttondis");
    } else {
      getotp(e.target.value);
      $("#msisdn").addClass("buttondis");
    }
  };

  const chnagecountrycode = (e) => {
    getcountry(e.target.value);
  };

  const verifyotp = () => {
    var dial_code = window.sessionStorage.getItem("dialCode");
    console.log(dial_code);
    if (otp != "undefined") {
      if (beeline.test(window.location.pathname) == true || sierra.test(window.location.pathname) == true) {
        if (otp.length == 5) {
          if (camp_id === NaN) {
            camp_id = 3;
          }
          var otpurl =
            myConfig.billing_url +
            "subscription.php?affiliate=" +
            aff_id +
            "&cg_image_url=&mode=WAP&msisdn=" +
            conuty +
            phone +
            "&product_id=" +
            productdetails.product_id +
            "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&aff_id=" +
            aff_id +
            "&camp_id=" +
            camp_id +
            "&other1=verify_otp&other2=" +
            otp;
          var queryparams =
            "affiliate = " +
            aff_id +
            " | cg_image_url = xxxxxxxx |mode = WAP | msisdn=" +
            conuty +
            phone +
            "| product_id = " +
            productdetails.product_id +
            "| result_url = https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F | other1 = verify_otp | other2 = " +
            otp;
          var gettime = new Date();
          var veriftotpurlreq =
            gettime + "| REQ |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams;
          var finalobj9 = {
            security: "col#c4",
            logData: veriftotpurlreq,
          };
          axios.post(myConfig.billing_url + "appLog.php", finalobj9);
          axios.get(otpurl).then((Response) => {
            var obj = {
              status: Response.data.status,
              ip: browser,
              agent: navigator.userAgent,
              response: Response,
              mobile_no: conuty + phone,
              productdetails: productdetails.product_id,
            };
            var obje = JSON.stringify(obj);
            var finalobj = {
              security: "col#c4",
              logData: obje,
            };
            var gettime = new Date();
            var queryparams1 =
              "affiliate=" +
              aff_id +
              "&cg_image_url=&mode=WAP&msisdn=" +
              conuty +
              phone +
              "&product_id=" +
              productdetails.product_id +
              "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=verify_otp&other2=" +
              otp;
            var veriftotpurlres =
              gettime + "| RES |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
            var finalobj5 = {
              security: "col#c4",
              logData: veriftotpurlres,
            };
            axios.post(myConfig.billing_url + "appLog.php", finalobj5);
            var createanotherurl =
              gettime +
              "|" +
              conuty +
              phone +
              "|" +
              window.location.pathname +
              "|" +
              1111 +
              "|" +
              browser +
              "|" +
              222222 +
              "|" +
              111111 +
              "|" +
              navigator.userAgent +
              "|" +
              otpurl +
              "|" +
              JSON.stringify(Response);
            var finalobj6 = {
              security: "col#c4",
              logData: createanotherurl,
            };
            axios.post(myConfig.billing_url + "visitorLog.php", finalobj6);

            if (Response.data.status == "OK") {
              // history.push('/thankYou')
              const result = axios(myConfig.server_url + "checkUser/" + conuty + phone, {
                headers: { Authorization: "Basic " + Buffer.from(`${myConfig.user}:${myConfig.password}`, "utf8").toString("base64") },
              }).then((res) => {
                console.log(res);
                if (res.data == false) {
                  var addsub = {
                    countryCode: conuty,
                    emailId: "string",
                    loginType: "mobile",
                    name: "",
                    phoneNumber: conuty + phone,
                    userId: conuty + phone,
                  };
                  axios
                    .post(myConfig.server_url + "user/", addsub, {
                      headers: {
                        Authorization: "Basic " + Buffer.from(`${myConfig.user}:${myConfig.password}`, "utf8").toString("base64"),
                        "X-Content-Type-Options": "nosniff",
                      },
                    })
                    .then((Response) => {
                      window.sessionStorage.setItem("userId", conuty + phone);
                      $("#verify").hide();
                      $("#mobotp5").hide();
                      history.push("/welcome");
                    });
                } else {
                  const result = axios(myConfig.server_url + "getSubscribedUser/" + conuty + phone, {
                    headers: {
                      Authorization: "Basic " + Buffer.from(`${myConfig.user}:${myConfig.password}`, "utf8").toString("base64"),
                      "X-Content-Type-Options": "nosniff",
                    },
                  }).then((resp) => {
                    window.sessionStorage.setItem("userId", conuty + phone);
                    localStorage.setItem("name", resp.data.name);
                    window.sessionStorage.setItem("isLogin", true);
                    $("#verify").hide();
                    $("#mobotp5").hide();
                    history.push("/welcome");
                  });
                }
              });
              // // return  console.log(result)
              //     $('#verify').hide()
              //     $("#mobotp").hide()
            } else {
              history.push("/fail");
            }
          });
        } else {
          $("#mobotp5").show();
        }
      } else {
        if (otp.length == 4) {
          var otpurl =
            virgin.test(window.location.pathname) == false || phones == ""
              ? myConfig.billing_url +
                "subscription.php?affiliate=" +
                aff_id +
                "&cg_image_url=&mode=WAP&msisdn=" +
                conuty +
                phone +
                "&product_id=" +
                productdetails.product_id +
                "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&aff_id=" +
                aff_id +
                "&camp_id=" +
                camp_id +
                "&other1=verify_otp&other2=" +
                otp
              : myConfig.billing_url +
                "subscription.php?affiliate=" +
                aff_id +
                "&cg_image_url=&mode=WAP&msisdn=" +
                phone +
                "&product_id=" +
                productdetails.product_id +
                "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=verify_otp&other2=" +
                otp;
          var queryparams =
            virgin.test(window.location.pathname) == false || phones == ""
              ? "affiliate = " +
                aff_id +
                " | cg_image_url = xxxxxxxx |mode = WAP | msisdn=" +
                conuty +
                phone +
                "| product_id = " +
                productdetails.product_id +
                "| result_url = https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F | other1 = verify_otp | other2 = " +
                otp
              : "affiliate = " +
                aff_id +
                " | cg_image_url = xxxxxxxx |mode = WAP | msisdn=" +
                phone +
                "| product_id = " +
                productdetails.product_id +
                "| result_url = https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F | other1 = verify_otp | other2 = " +
                otp;
          var gettime = new Date();
          var veriftotpurlreq =
            virgin.test(window.location.pathname) == false || phones == ""
              ? gettime + "| REQ |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams
              : gettime + "| REQ |" + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams;
          var finalobj9 = {
            security: "col#c4",
            logData: veriftotpurlreq,
          };
          axios.post(myConfig.billing_url + "appLog.php", finalobj9);
          axios.get(otpurl).then((Response) => {
            var obj;
            if (virgin.test(window.location.pathname) == false || phones == "") {
              obj = {
                status: Response.data.status,
                ip: browser,
                agent: navigator.userAgent,
                response: Response,
                mobile_no: conuty + phone,
                productdetails: productdetails.product_id,
              };
            } else {
              obj = {
                status: Response.data.status,
                ip: browser,
                agent: navigator.userAgent,
                response: Response,
                mobile_no: phone,
                productdetails: productdetails.product_id,
              };
            }
            var obje = JSON.stringify(obj);
            var finalobj = {
              security: "col#c4",
              logData: obje,
            };
            var gettime = new Date();
            var queryparams1 =
              "affiliate=" +
              aff_id +
              "&cg_image_url=&mode=WAP&msisdn=" +
              conuty +
              phone +
              "&product_id=" +
              productdetails.product_id +
              "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=verify_otp&other2=" +
              otp;
            var veriftotpurlres =
              virgin.test(window.location.pathname) == false || phones == ""
                ? gettime + "| RES |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data)
                : gettime + "| RES |" + phone + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
            var finalobj5 = {
              security: "col#c4",
              logData: veriftotpurlres,
            };
            axios.post(myConfig.billing_url + "appLog.php", finalobj5);
            var createanotherurl =
              virgin.test(window.location.pathname) == false || phones == ""
                ? gettime +
                  "|" +
                  conuty +
                  phone +
                  "|" +
                  window.location.pathname +
                  "|" +
                  1111 +
                  "|" +
                  browser +
                  "|" +
                  222222 +
                  "|" +
                  111111 +
                  "|" +
                  navigator.userAgent +
                  "|" +
                  otpurl +
                  "|" +
                  JSON.stringify(Response)
                : gettime +
                  "|" +
                  phone +
                  "|" +
                  window.location.pathname +
                  "|" +
                  1111 +
                  "|" +
                  browser +
                  "|" +
                  222222 +
                  "|" +
                  111111 +
                  "|" +
                  navigator.userAgent +
                  "|" +
                  otpurl +
                  "|" +
                  JSON.stringify(Response);
            var finalobj6 = {
              security: "col#c4",
              logData: createanotherurl,
            };
            axios.post(myConfig.billing_url + "visitorLog.php", finalobj6);

            if (Response.data.status == "OK") {
              // history.push('/thankYou')
              const result = axios(myConfig.server_url + "checkUser/" + conuty + phone, {
                headers: { Authorization: "Basic " + Buffer.from(`${myConfig.user}:${myConfig.password}`, "utf8").toString("base64") },
              }).then((res) => {
                console.log(res);
                if (res.data == false) {
                  var addsub = {
                    countryCode: conuty,
                    emailId: "string",
                    loginType: "mobile",
                    name: "",
                    phoneNumber: conuty + phone,
                    userId: conuty + phone,
                  };
                  axios
                    .post(myConfig.server_url + "user/", addsub, {
                      headers: {
                        Authorization: "Basic " + Buffer.from(`${myConfig.user}:${myConfig.password}`, "utf8").toString("base64"),
                        "X-Content-Type-Options": "nosniff",
                      },
                    })
                    .then((Response) => {
                      window.sessionStorage.setItem("userId", conuty + phone);
                      $("#verify").hide();
                      $("#mobotp").hide();
                      history.push("/welcome");
                    });
                } else {
                  const result = axios(myConfig.server_url + "getSubscribedUser/" + conuty + phone, {
                    headers: {
                      Authorization: "Basic " + Buffer.from(`${myConfig.user}:${myConfig.password}`, "utf8").toString("base64"),
                      "X-Content-Type-Options": "nosniff",
                    },
                  }).then((resp) => {
                    window.sessionStorage.setItem("userId", conuty + phone);
                    localStorage.setItem("name", resp.data.name);
                    window.sessionStorage.setItem("isLogin", true);
                    $("#verify").hide();
                    $("#mobotp").hide();
                    history.push("/welcome");
                  });
                }
              });
              // // return  console.log(result)
              //     $('#verify').hide()
              //     $("#mobotp").hide()
            } else {
              history.push("/fail");
            }
          });
        } else {
          $("#mobotp").show();
        }
      }
    } else {
      $("mobotp").show();
    }
  };

  const beline = () => {
    if (sierra.test(window.location.pathname) == true) {
      var gProductId = productdetails.product_id;
      var opcode = 91;
      if (orange.test(window.location.pathname) == true) {
        opcode = 91;
      } else {
        opcode = 100;
      }
      var anotherUrl =
        myConfig.billing_url +
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      if (localStorage.getItem("plan") == "daily") {
        gProductId = "SEVdb100dly";
        //gProductId = "SEVdb100wly";
      } else {
        gProductId = "SEVdb100wly";
      }
      var anotherUrl =
        myConfig.billing_url +
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      var queryparams4 =
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      var gettime = new Date();
      var subreq = gettime + "| REQ |" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams4;
      var finalobj4 = {
        security: "col#c4",
        logData: subreq,
      };
      axios.post(myConfig.billing_url + "appLog.php", finalobj4);
      axios.get(anotherUrl).then((Response) => {
        console.log(Response);
        /*** */
        var obj = {
          status: Response.data.status,
          ip: browser,
          agent: navigator.userAgent,
          response: Response,
          mobile_no: conuty + phone,
          productdetails: productdetails.product_id,
        };
        var obje = JSON.stringify(obj);
        var finalobj = {
          security: "col#c4",
          logData: obje,
        };
        var gettime = new Date();
        var queryparams5 =
          "subscription.php?affiliate=" +
          aff_id +
          "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
          productdetails.product_id +
          "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=";
        var subros = gettime + "| RES |" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
        var finalobj7 = {
          security: "col#c4",
          logData: subros,
        };
        axios.post(myConfig.billing_url + "appLog.php", finalobj7);
        var createanotherurl =
          gettime +
          "|" +
          conuty +
          phone +
          "|" +
          window.location.pathname +
          "|" +
          1111 +
          "|" +
          browser +
          "|" +
          222222 +
          "|" +
          111111 +
          "|" +
          navigator.userAgent +
          "|" +
          anotherUrl +
          "|" +
          JSON.stringify(Response);
        var finalobj8 = {
          security: "col#c4",
          logData: createanotherurl,
        };
        axios.post(myConfig.billing_url + "visitorLog.php", finalobj8);

        if (Response.data.status == "OK") {
          window.open(Response.data.url, "_blank");
          var getMsisdn =
            myConfig.billing_url +
            "subscription.php?affiliate=" +
            aff_id +
            "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
            gProductId +
            "&other1=fetchmsisdn&other2=2345&plan=" +
            localStorage.getItem("plan") +
            "&aff_id=" +
            aff_id +
            "&aff_uniq_id=" +
            click_id +
            "&camp_id=" +
            camp_id;
          axios.get(getMsisdn).then((MsisdnResponse) => {
            if (MsisdnResponse.data.code == "H900") {
              if (MsisdnResponse.data.msisdn) {
                var subros = gettime + "| RES |" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(MsisdnResponse.data);
                var finalobj7 = {
                  security: "col#c4",
                  logData: subros,
                };
                axios.post(myConfig.billing_url + "appLog.php", finalobj7);
                //call do billing api
                //https://billingapi.skysportsnetwork.com/mpg/subscription.php?affiliate=1000&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=SEVdb91baa&other1=do_billing&other2=&plan=daily&aff_id=1000&aff_uniq_id=1234&camp_id=3
                var doBilling =
                  myConfig.billing_url +
                  "subscription.php?affiliate=" +
                  aff_id +
                  "&cg_image_url=&mode=WAP&msisdn=" +
                  MsisdnResponse.data.msisdn +
                  "&product_id=" +
                  gProductId +
                  "&other1=do_billing&other2=&plan=" +
                  localStorage.getItem("plan") +
                  "&aff_id=" +
                  aff_id +
                  "&aff_uniq_id=" +
                  click_id +
                  "&camp_id=" +
                  camp_id;
                console.log("doBilling:", doBilling);
                axios.get(doBilling).then((BillingResponse) => {
                  if (BillingResponse.data.status == "OK") {
                    window.sessionStorage.setItem("userId", conuty + MsisdnResponse.data.msisdn);
                    //localStorage.setItem("name",resp.data.name)
                    window.sessionStorage.setItem("isLogin", true);
                    $("#verify").hide();
                    $("#mobotp5").hide();
                    history.push("/welcome");
                    //window.location.href = redirectUrl
                  } else {
                    if (BillingResponse.data.code == "H101") {
                      history.push("/fail?code=subscribed");
                    } else if (BillingResponse.data.code == "H100") {
                      history.push("/fail?code=".BillingResponse.data.message);
                    } else {
                      history.push("/fail?code=");
                    }
                  }
                });
              } else {
                var errorRedirectUrl =
                  "https://osl.skysportsnetwork.com/sierra_leone/promo?plan=daily&aff_id=" +
                  aff_id +
                  "&click_id=" +
                  click_id +
                  "&wifi=yes&camp_id=" +
                  camp_id;
                //errorRedirectUrl =  encodeURIComponent(errorRedirectUrl)
                var subrosSSS = gettime + "| RES |" + JSON.stringify(Response.data) + "&errorRedirectUrl=" + errorRedirectUrl;
                var finalobj10 = {
                  security: "col#c4",
                  logData: subrosSSS,
                };
                axios.post(myConfig.billing_url + "appLog.php", finalobj10);

                var redirectUrl = Response.data.url + "&errorRedirectUrl=" + errorRedirectUrl;
                //console.log(redirectUrl)
                //  alert(redirectUrl)
                window.location.href = errorRedirectUrl;
              }
            } else {
              var errorRedirectUrl =
                "https://osl.skysportsnetwork.com/sierra_leone/promo?plan=daily&aff_id=" +
                aff_id +
                "&click_id=" +
                click_id +
                "&wifi=yes&camp_id=" +
                camp_id;
              //errorRedirectUrl =  encodeURIComponent(errorRedirectUrl)
              var subrosSSS = gettime + "| RES |" + JSON.stringify(Response.data) + "&errorRedirectUrl=" + errorRedirectUrl;
              var finalobj10 = {
                security: "col#c4",
                logData: subrosSSS,
              };
              axios.post(myConfig.billing_url + "appLog.php", finalobj10);

              var redirectUrl = Response.data.url + "&errorRedirectUrl=" + errorRedirectUrl;
              //console.log(redirectUrl)
              //  alert(redirectUrl)
              window.location.href = errorRedirectUrl;
            }
          });
        } else {
          if (Response.data.code == "H101") {
            history.push("/fail?code=subscribed");
          } else if (Response.data.code == "H100") {
            history.push("/fail?code=inSufBal");
          } else {
            history.push("/fail?code=");
          }

          // window.location.href = '/fail'
        }
      });
      /* //https://billingapi.skysportsnetwork.com/mpg/subscription.php?affiliate=1000&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=SEVdb91baa&other1=&other2=&plan=daily&aff_id=1000&aff_uniq_id=1234&camp_id=3
      var anotherUrl = myConfig.billing_url+"subscription.php?affiliate="+aff_id+"&cg_image_url=&mode=WAP&msisdn=1000000000&product_id="+gProductId+"&other1=&other2=&plan="+localStorage.getItem('plan')+"&aff_id="+aff_id+"&aff_uniq_id="+click_id+"&camp_id="+camp_id
      var queryparams4 = "subscription.php?affiliate="+aff_id+"&cg_image_url=&mode=WAP&msisdn=1000000000&product_id="+gProductId+"&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan="+localStorage.getItem('plan')+"&aff_id="+aff_id+"&aff_uniq_id="+click_id+"&camp_id="+camp_id
      var gettime = new Date();
      var subreq = gettime + '| REQ |' + myConfig.billing_url+"subscription.php" + '| POST Parameters =>' + queryparams4
      var finalobj4 = {
        security : "col#c4",
        logData : subreq
      }
      //
      axios.post(myConfig.billing_url+"appLog.php",finalobj4)
      axios.get(anotherUrl)
      .then(Response => {
        if(Response.data.status=="OK"){
          var redirectUrl = Response.data.url;
          var transactionId = Response.data.trans_id;  
         // window.location.href = redirectUrl
         //https://billingapi.skysportsnetwork.com/mpg/subscription.php?affiliate=1000&msisdn=9000000000&product_id=SEVdb91baa&camp_id=3&other1=fetchmsisdn&other2=2022120808421210000000009856&OPID
         var finalobj4 = {
          security : "col#c4",
          logData : Response.data
        }
        axios.post(myConfig.billing_url+"appLog.php",finalobj4)
        var anotherUrl = myConfig.billing_url+"subscription.php?affiliate="+aff_id+"&msisdn=9000000000&mode=WAP&msisdn=1000000000&product_id="+gProductId+"&other1=fetchmsisdn&other2="+transactionId+"&OPID="+opcode+"&plan="+localStorage.getItem('plan')+"&aff_id="+aff_id+"&aff_uniq_id="+click_id+"&camp_id="+camp_id
        var queryparams4 = "subscription.php?affiliate="+aff_id+"&msisdn=9000000000&mode=WAP&msisdn=1000000000&product_id="+gProductId+"&other1=fetchmsisdn&other2="+transactionId+"&OPID="+opcode+"&plan="+localStorage.getItem('plan')+"&aff_id="+aff_id+"&aff_uniq_id="+click_id+"&camp_id="+camp_id
        var gettime = new Date();
        var subreq = gettime + '| REQ |' + myConfig.billing_url+"subscription.php" + '| POST Parameters =>' + queryparams4
        var finalobj4 = {
          security : "col#c4",
          logData : subreq
        }
        axios.post(myConfig.billing_url+"appLog.php",finalobj4)
        axios.post(anotherUrl)
        .then(res=>{
            console.log(res)
            // start work 
            //sattus = OK 
            if(res.data.status == 'OK') {

            var opsUrl = myConfig.billing_url+"subscription.php?affiliate="+aff_id+"&msisdn=9000000000&mode=WAP&msisdn=1000000000&product_id="+gProductId+"&other1=do_billing&other2="+transactionId+"&OPID="+opcode+"&plan="+localStorage.getItem('plan')+"&aff_id="+aff_id+"&aff_uniq_id="+click_id+"&camp_id="+camp_id
            var queryparamsOps = "subscription.php?affiliate="+aff_id+"&msisdn=9000000000&mode=WAP&msisdn=1000000000&product_id="+gProductId+"&other1=do_billing&other2="+transactionId+"&OPID="+opcode+"&plan="+localStorage.getItem('plan')+"&aff_id="+aff_id+"&aff_uniq_id="+click_id+"&camp_id="+camp_id
            var gettime = new Date();
            var subreq = gettime + '| REQ |' + myConfig.billing_url+"subscription.php" + '| POST Parameters =>' + queryparamsOps
            var finalobjOps = {
              security : "col#c4",
              logData : opsUrl
            }
            axios.post(myConfig.billing_url+"appLog.php",finalobjOps)
          }
        })
           
        }else{
          if (Response.data.code == 'H101') {
            history.push('/fail?code=subscribed')
          }else if(Response.data.code == 'H100'){
            history.push('/fail?code=inSufBal')
          }else{
            history.push('/fail?code=')
          }
        }
       
         
      })*/
    } else if (orange.test(window.location.pathname) == true) {
      var gProductId = productdetails.product_id;
      var opcode = 91;
      if (orange.test(window.location.pathname) == true) {
        opcode = 91;
      } else {
        opcode = 100;
      }
      var anotherUrl =
        myConfig.billing_url +
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      if (localStorage.getItem("plan") == "daily" && gProductId == "SEVdb1gp") {
        gProductId = "SEVdb2gp";
      }
      var anotherUrl =
        myConfig.billing_url +
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      var queryparams4 =
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      var gettime = new Date();
      var subreq = gettime + "| REQ |" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams4;
      var finalobj4 = {
        security: "col#c4",
        logData: subreq,
      };
      axios.post(myConfig.billing_url + "appLog.php", finalobj4);
      axios.get(anotherUrl).then((Response) => {
        var obj = {
          status: Response.data.status,
          ip: browser,
          agent: navigator.userAgent,
          response: Response,
          mobile_no: conuty + phone,
          productdetails: productdetails.product_id,
        };
        var obje = JSON.stringify(obj);
        var finalobj = {
          security: "col#c4",
          logData: obje,
        };
        var gettime = new Date();
        var queryparams5 =
          "subscription.php?affiliate=" +
          aff_id +
          "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
          productdetails.product_id +
          "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=";
        var subros = gettime + "| RES |" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
        var finalobj7 = {
          security: "col#c4",
          logData: subros,
        };
        axios.post(myConfig.billing_url + "appLog.php", finalobj7);
        var createanotherurl =
          gettime +
          "|" +
          conuty +
          phone +
          "|" +
          window.location.pathname +
          "|" +
          1111 +
          "|" +
          browser +
          "|" +
          222222 +
          "|" +
          111111 +
          "|" +
          navigator.userAgent +
          "|" +
          anotherUrl +
          "|" +
          JSON.stringify(Response);
        var finalobj8 = {
          security: "col#c4",
          logData: createanotherurl,
        };
        axios.post(myConfig.billing_url + "visitorLog.php", finalobj8);

        if (Response.data.status == "OK") {
          window.open(Response.data.url, "_blank");
          var getMsisdn =
            myConfig.billing_url +
            "subscription.php?affiliate=" +
            aff_id +
            "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
            gProductId +
            "&other1=fetchmsisdn&other2=2345&plan=" +
            localStorage.getItem("plan") +
            "&aff_id=" +
            aff_id +
            "&aff_uniq_id=" +
            click_id +
            "&camp_id=" +
            camp_id;
          console.log(getMsisdn);
          axios.get(getMsisdn).then((MsisdnResponse) => {
            if (MsisdnResponse.data.code == "H900") {
              if (MsisdnResponse.data.msisdn) {
                //call do billing api
                //https://billingapi.skysportsnetwork.com/mpg/subscription.php?affiliate=1000&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=SEVdb91baa&other1=do_billing&other2=&plan=daily&aff_id=1000&aff_uniq_id=1234&camp_id=3
                var doBilling =
                  myConfig.billing_url +
                  "subscription.php?affiliate=" +
                  aff_id +
                  "&cg_image_url=&mode=WAP&msisdn=" +
                  MsisdnResponse.data.msisdn +
                  "&product_id=" +
                  gProductId +
                  "&other1=do_billing&other2=&plan=" +
                  localStorage.getItem("plan") +
                  "&aff_id=" +
                  aff_id +
                  "&aff_uniq_id=" +
                  click_id +
                  "&camp_id=" +
                  camp_id;
                console.log("doBilling:", doBilling);
                var subros = gettime + "| RES |" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(MsisdnResponse.data);
                var finalobj7 = {
                  security: "col#c4",
                  logData: subros,
                };
                axios.post(myConfig.billing_url + "appLog.php", finalobj7);
                axios.get(doBilling);
                console.log("doBilling:", doBilling).then((BillingResponse) => {
                  console.log(BillingResponse);
                  if (BillingResponse.data.status == "OK") {
                    window.sessionStorage.setItem("userId", conuty + MsisdnResponse.data.msisdn);
                    //localStorage.setItem("name",resp.data.name)
                    window.sessionStorage.setItem("isLogin", true);
                    $("#verify").hide();
                    $("#mobotp5").hide();
                    history.push("/welcome");
                  } else {
                    if (BillingResponse.data.code == "H101") {
                      history.push("/fail?code=subscribed");
                    } else if (BillingResponse.data.code == "H100") {
                      history.push("/fail?code=".BillingResponse.data.message);
                    } else {
                      history.push("/fail?code=");
                    }
                  }
                });
              } else {
                var errorRedirectUrl =
                  "https://op.skysportsnetwork.com/orange_poland/promo?plan=daily&aff_id=" +
                  aff_id +
                  "&click_id=" +
                  click_id +
                  "&wifi=yes&camp_id=" +
                  camp_id;
                errorRedirectUrl = encodeURIComponent(errorRedirectUrl);
                var subrosSSS = gettime + "| RES |" + JSON.stringify(Response.data) + "&errorRedirectUrl=" + errorRedirectUrl;
                var finalobj10 = {
                  security: "col#c4",
                  logData: subrosSSS,
                };
                axios.post(myConfig.billing_url + "appLog.php", finalobj10);

                var redirectUrl = Response.data.url + "&errorRedirectUrl=" + errorRedirectUrl;
                //console.log(redirectUrl)
                //  alert(redirectUrl)
                window.location.href = redirectUrl;
              }
            } else {
              var errorRedirectUrl =
                "https://op.skysportsnetwork.com/orange_poland/promo?plan=daily&aff_id=" +
                aff_id +
                "&click_id=" +
                click_id +
                "&wifi=yes&camp_id=" +
                camp_id;
              errorRedirectUrl = encodeURIComponent(errorRedirectUrl);
              var subrosSSS = gettime + "| RES |" + JSON.stringify(Response.data) + "&errorRedirectUrl=" + errorRedirectUrl;
              var finalobj10 = {
                security: "col#c4",
                logData: subrosSSS,
              };
              axios.post(myConfig.billing_url + "appLog.php", finalobj10);

              var redirectUrl = Response.data.url + "&errorRedirectUrl=" + errorRedirectUrl;
              //console.log(redirectUrl)
              //  alert(redirectUrl)
              window.location.href = redirectUrl;
            }
          });
        } else {
          if (Response.data.code == "H101") {
            history.push("/fail?code=subscribed");
          } else if (Response.data.code == "H100") {
            history.push("/fail?code=inSufBal");
          } else {
            history.push("/fail?code=");
          }

          // window.location.href = '/fail'
        }
      });
    } else {
      var gProductId = productdetails.product_id;
      if (camp_id === NaN) {
        camp_id = 3;
      }
      var anotherUrl =
        myConfig.billing_url +
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      if (localStorage.getItem("plan") == "daily" && gProductId == "SEVdb1gp") {
        gProductId = "SEVdb2gp";
      }
      var anotherUrl =
        myConfig.billing_url +
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      var queryparams4 =
        "subscription.php?affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
        gProductId +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
        localStorage.getItem("plan") +
        "&aff_id=" +
        aff_id +
        "&aff_uniq_id=" +
        click_id +
        "&camp_id=" +
        camp_id;
      var gettime = new Date();
      var subreq = gettime + "| REQ |" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams4;
      var finalobj4 = {
        security: "col#c4",
        logData: subreq,
      };
      axios.post(myConfig.billing_url + "appLog.php", finalobj4);
      axios.get(anotherUrl).then((Response) => {
        var obj = {
          status: Response.data.status,
          ip: browser,
          agent: navigator.userAgent,
          response: Response,
          mobile_no: conuty + phone,
          productdetails: productdetails.product_id,
        };
        var obje = JSON.stringify(obj);
        var finalobj = {
          security: "col#c4",
          logData: obje,
        };
        var gettime = new Date();
        var queryparams5 =
          "subscription.php?affiliate=" +
          aff_id +
          "&cg_image_url=&mode=WAP&msisdn=1000000000&product_id=" +
          productdetails.product_id +
          "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=";
        var subros = gettime + "| RES |" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
        var finalobj7 = {
          security: "col#c4",
          logData: subros,
        };
        axios.post(myConfig.billing_url + "appLog.php", finalobj7);
        var createanotherurl =
          gettime +
          "|" +
          conuty +
          phone +
          "|" +
          window.location.pathname +
          "|" +
          1111 +
          "|" +
          browser +
          "|" +
          222222 +
          "|" +
          111111 +
          "|" +
          navigator.userAgent +
          "|" +
          anotherUrl +
          "|" +
          JSON.stringify(Response);
        var finalobj8 = {
          security: "col#c4",
          logData: createanotherurl,
        };
        axios.post(myConfig.billing_url + "visitorLog.php", finalobj8);

        if (Response.data.status == "OK") {
          // var url = new URL(Response.data.url)
          // var get_tx_id = url.searchParams.get("partner_txid")
          // console.log("get_tx_id",get_tx_id);
          // var search_params = url.searchParams;
          // search_params.set('partner_txid', get_tx_id +"_"+aff_id+"_"+click_id);
          // url.search = search_params.toString();
          // var new_url = url.toString();
          // console.log(new_url);
          // Response.data.url = new_url
          var errorRedirectUrl =
            "https://bg.skysportsnetwork.com/beeline_georgia/promo?plan=daily&aff_id=" +
            aff_id +
            "&click_id=" +
            click_id +
            "&wifi=yes&camp_id=" +
            camp_id;
          errorRedirectUrl = encodeURIComponent(errorRedirectUrl);
          var subrosSSS = gettime + "| RES |" + JSON.stringify(Response.data) + "&errorRedirectUrl=" + errorRedirectUrl;
          var finalobj10 = {
            security: "col#c4",
            logData: subrosSSS,
          };
          axios.post(myConfig.billing_url + "appLog.php", finalobj10);

          var redirectUrl = Response.data.url + "&errorRedirectUrl=" + errorRedirectUrl;
          //console.log(redirectUrl)
          //  alert(redirectUrl)
          window.location.href = redirectUrl;
        } else {
          if (Response.data.code == "H101") {
            history.push("/fail?code=subscribed");
          } else if (Response.data.code == "H100") {
            history.push("/fail?code=inSufBal");
          } else {
            history.push("/fail?code=");
          }

          // window.location.href = '/fail'
        }
      });
    }
  };

  const sendotp = () => {
    var dial_code = window.sessionStorage.getItem("dialCode");
    window.sessionStorage.setItem("userId", conuty + phone);
    if (phone != "undefined") {
      if (phone.length > 0) {
        var url =
          virgin.test(window.location.pathname) == false || phones == ""
            ? myConfig.billing_url + "checkSubscription.php?msisdn=" + conuty + phone
            : myConfig.billing_url + "checkSubscription.php?msisdn=" + phone;
        var queryparams2 =
          virgin.test(window.location.pathname) == false || phones == ""
            ? "msisdn=" + conuty + phone + " | product_id=" + productdetails.product_id
            : "msisdn=" + phone + " | product_id=" + productdetails.product_id;
        var gettime = new Date();
        var phoneotpurlreq =
          virgin.test(window.location.pathname) == false || phones == ""
            ? gettime + "| REQ |" + conuty + phone + "|" + myConfig.billing_url + "checkSubscription.php" + "| Post Parameter =>" + queryparams2
            : gettime + "| REQ |" + phone + "|" + myConfig.billing_url + "checkSubscription.php" + "| Post Parameter =>" + queryparams2;
        var finalobj1 = {
          security: "col#c4",
          logData: phoneotpurlreq,
        };
        axios.post(myConfig.billing_url + "appLog.php", finalobj1);
        axios.get(url).then((Response) => {
          var obj;
          if (virgin.test(window.location.pathname) == false || phones == "") {
            obj = {
              status: Response.data.status,
              ip: browser,
              agent: navigator.userAgent,
              response: Response,
              mobile_no: conuty + phone,
              productdetails: productdetails.product_id,
            };
          } else {
            obj = {
              status: Response.data.status,
              ip: browser,
              agent: navigator.userAgent,
              response: Response,
              mobile_no: phone,
              productdetails: productdetails.product_id,
            };
          }
          var obje = JSON.stringify(obj);
          var finalobj = {
            security: "col#c4",
            logData: obje,
          };
          var gettime = new Date();
          var queryparams3 = "checkSubscription.php?msisdn=" + conuty + phone;
          var phoneotpurlres =
            virgin.test(window.location.pathname) == false || phones == ""
              ? gettime + "| RES |" + conuty + phone + "|" + myConfig.billing_url + "checkSubscription.php" + "|" + JSON.stringify(Response.data)
              : gettime + "| RES |" + phone + "|" + myConfig.billing_url + "checkSubscription.php" + "|" + JSON.stringify(Response.data);
          var finalobj2 = {
            security: "col#c4",
            logData: phoneotpurlres,
          };
          axios.post(myConfig.billing_url + "appLog.php", finalobj2);
          var createurl =
            virgin.test(window.location.pathname) == false || phones == ""
              ? gettime +
                "|" +
                conuty +
                phone +
                "|" +
                window.location.pathname +
                "|" +
                1111 +
                "|" +
                browser +
                "|" +
                222222 +
                "|" +
                111111 +
                "|" +
                navigator.userAgent +
                "|" +
                url +
                "|" +
                JSON.stringify(Response)
              : gettime +
                "|" +
                phone +
                "|" +
                window.location.pathname +
                "|" +
                1111 +
                "|" +
                browser +
                "|" +
                222222 +
                "|" +
                111111 +
                "|" +
                navigator.userAgent +
                "|" +
                url +
                "|" +
                JSON.stringify(Response);
          var finalobj3 = {
            security: "col#c4",
            logData: createurl,
          };
          axios.post(myConfig.billing_url + "visitorLog.php", finalobj3);

          if (Response.data.status != "success") {
            var anotherUrl =
              virgin.test(window.location.pathname) == false || phones == ""
                ? myConfig.billing_url +
                  "subscription.php?affiliate=" +
                  aff_id +
                  "&cg_image_url=&mode=WAP&msisdn=" +
                  conuty +
                  phone +
                  "&product_id=" +
                  productdetails.product_id +
                  "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=&plan=" +
                  plan +
                  "&aff_id=" +
                  aff_id +
                  "&aff_uniq_id=" +
                  click_id +
                  "&camp_id=" +
                  camp_id
                : myConfig.billing_url +
                  "subscription.php?affiliate=" +
                  aff_id +
                  "&cg_image_url=&mode=WAP&msisdn=" +
                  phone +
                  "&product_id=" +
                  productdetails.product_id +
                  "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=&plan=" +
                  plan +
                  "&aff_id=" +
                  aff_id +
                  "&aff_uniq_id=" +
                  click_id +
                  "&camp_id=" +
                  camp_id;
            var queryparams4 =
              virgin.test(window.location.pathname) == false || phones == ""
                ? "subscription.php?affiliate=" +
                  aff_id +
                  "&cg_image_url=&mode=WAP&msisdn=" +
                  conuty +
                  phone +
                  "&product_id=" +
                  productdetails.product_id +
                  "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=&plan=" +
                  plan +
                  "&aff_id=" +
                  aff_id +
                  "&aff_uniq_id=" +
                  click_id +
                  "&camp_id=" +
                  camp_id
                : "subscription.php?affiliate=" +
                  aff_id +
                  "&cg_image_url=&mode=WAP&msisdn=" +
                  phone +
                  "&product_id=" +
                  productdetails.product_id +
                  "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=&plan=" +
                  plan +
                  "&aff_id=" +
                  aff_id +
                  "&aff_uniq_id=" +
                  click_id +
                  "&camp_id=" +
                  camp_id;
            var gettime = new Date();
            var subreq =
              virgin.test(window.location.pathname) == false || phones == ""
                ? gettime + "| REQ |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams4
                : gettime + "| REQ |" + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams4;
            var finalobj4 = {
              security: "col#c4",
              logData: subreq,
            };
            axios.post(myConfig.billing_url + "appLog.php", finalobj4);
            axios.get(anotherUrl).then((Response) => {
              var obj;
              if (virgin.test(window.location.pathname) == false || phones == "") {
                obj = {
                  status: Response.data.status,
                  ip: browser,
                  agent: navigator.userAgent,
                  response: Response,
                  mobile_no: conuty + phone,
                  productdetails: productdetails.product_id,
                };
              } else {
                obj = {
                  status: Response.data.status,
                  ip: browser,
                  agent: navigator.userAgent,
                  response: Response,
                  mobile_no: phone,
                  productdetails: productdetails.product_id,
                };
              }
              var obje = JSON.stringify(obj);
              var finalobj = {
                security: "col#c4",
                logData: obje,
              };
              var gettime = new Date();
              var queryparams5 =
                "subscription.php?affiliate=" +
                aff_id +
                "&cg_image_url=&mode=WAP&msisdn=" +
                conuty +
                phone +
                "&product_id=" +
                productdetails.product_id +
                "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=";
              var subros =
                virgin.test(window.location.pathname) == false || phones == ""
                  ? gettime + "| RES |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data)
                  : gettime + "| RES |" + phone + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
              var finalobj7 = {
                security: "col#c4",
                logData: subros,
              };
              axios.post(myConfig.billing_url + "appLog.php", finalobj7);
              var createanotherurl =
                virgin.test(window.location.pathname) == false || phones == ""
                  ? gettime +
                    "|" +
                    conuty +
                    phone +
                    "|" +
                    window.location.pathname +
                    "|" +
                    1111 +
                    "|" +
                    browser +
                    "|" +
                    222222 +
                    "|" +
                    111111 +
                    "|" +
                    navigator.userAgent +
                    "|" +
                    anotherUrl +
                    "|" +
                    JSON.stringify(Response)
                  : gettime +
                    "|" +
                    phone +
                    "|" +
                    window.location.pathname +
                    "|" +
                    1111 +
                    "|" +
                    browser +
                    "|" +
                    222222 +
                    "|" +
                    111111 +
                    "|" +
                    navigator.userAgent +
                    "|" +
                    anotherUrl +
                    "|" +
                    JSON.stringify(Response);
              var finalobj8 = {
                security: "col#c4",
                logData: createanotherurl,
              };
              axios.post(myConfig.billing_url + "visitorLog.php", finalobj8);

              if (Response.data.status == "OK") {
                $("#verify").show();
                $("#subscribeMsisdn").hide();
              } else {
                history.push("/fail");
                // window.location.href = '/fail'
              }
            });
          } else {
            window.location.href = "/welcome";
          }
        });

        axios.post(myConfig.billing_url + "stagingCount.php?aff_id=" + aff_id + "&operator_id=" + operator_id + "&click_submit_count=1");
        var veriftotpurlreq =
          gettime +
          "| REQ |" +
          myConfig.billing_url +
          "stagingCount.php" +
          "| POST Parameters =>" +
          "aff_id=" +
          aff_id +
          "&operator_id=" +
          operator_id +
          "&click_submit_count=1";
        var finalobj22 = {
          security: "col#c4",
          logData: veriftotpurlreq,
        };
        axios.post(myConfig.billing_url + "appLog.php", finalobj22);

        $("#mob").hide();
      } else {
        console.log("in");
        $("#mob").show();
      }
    } else {
    }
  };

  const sendBeelineOtp = () => {
    var dial_code = window.sessionStorage.getItem("dialCode");
    window.sessionStorage.setItem("userId", conuty + phone);
    if (phone != "undefined") {
      if (phone.length > 0) {
        var url = myConfig.billing_url + "checkSubscription.php?msisdn=" + conuty + phone;
        var queryparams2 = "msisdn=" + conuty + phone + " | product_id=" + productdetails.product_id;
        var gettime = new Date();
        var phoneotpurlreq =
          gettime + "| REQ |" + conuty + phone + "|" + myConfig.billing_url + "checkSubscription.php" + "| Post Parameter =>" + queryparams2;
        var finalobj1 = {
          security: "col#c4",
          logData: phoneotpurlreq,
        };
        axios.post(myConfig.billing_url + "appLog.php", finalobj1);
        axios.get(url).then((Response) => {
          var obj = {
            status: Response.data.status,
            ip: browser,
            agent: navigator.userAgent,
            response: Response,
            mobile_no: conuty + phone,
            productdetails: productdetails.product_id,
          };
          var obje = JSON.stringify(obj);
          var finalobj = {
            security: "col#c4",
            logData: obje,
          };
          var gettime = new Date();
          var queryparams3 = "checkSubscription.php?msisdn=" + conuty + phone;
          var phoneotpurlres =
            gettime + "| RES |" + conuty + phone + "|" + myConfig.billing_url + "checkSubscription.php" + "|" + JSON.stringify(Response.data);
          var finalobj2 = {
            security: "col#c4",
            logData: phoneotpurlres,
          };
          axios.post(myConfig.billing_url + "appLog.php", finalobj2);
          var createurl =
            gettime +
            "|" +
            conuty +
            phone +
            "|" +
            window.location.pathname +
            "|" +
            1111 +
            "|" +
            browser +
            "|" +
            222222 +
            "|" +
            111111 +
            "|" +
            navigator.userAgent +
            "|" +
            url +
            "|" +
            JSON.stringify(Response);
          var finalobj3 = {
            security: "col#c4",
            logData: createurl,
          };
          axios.post(myConfig.billing_url + "visitorLog.php", finalobj3);

          if (Response.data.status != "success") {
            var anotherUrl =
              myConfig.billing_url +
              "subscription.php?affiliate=" +
              aff_id +
              "&cg_image_url=&mode=WAP&msisdn=" +
              conuty +
              phone +
              "&product_id=" +
              productdetails.product_id +
              "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=&plan=" +
              plan +
              "&aff_id=" +
              aff_id +
              "&aff_uniq_id=" +
              click_id +
              "&camp_id=" +
              camp_id;
            var queryparams4 =
              "subscription.php?affiliate=" +
              aff_id +
              "&cg_image_url=&mode=WAP&msisdn=" +
              conuty +
              phone +
              "&product_id=" +
              productdetails.product_id +
              "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=&plan=" +
              plan +
              "&aff_id=" +
              aff_id +
              "&aff_uniq_id=" +
              click_id +
              "&camp_id=" +
              camp_id;
            var gettime = new Date();
            var subreq =
              gettime + "| REQ |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams4;
            var finalobj4 = {
              security: "col#c4",
              logData: subreq,
            };
            axios.post(myConfig.billing_url + "appLog.php", finalobj4);
            axios.get(anotherUrl).then((Response) => {
              var obj = {
                status: Response.data.status,
                ip: browser,
                agent: navigator.userAgent,
                response: Response,
                mobile_no: conuty + phone,
                productdetails: productdetails.product_id,
              };
              var obje = JSON.stringify(obj);
              var finalobj = {
                security: "col#c4",
                logData: obje,
              };
              var gettime = new Date();
              var queryparams5 =
                "subscription.php?affiliate=" +
                aff_id +
                "&cg_image_url=&mode=WAP&msisdn=" +
                conuty +
                phone +
                "&product_id=" +
                productdetails.product_id +
                "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=send_otp&other2=";
              var subros =
                gettime + "| RES |" + conuty + phone + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
              var finalobj7 = {
                security: "col#c4",
                logData: subros,
              };
              axios.post(myConfig.billing_url + "appLog.php", finalobj7);
              var createanotherurl =
                gettime +
                "|" +
                conuty +
                phone +
                "|" +
                window.location.pathname +
                "|" +
                1111 +
                "|" +
                browser +
                "|" +
                222222 +
                "|" +
                111111 +
                "|" +
                navigator.userAgent +
                "|" +
                anotherUrl +
                "|" +
                JSON.stringify(Response);
              var finalobj8 = {
                security: "col#c4",
                logData: createanotherurl,
              };
              axios.post(myConfig.billing_url + "visitorLog.php", finalobj8);

              if (Response.data.status == "OK") {
                $("#verify").show();
                $("#subscribeMsisdn").hide();
              } else {
                history.push("/fail");
                // window.location.href = '/fail'
              }
            });
          } else {
            window.location.href = "/welcome";
          }
        });

        $("#mob").hide();
      } else {
        console.log("in");
        $("#mob").show();
      }
    } else {
    }
  };

  const sendotpwithnumnber = () => {
    setshow1(false);
    setshow2(true);
  };

  const Exit = () => {
    window.location.href = "/";
    // history.push("/")
  };

  const Cancel = () => {
    window.location.href = "/fail";
  };

  const completecgflow = () => {
    var str = window.location.pathname;
    const dataes = codeJson.filter((i) => i.link1 === str);
    var otpurl =
      myConfig.billing_url +
      "subscription.php?affiliate=" +
      aff_id +
      "&cg_image_url=&mode=WAP&msisdn=" +
      phone +
      "&product_id=" +
      dataes[0].product_id +
      "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=&plan=" +
      plan +
      "&aff_id=" +
      aff_id +
      "&aff_uniq_id=" +
      click_id +
      "&camp_id=" +
      camp_id;
    var queryparams =
      "affiliate = " +
      aff_id +
      " | cg_image_url = xxxxxxxx |mode = WAP | msisdn=" +
      phone +
      "| product_id = " +
      dataes[0].product_id +
      "| result_url = https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F | other1 =  | other2 = &plan=" +
      plan +
      "&aff_id=" +
      aff_id +
      "&aff_uniq_id=" +
      click_id +
      "&camp_id=" +
      camp_id;
    var gettime = new Date();
    var veriftotpurlreq = gettime + "| REQ |" + phone + "|" + myConfig.billing_url + "subscription.php" + "| POST Parameters =>" + queryparams;
    var finalobj9 = {
      security: "col#c4",
      logData: veriftotpurlreq,
    };
    axios.post(myConfig.billing_url + "appLog.php", finalobj9);
    axios.get(otpurl).then((Response) => {
      var obj = {
        status: Response.data.status,
        ip: browser,
        agent: navigator.userAgent,
        response: Response,
        mobile_no: phone,
        productdetails: dataes[0].product_id,
      };
      var obje = JSON.stringify(obj);
      var finalobj = {
        security: "col#c4",
        logData: obje,
      };
      var gettime = new Date();
      var queryparams1 =
        "affiliate=" +
        aff_id +
        "&cg_image_url=&mode=WAP&msisdn=" +
        phone +
        "&product_id=" +
        dataes[0].product_id +
        "&result_url=https%3A%2F%2Fdomain.com%2Fmtn_afg%2Fplan%2Fconsent_return%2F&other1=&other2=";
      var veriftotpurlres = gettime + "| RES |" + phone + "|" + myConfig.billing_url + "subscription.php" + "|" + JSON.stringify(Response.data);
      var finalobj5 = {
        security: "col#c4",
        logData: veriftotpurlres,
      };
      axios.post(myConfig.billing_url + "appLog.php", finalobj5);
      var createanotherurl =
        gettime +
        "|" +
        phone +
        "|" +
        window.location.pathname +
        "|" +
        1111 +
        "|" +
        browser +
        "|" +
        222222 +
        "|" +
        111111 +
        "|" +
        navigator.userAgent +
        "|" +
        otpurl +
        "|" +
        JSON.stringify(Response);
      var finalobj6 = {
        security: "col#c4",
        logData: createanotherurl,
      };
      axios.post(myConfig.billing_url + "visitorLog.php", finalobj6);

      if (Response.data.status == "OK") {
        // history.push('/thankYou')
        // const result = axios(
        //   myConfig.server_url+'checkUser/'+phone,
        //   {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64')}}
        // ).then(res => {
        //   console.log(res)
        //   if (res.data == false) {
        //           var addsub = {
        //               "countryCode": conuty,
        //               "emailId": "string",
        //               "loginType": "mobile",
        //               "name": "",
        //               "phoneNumber": phone,
        //               "userId": phone
        //           }
        //           axios.post(myConfig.server_url+"user/",addsub,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
        //           .then(Response => {
        //               window.sessionStorage.setItem("userId", phone);
        //               $('#verify').hide()
        //               $("#mobotp").hide()
        //               history.push('/welcome')
        //           })
        //       }else {
        //           const result = axios(
        //               myConfig.server_url+'getSubscribedUser/'+phone,
        //               {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
        //             ).then(resp => {
        //               window.sessionStorage.setItem("userId", phone);
        //               localStorage.setItem("name",resp.data.name)
        //               window.sessionStorage.setItem("isLogin", true);
        //               $('#verify').hide()
        //               $("#mobotp").hide()
        //               history.push('/')
        //             })
        //       }
        // })
        // // return  console.log(result)
        //     $('#verify').hide()
        //     $("#mobotp").hide()
        window.location.href = Response.data.url;
      } else {
        history.push("/fail");
      }
    });
  };

  const completecheckflow = () => {
    var str = window.location.pathname;

    var otpurl =
      myConfig.redirect_url +
      "ConsentGateway/queryMsisdn?vendorId=IBUDigitalLtd159&serviceId=" +
      service_no +
      "&tid=TN_SSN_" +
      aff_id +
      "_" +
      click_id +
      "_" +
      camp_id +
      "_" +
      Math.floor(1000 + Math.random() * 9000) +
      "_" +
      new Date().getTime();
    var queryparams =
      "vendorid=IBUDigitalLtd159=&serviceId=" +
      service_no +
      "tid=TN_SSN_" +
      aff_id +
      "_" +
      click_id +
      "_" +
      camp_id +
      "_" +
      Math.floor(1000 + Math.random() * 9000) +
      new Date().getTime();
    var gettime = new Date();
    var veriftotpurlreq = gettime + "| REQ |" + myConfig.redirect_url + "ConsentGateway/queryMsisdn" + "| POST Parameters =>" + queryparams;
    var finalobj9 = {
      security: "col#c4",
      logData: veriftotpurlreq,
    };
    axios.post(myConfig.billing_url + "appLog.php", finalobj9);
    axios.post(myConfig.billing_url + "stagingCount.php?aff_id=" + aff_id + "&operator_id=" + operator_id + "&click_submit_count=1");
    var veriftotpurlreqs =
      gettime +
      "| REQ |" +
      myConfig.billing_url +
      "stagingCount.php" +
      "| POST Parameters =>" +
      "aff_id=" +
      aff_id +
      "&operator_id=" +
      operator_id +
      "&click_submit_count=1";
    var finalobj22 = {
      security: "col#c4",
      logData: veriftotpurlreqs,
    };
    axios.post(myConfig.billing_url + "appLog.php", finalobj22);
    window.location.href = otpurl;
  };

  return (
    <div>
      <div className="landingPage">
        <div className="container">
          <div className="row">
            {cgflow == false ? (
              checkflow == true ? (
                <>
                  {localStorage.getItem("bmsisdn") == "undefined" ||
                  localStorage.getItem("bmsisdn") == undefined ||
                  localStorage.getItem("bmsisdn") == null ? (
                    <div className="col">
                      <div className="heading">{lan.product_name}</div>
                      <div className="subheading">
                        {lan.tosubscribe}
                        <span>{localStorage.getItem("plan") == "daily" ? lan.aoc : lan.aocweekly}</span>
                      </div>
                      <div className="link" id="subscribeMsisdn">
                        <a
                          id="msisdn"
                          className="buttondis"
                          style={{ marginTop: "30px", cursor: "pointer" }}
                          onClick={
                            beeline.test(window.location.pathname) == true ||
                            grameen.test(window.location.pathname) == true ||
                            orange.test(window.location.pathname) == true ||
                            sierra.test(window.location.pathname) == true
                              ? beline
                              : completecheckflow
                          }>
                          {lan.subscribe}
                        </a>
                        <br></br>
                        <a
                          id="msisdn1"
                          className="buttondis"
                          style={{ marginTop: "30px", backgroundColor: "black", color: "white", cursor: "pointer" }}
                          onClick={Exit}>
                          {lan.lwelexit}
                        </a>
                      </div>
                      <br></br>
                      {localStorage.getItem("lang") == "boar" ? (
                        <div className="termsHeading">წესები და პირობები</div>
                      ) : (
                        <div className="termsHeading">Terms and Conditions</div>
                      )}

                      <div className="termsData">
                        {localStorage.getItem("plan") == "daily" ? (
                          <>
                            {lan.terms === undefined
                              ? ""
                              : lan.terms.split("\n").map((str) => (
                                  <>
                                    {" "}
                                    <p style={{ textAlign: "left" }}>
                                      <img height="14" width="14" src={`${bulletImage}`} />
                                      {str}
                                    </p>
                                  </>
                                ))}{" "}
                            <br></br>
                            {lan.lwelcometermsbr === undefined
                              ? ""
                              : lan.lwelcometermsbr.split("\n").map((str) => (
                                  <>
                                    {" "}
                                    <p style={{ textAlign: "left" }}>
                                      <img height="14" width="14" src={`${bulletImage}`} />
                                      {str}
                                    </p>
                                  </>
                                ))}
                          </>
                        ) : (
                          lan.termsweekly.split("\n").map((str) => (
                            <>
                              {" "}
                              <p style={{ textAlign: "left" }}>
                                <img height="14" width="14" src={`${bulletImage}`} />
                                {str}
                              </p>
                            </>
                          ))
                        )}{" "}
                        <br />
                        <a href="/tnc">click here</a>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* code for page popup*/}
                      <div className="col">
                        {localStorage.getItem("lang") == "boar" ? (
                          <div className="subheading" style={{ fontSize: "1.2rem" }}>
                            ნომრის {localStorage.getItem("bmsisdn")} ბალანსიდან ყოველდღე ჩამოიჭრება სერვისის საფასური 2 ₾. ადასტურებ სერვისის
                            აქტივაციას?
                          </div>
                        ) : (
                          <div className="subheading" style={{ fontSize: "1.2rem" }}>
                            A service fee of 2 ₾ will be deducted from the balance of number {localStorage.getItem("bmsisdn")} every day. Confirm
                            service activation?
                          </div>
                        )}
                        <div className="link" id="subscribeMsisdn">
                          <a id="msisdn" className="buttondis" style={{ marginTop: "30px", cursor: "pointer", width: "130px" }} onClick={subConfirm}>
                            {lan.ok}
                          </a>
                          <a
                            id="msisdn1"
                            className="buttondis"
                            style={{ marginTop: "30px", backgroundColor: "black", color: "white", cursor: "pointer" }}
                            onClick={callCancel}>
                            {lan.cancel}
                          </a>
                        </div>
                        <br></br>
                        {localStorage.getItem("lang") == "boar" ? (
                          <div className="termsHeading">წესები და პირობები</div>
                        ) : (
                          <div className="termsHeading">Terms and Conditions</div>
                        )}

                        <div className="termsData" content="text/html;charset=utf-8">
                          {localStorage.getItem("plan") == "daily"
                            ? lan.terms === undefined
                              ? ""
                              : lan.terms.split("\n").map((str) => (
                                  <>
                                    {" "}
                                    <p style={{ textAlign: "left" }}>
                                      <img height="14" width="14" src={`${bulletImage}`} />
                                      {str}
                                    </p>
                                  </>
                                ))
                            : lan.termsweekly === undefined
                            ? ""
                            : lan.termsweekly.split("\n").map((str) => (
                                <>
                                  {" "}
                                  <p style={{ textAlign: "left" }}>
                                    <img height="14" width="14" src={`${bulletImage}`} />
                                    {str}
                                  </p>
                                </>
                              ))}{" "}
                          <br />
                          <a href="/tnc">click here</a>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="col">
                  {/* 
                    <div className="heading">{lan.product_name}</div> */}
                  <div className="subheading">
                    {/* lan.tosubscribe */}

                    <span>{localStorage.getItem("plan") == "daily" ? lan.aoc : lan.aocweekly}</span>
                  </div>

                  <div className="link" id="subscribeMsisdn">
                    <div>
                      <img style={{ width: "10%" }} src="/asset/images/imagelandingg.png" />
                    </div>  
                    {/* <h4>{lan.enterotp}</h4> */}
                    <div className="subheading">{lan.otp}</div>

                    {virgin.test(window.location.pathname) == false || phones == "" ? (
                      <input className="subscribeText1" type="text" value={conutys} readOnly />
                    ) : (
                      ""
                    )}
                    <input
                      className="subscribeText"
                      onChange={checknumber}
                      type="text"
                      value={phone}
                      placeholder={lan.enterotp}
                      id="phoneNoSub"
                      pattern="\d*"
                    />
                    <div
                      id="mob"
                      style={{ color: "white", display: "none", color: "red", marginBottom: "20px", fontSize: "14px", marginTop: "10px" }}></div>
                    <br></br>
                    <a
                      id="msisdn"
                      className="buttondis"
                      style={{ marginTop: "20px", cursor: "pointer" }}
                      onClick={
                        sierra.test(window.location.pathname) == true || beeline.test(window.location.pathname) == true ? sendBeelineOtp : sendotp
                      }>
                      {lan.subscribe}
                    </a>
                    <a
                      id="msisdn1"
                      className="buttondis"
                      style={{ marginTop: "20px", backgroundColor: "black", color: "white", cursor: "pointer" }}
                      onClick={Exit}>
                      {lan.lwelexit}
                    </a>
                  </div>
                  <br></br>
                  <span>{localStorage.getItem("plan") == "daily" ? lan.aoc : lan.aocweekly}</span>
                  <br></br>
                  <br></br>
                  {lan.afterClick}
                  <div className="link" id="verify">
                    <h4 style={{ marginBottom: "18px", fontSize: "19px", fontWeight: "800" }}>{lan.lwelcomeenterotp}</h4>
                    <input
                      className="subscribeText"
                      type="text"
                      onChange={checkotp}
                      placeholder="Enter OTP"
                      id="otpSub"
                      pattern="\d*"
                      maxlength="6"
                    />
                    <div
                      id="mobotp"
                      style={{ color: "white", display: "none", color: "red", marginBottom: "20px", fontSize: "14px", marginTop: "10px" }}>
                      Please Enter 4 Digit Valid Otp
                    </div>
                    <div
                      id="mobotp5"
                      style={{ color: "white", display: "none", color: "red", marginBottom: "20px", fontSize: "14px", marginTop: "10px" }}>
                      Please Enter 5 Digit Valid Otp
                    </div>
                    <br></br>
                    <a id="verifyBtn" className="buttondis" style={{ marginTop: "30px", cursor: "pointer" }} onClick={verifyotp}>
                      {lan.lwelcomeconform}
                    </a>
                    <br></br>
                    <a
                      id="msisdn2"
                      className="buttondis"
                      style={{ marginTop: "30px", backgroundColor: "black", color: "white", cursor: "pointer" }}
                      onClick={Exit}>
                      {lan.lwelexit}
                    </a>
                  </div>
                  <br></br>
                  {tmobile.test(window.location.pathname) == true ||
                  play.test(window.location.pathname) == true ||
                  plus.test(window.location.pathname) == true ? (
                    <>
                      <div style={{ height: "300px", width: "100%", textAlign: "initial", overflowX: "auto", margin: "10vh 10vh 0vh 3vh" }}>
                        Serwis Sky Sports Network stanowi świadczenie dodatkowe w ramach usługi o podwyższonej opłacie i jest co tydzień usługą
                        subskrypcyjną, która umożliwia dostęp do najlepszych sportowych treści multimedialnych dostępnych na urządzeniach mobilnych i
                        komputerach (“Serwis”).
                        <br></br>
                        <br></br>
                        Koszt dostępu do Serwisu wynosi 19 zł / tydzień brutto (pobierane będą 1 opłaty po 19 zł brutto na tydzień)
                        <br></br>
                        <br></br>
                        Serwis dostępny jest na telefony komórkowe bądź inne urządzenia końcowe co tydzień, posiadające kartę SIM w sieci Orange,
                        Plus, T-Mobile & Play.
                        <br></br>
                        <br></br>
                        Opłata zostanie doliczona do Twojego rachunku w u twojego operatora (telefon na abonament) lub odliczona od środków na koncie
                        (telefon na kartę).
                        <br></br>
                        <br></br>
                        Połączenie internetowe jest konieczne dla korzystania z otrzymanych w ramach Serwisu treści multimedialnych. Do opłaty za
                        dostęp do Serwisu, nie są wliczone opłaty za transmisję danych. W przypadku gdy korzystanie z Serwisu wymaga pobierania przez
                        Ciebie danych z wykorzystaniem transmisji GSM, opłaty te będą pobierane przez Operatora, zgodnie z aktualnym cennikiem
                        zakontraktowanego Operatora.
                        <br></br>
                        <br></br>
                        Aby dokonać zamówienia, musisz zapoznać się z treścią przedstawioną na Stronie Aktywacyjnej i podążać za instrukcjami na niej
                        wskazanymi.
                        <br></br>
                        <br></br>
                        Rejestracja do Serwisu jest jednoznaczna z akceptacją Regulaminu i Polityki Prywatności. Po rejestracji do Serwisu, otrzymasz
                        bezpłatną wiadomość powitalną potwierdzającą, że rejestracja do usługi zakończyła się powodzeniem.
                        <br></br>
                        <br></br>
                        Usługa jest aktywowana na czas nieokreślony, do czasu aż z niej zrezygnujesz. Aby dokonać rezygnacji należy wysłać SMS o
                        treści STOP SKY na 80708 (Orange) lub 80425 (T-Mobile & Plus) lub 191 (Play), koszt: 0 zł.
                        <br></br>
                        <br></br>
                        Usługodawcą i podmiotem realizującym dodatkowe świadczenie jest: IBU Digital Ltd, 104 Ruislip Road Greenford United Kingdom
                        UB69QH, zarejestrowany w Rejestrze Handlowym w London United Kingdom pod numerem 13614800 (“Organizator”).
                        <br></br>
                        <br></br>
                        Kontakt, w razie reklamacji: info-pl@mobileinfo.biz, numer telefoniczny: 800 007 173 (opłata lokalna) od poniedziałku do
                        piątku w godzinach 09:00 – 17:00.
                        <br></br>
                        <br></br>
                        Organizator będzie kontaktować się z Klientem na podany przez niego numer telefonu lub e-mail. Regulamin usługi:
                        https://m.skysportsnetwork.com/tnc .<br></br>
                        <br></br>
                        Poprzez przyciśniecie na przycisk Subskrybuj użytkownik wyraźnie potwierdza, że wie, że zamówienie pociąga za sobą obowiązek
                        zapłaty poprzez rachunek telefoniczny według powyżej opisanej częstotliwości Serwisu. Dalej użytkownik potwierdza, że zapoznał
                        się z treścią niniejszego Regulaminu organizatora i go wyraźnie akceptuje, a także zgadza się na natychmiastowe rozpocznie
                        świadczenia Serwisu i związaną z tym utratę jego prawa do odstąpienia od umowy o udostępnianie Serwisu w przeciągu 14 dni.
                        Ponadto, wyrażasz również zgodę na to, że niezwłoczne pobierzesz i zachowasz obowiązującą kopię Regulaminu usługi Organizatora
                        w chwili zawarcia umowy o usługę (potwierdzenie zawarcia umowy), który będzie ci udostępniony (w postaci linka www) w
                        otrzymanym przez ciebie SMS-ie potwierdzającym zawarcie umowy na usługę.
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {tmobile.test(window.location.pathname) == true ||
                  play.test(window.location.pathname) == true ||
                  plus.test(window.location.pathname) == true ? (
                    <div style={{ margin: "0% 40%" }}>
                      <div className="termsHeading" style={{ float: "left" }}>
                        Zasady i warunki:
                      </div>
                      <div className="termsData" style={{ float: "left" }}>
                        <a href="/tnc">Kliknij tutaj</a>
                      </div>
                    </div>
                  ) : (
                    <>
                      {localStorage.getItem("lang") == "boar" ? (
                        <div className="termsHeading">წესები და პირობები</div>
                      ) : (
                        <div className="termsHeading" style={{ textAlign: "left" }}>
                          Terms & Condition
                        </div>
                      )}
                      <p style={{ textAlign: "left" }}> {lan.byClick}</p>

                      <div className="termsData">
                        {localStorage.getItem("plan") == "daily"
                          ? lan.terms === undefined
                            ? ""
                            : lan.terms.split("\n").map((str, index) => {
                                console.log(index);
                                console.log(lan.terms.split("\n").length);
                                if (index == lan.terms.split("\n").length-1) {
                                  return (
                                    <p style={{ textAlign: "left" }}>
                                      <img height="14" width="14" src={`${bulletImage}`} />
                                      {str}  <a href="/tnc">click here</a>
                                    </p>
                                  );
                                }
                                return (
                                  <p style={{ textAlign: "left" }}>
                                    <img height="14" width="14" src={`${bulletImage}`} />
                                    {str}
                                  </p>
                                );
                              })
                          : lan.termsweekly === undefined
                          ? ""
                          : lan.termsweekly.split("\n").map((str) => (
                              <>
                                {" "}
                                <p style={{ textAlign: "left" }}>
                                  <img height="14" width="14" src={`${bulletImage}`} />
                                  {str}
                                </p>
                              </>
                            ))}
                        {lan.lwelcometermsbr === undefined
                          ? ""
                          : lan.lwelcometermsbr.split("\n").map((str) => (
                              <>
                                {" "}
                                <p style={{ textAlign: "left" }}></p>
                              </>
                            ))}
                      </div>
                    </>
                  )}
                </div>
              )
            ) : (
              <>
                {show1 == true ? (
                  <div className="col">
                    <div className="heading">{lan.product_name}</div>
                    <div className="subheading">
                      {lan.tosubscribe}
                      <span>{localStorage.getItem("plan") == "daily" ? lan.aoc : lan.aocweekly}</span>
                    </div>
                    <div className="link" id="subscribeMsisdn">
                      <a id="msisdn" className="buttondis" style={{ marginTop: "30px", cursor: "pointer" }} onClick={completecgflow}>
                        {lan.lwelcomeconform}
                      </a>
                      <br></br>
                      <a
                        id="msisdn1"
                        className="buttondis"
                        style={{ marginTop: "30px", backgroundColor: "black", color: "white", cursor: "pointer" }}
                        onClick={Exit}>
                        {lan.lwelCancel}
                      </a>
                    </div>
                    <br></br>
                    <div className="termsHeading">Terms and Conditions:</div>
                    <div className="termsData">
                      {localStorage.getItem("plan") == "daily"
                        ? lan.terms === undefined
                          ? ""
                          : lan.terms.split("\n").map((str) => (
                              <>
                                {" "}
                                <p style={{ textAlign: "left" }}>
                                  <img height="14" width="14" src={`${bulletImage}`} />
                                  {str}
                                </p>
                              </>
                            ))
                        : lan.termsweekly === undefined
                        ? ""
                        : lan.termsweekly.split("\n").map((str) => (
                            <>
                              {" "}
                              <p style={{ textAlign: "left" }}>
                                <img height="14" width="14" src={`${bulletImage}`} />
                                {str}
                              </p>
                            </>
                          ))}{" "}
                      <br />
                      <a href="/tnc">click here</a>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPageNew;
