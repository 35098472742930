import React, {Component} from 'react';
import { myConfig } from '../config';
import $, { data } from 'jquery';
import axios from 'axios';
import vasrJson from './vars.json'
import { Link, NavLink } from "react-router-dom";
// const queryString = require('query-string');

import codeJson from '../CountryCodes.json';
class Highlights extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            loading : true,
            categories:[],
            catIndex:0,
            subCat:[],
            current:''
        };
        this.appLog = this.appLog.bind(this);
        // console.log(new URLSearchParams(this.props.location.ind));
      }
    async componentDidMount() {

    if(localStorage.getItem('highlightsindex') == null) {
        localStorage.setItem('highlightsindex',0)
    }
    
    var str = window.location.href.split('/')
    var index = str[str.length - 1]
    var checkhomepagedata = JSON.parse(localStorage.getItem("listHomePage"))
    const dataArray = codeJson.filter(i => i.code ===  window.sessionStorage.getItem("sCountryCode"))
    if(dataArray.length>0){
        window.sessionStorage.setItem("dialCode", dataArray[0].dial_code.slice(1))  
    }else{
        window.sessionStorage.setItem("dialCode", '91')  
    }
    const dCode = window.sessionStorage.getItem("dialCode")
    var apiurl = myConfig.server_url+"listHomePage/country/"+dCode
    axios.get(apiurl,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64')}})
    .then(res => {
        this.appLog(apiurl,res)
        var str = parseInt(localStorage.getItem('highlightsindex'));
        var chnageindex =  parseInt(localStorage.getItem('highlightsindex'))
        // console.log(id);
        for(var i = 0; i < res.data.homepage[0].categories.length; i++) {
            if (i == str) {
                res.data.homepage[0].categories[i]['is_active'] = true    
            }else {
                res.data.homepage[0].categories[i]['is_active'] = false
            }   
        }
        this.setState({ subCat: res.data.homepage[0].categories[0] });
        this.setState({ categories: res.data.homepage[0].categories });
        localStorage.setItem("listHomePage", JSON.stringify(res.data));
        this.setState({catIndex: chnageindex});  
        this.setState({current : res.data.homepage[0].categories[chnageindex].categoryName})
    })
    }

    sendBackData = () => {
        const dCode = window.sessionStorage.getItem("dialCode") 
        var apiurl = myConfig.server_url+"listHomePage/country/"+dCode
        axios.get(apiurl,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
        .then(res => {
            this.appLog(apiurl,res)
        this.props.parentCallback(res.data.homepage[0].categories[this.state.catIndex].categoryName);
        })
    }

    handleLogin(path) {
        var login = window.sessionStorage.getItem('isLogin');
        console.log(login)
        if (login == "true" || login == true) {
            window.location.href = path
        }else {
            window.sessionStorage.setItem("lastClick", path);
             $('#loginpopup').show();
        }
    }

    sethighlightindex(i) {
        console.log("here it is",i)
        localStorage.setItem('highlightsindex', i)
    }

    appLog(url, res) {
        var browser = (function() {
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
            })();
            if(window.sessionStorage.getItem('userId') != undefined) {
                var gettime = new Date();
                var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
                var finalobj6 = {
                security : "col#c4",
                logData : createanotherurl
                }
                axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
            }
    }

    render() {
        // let url = this.props.location.search;
        // console.log(url);
        const updateFieldChanged = (index,category) => e => {
            console.log(index)
            var str = this.props.id != undefined ? parseInt(this.props.id) -1  : index 
            for (let j = 0; j < this.state.categories.length; j++) {
                if(this.state.categories[j].categoryId == category) {
                    this.state.categories[j].is_active = true 
                }else {
                    this.state.categories[j].is_active = false
                }                
            }
            console.log(str);
           this.setState({catIndex: str});
          }
	    return (
            <div  style={{marginTop:"-2%"}} >
                {this.props.type == "category" ? <div className="gameTop center">
                    <img src={vasrJson.categories[this.state.current]} alt="" className="full" />
                    
                    <div className="pos">
                        <div className="container">
                            <div className="row">
                                <div className="col50">
                                    <div className="heading">{this.state.current}</div>
                                    {/* <div className="caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</div> */}
                                </div>
                                <div className="clr"></div>
                            </div>
                        </div>
                    </div>
                </div>:""}
                <div className="highlights">
                    <div className="container">
                        <div className="categories">
                        <div class="label forMobile">Categories</div>
                            <ul>
                                {
                                    this.state.categories.map((category,index)=>{
                                        return (
                                                <li className={category.is_active == true ? "active" : ""} /*onClick={updateFieldChanged(index,category.categoryId)}*/ key={category.categoryId} ><a href={"/categories/"+category.categoryId+"/"+index} onClick={this.sethighlightindex.bind(this,index)}><img src={category.categoryIconUrl} />{category.categoryName}</a></li>
                                                 /*<li className={category.is_active == true ? "active" : ""} onClick={updateFieldChanged(index,category.categoryId)} key={category.categoryId} ><a href="javascript:void(0)" onclick="return false;"><img src={category.categoryIconUrl} />{category.categoryName}</a></li>*/
                                        )
                                    })
                                }
                            </ul>
                        </div>
                   
                    </div>
                    <div className="clr"></div>
                </div> 
                {
                    this.state.categories.length?
                    this.state.categories[this.state.catIndex].subcategories.map((subCategory)=>{return(
                        
                        <div className="highlights">
                            
                            <div className="container">
                                <div className="row">
                                    <div className="col">
                                        <div className="title">{subCategory.subcategoryName} <a href="javascript:void(0)" onClick={this.handleLogin.bind(this,"/viewmore/"+this.state.categories[this.state.catIndex].categoryId+"/"+subCategory.subcategoryId) } className="fr forDesktop">View More</a><div className="clr"></div></div>
                                    </div>
                                </div>
                                
                                <div className="clr"></div>
                                {this.props.type != "category" ?
                                    <div className="row">
                                        {
                                            subCategory.contentDetails.map((content,i)=>{
                                                if (subCategory.subcategoryClass == "large") {
                                                    if (i > 1) {
                                                        return
                                                    }
                                                }

                                                if (subCategory.subcategoryClass == "small") {
                                                    if (i > 2) {
                                                        return
                                                    }
                                                }

                                                return(
                                                    <div className={subCategory.subcategoryClass === "small" ? "col33 fl": i == 1 ? "col35 fl" : "col65 fl" }>
                                                        <a href="javascript:void(0)" onClick={this.handleLogin.bind(this,"/details/"+content.contentDtl.id+"/"+subCategory.subcategoryId+"/"+this.state.categories[this.state.catIndex].categoryId)}>
                                                        <div className="mocImage">
                                                            <div className="h2">
                                                            <img className="resize" src={content.contentDtl.thumbnilUrl} alt=""/>
                                                            </div>
                                                            <div className="content">
                                                                <div className="timePoint">
                                                                    <span>
                                                                        <img src="/asset/images/WallClock1.svg" alt="" className="resize"/>
                                                                        {content.contentDtl.duration}
                                                                    </span>
                                                                    {/* <h1>{subCategory.subcategoryClass}</h1> */}
                                                                    <span>
                                                                        <img src="/asset/images/Eye.svg" alt=""/>
                                                                        21.3k Views
                                                                    </span>
                                                                </div>
                                                                <div className="heading">{content.contentDtl.contentTitle}</div>
                                                            </div>
                                                        </div>
                                                        </a>
                                                    </div>
                                                    // </div>
                                                )
                                            })
                                        }
                                        <div className="clr"></div>
                                    </div>                                 
                                 :
                                    <div className="row">
                                 {
                                     subCategory.contentDetails.map((content,i)=>{
                                         if (subCategory.subcategoryClass == "large") {
                                             if (i > 3) {
                                                 return
                                             }
                                         }

                                         if (subCategory.subcategoryClass == "small") {
                                             if (i > 7) {
                                                 return
                                             }
                                         }

                                         return(
                                             <div className={subCategory.subcategoryClass === "small" ? "col255 fl" :  "col255 fl" }>
                                                 <a href="javascript:void(0)" onClick={this.handleLogin.bind(this,"/details/"+content.contentDtl.id+"/"+subCategory.subcategoryId+"/"+this.state.categories[this.state.catIndex].categoryId)}>
                                                 <div className="mocImage image-margin">
                                                     <div className={subCategory.subcategoryClass === "small" ? "h5":"h2"}>
                                                     <img className="resize" src={content.contentDtl.thumbnilUrl} alt=""/>
                                                     </div>
                                                     <div className="content">
                                                         <div className="heading">{content.contentDtl.contentTitle}</div>
                                                     </div>
                                                 </div>
                                                 </a>
                                             </div>
                                             // </div>
                                         )
                                     })
                                 }
                                 <div className="clr"></div>
                                    </div>
                                 }
                                
                                <div className="clr"></div>
                                <div className="row">
                                    <div className="col">
                                        <div className="title forMobile"><a href="javascript:void(0)" onClick={this.handleLogin.bind(this,"viewmore/"+this.state.categories[this.state.catIndex].categoryId+"/"+subCategory.subcategoryId) } className="fr forMobile">View More</a><div className="clr"></div></div>
                                    </div>
                                </div>
                            </div>
                            <div className="clr"></div>
                        </div>
                    )}):''
                }
            </div>
        )
    }
}

export default Highlights;
