import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import Highlights from '../Pages/Highlights';
import { myConfig } from '../config';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
  } from "react-router-dom";


function CategoryPage(props) {
    const { cId } = useParams()
    const [categories , setCategories] = useState([]);
    const [list ,setlist] = useState([]);
    useEffect(() => {
        console.log(cId);
        var countrycode = sessionStorage.getItem('dialCode')
        console.log(countrycode);
        const fetchData = async () => {
        var url = myConfig.server_url+'contentDetailByCategoryId/?categoryId='+cId+'&contrycode='+countrycode 
        const result = await axios(
            url,
          {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
        );
        appLog(url, result)
        //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
        // setCategories(result.data.list);
        setlist(result.data.list)
      };
      fetchData();
      localStorage.setItem("activetab","category")
    }, []);

    function appLog(url, res) {
        var browser = (function() {
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
            })();
            if(window.sessionStorage.getItem('userId') != undefined) {
                var gettime = new Date();
                var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
                var finalobj6 = {
                security : "col#c4",
                logData : createanotherurl
                }
                axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
            }
    }

  return (
    <>
        <div className="highlights" style={{marginTop:"-0.5%"}}>
                    <div className="container">
                        <div className="categories">
                        <div class="label forMobile">Categories</div>
                            <ul>
                                {
                                    categories.map((category,index)=>{
                                        return (
                                                <li className={category.is_active == true ? "active" : ""} /*onClick={updateFieldChanged(index,category.categoryId)}*/ key={category.categoryId} ><a href={"/categories/"+category.categoryId} onclick="return false;"><img src={category.categoryIconUrl} />{category.categoryName}</a></li>
                                                 /*<li className={category.is_active == true ? "active" : ""} onClick={updateFieldChanged(index,category.categoryId)} key={category.categoryId} ><a href="javascript:void(0)" onclick="return false;"><img src={category.categoryIconUrl} />{category.categoryName}</a></li>*/
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="clr"></div>
        </div> 
        {/* <div className="gameTop center">
        <img src="/asset/images/categories.png" alt="" className="full" />
        <div className="pos">
            <div className="container">
                <div className="row">
                    <div className="col50">
                        <div className="heading">Cricket</div>
                        <div className="caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</div>
                    </div>
                    <div className="clr"></div>
                </div>
            </div>
        </div>
    </div> */}
    <Highlights id={cId} type="category"/>,

    {/* <div className="searchVideos nopaddingBottom">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="title">IPL 2021</div>
                </div>
            </div>
            <div className="clr"></div>
            <div className="row">

                {
                     list.splice(0,8).map((list,index)=>{
                        return (
                                <div className="col255 fl">
                                    <div className="image">
                                        <a href="">
                                            <div className="h5">
                                                <img src={list.thumbnilUrl} alt="" className="resize" />
                                            </div>
                                            <div className="caption">{list.contentName}</div>
                                        </a>
                                    </div>
                                </div>
                        )
                    })
                }
               
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Panther</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Premi O Premi</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Pashan</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Avengers Infinity War</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Panther</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Premi O Premi</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Pashan</div>
                        </a>
                    </div>
                </div>
                
                <div className="clr"></div>
            </div>
        </div>
    </div>
    <div className="searchVideos nopaddingBottom">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="title">Latest Cricket matches Videos</div>
                </div>
            </div>
            <div className="clr"></div>
            <div className="row">
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                           
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            
                        </a>
                    </div>
                </div>
                <div className="clr"></div>
            </div>
        </div>
    </div>
    <div className="searchVideos nopaddingBottom">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="title">ICC World Cup 2021</div>
                </div>
            </div>
            <div className="clr"></div>
            <div className="row">
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Avengers Infinity War</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Panther</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Premi O Premi</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Pashan</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Avengers Infinity War</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Panther</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Premi O Premi</div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h5">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                            <div className="caption">Pashan</div>
                        </a>
                    </div>
                </div>
                
                <div className="clr"></div>
            </div>
        </div>
    </div> */}
    </>
  );
}

export default CategoryPage;