import React, { useState, useRef, useEffect } from "react";



function GamePage(props) {
  const { children, open, value } = props;

  return (
    <>
          <div className="gameTop">
        <img src="/asset/images/Group36693.png" alt="" className="full" />
        <div className="pos">
            <div className="container">
                <div className="row">
                    <div className="col30 fl">
                        <a href="#"><img src="/asset/images/PlayButton.svg" alt="" className="playBtn" /></a>
                    </div>
                    <div className="col40 fr">
                        <div className="heading">Crysis</div>
                        <div className="caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</div>
                    </div>
                    <div className="clr"></div>
                </div>
            </div>
        </div>
    </div>
    <div className="searchVideos onTop">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="title">Latest Games</div>
                </div>
            </div>
            <div className="clr"></div>
            <div className="row">
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="col255 fl">
                    <div className="image">
                        <a href="">
                            <div className="h2">
                                <img src="/asset/images/1220.jpg" alt="" className="resize" />
                            </div>
                        </a>
                    </div>
                </div>
                
                <div className="clr"></div>
            </div>
        </div>
    </div>
    </>
  );
}

export default GamePage;