import React, {Component} from 'react';
import $ from 'jquery';


class HerHim extends Component {
    constructor(props) {
        super(props);
      }
     
      handleLogin(path) {
        var login = window.sessionStorage.getItem('isLogin');
        console.log(login)
        if (login == "true" || login == true) {
            window.location.href = path
        }else {
             $('#loginpopup').show();
        }
      }

    render(props) {
	    return (
            <div className="herHim">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="locImage">
                                <img src="/asset/images/VideoPlaceholder.png" alt="" />
                                <div className="content">
                                    <div className="heading">Her & Him</div>
                                    <div className="cont">{/*Lorem ipsum dolor sit amet, consec tetur adipis cing<br />elit, sed do eiusmod tempor incididunt ut labore et.*/}</div>
                                </div>
                                <a href="javascript:void(0)" className="circle"><img src="/asset/images/PlayCircle.svg" alt="" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="clr"></div>
                    <div className="row blocks">
                        {
                            this.props.cats.length?
                            this.props.cats.map((cat,idx)=>{
                                return(
                                    idx>2?'':
                                   
                                        <div className="col33 fl">
                                            <div className="blocksAr">
                                                <div className="image fl">
                                                    <a href="javascript:void(0)" onClick={this.handleLogin.bind(this,"/details/"+cat.subcategories[0].contentDetails[0].contentDtl.id+"/"+cat.subcategories[0].subcategoryId+"/"+cat.categoryId)}>
                                                    
                                                    <div class="h3">
                                                        <img src={cat.subcategories[0].contentDetails[0].contentDtl.thumbnilUrl} alt="" class="resize"/>
                                                    </div>
                                                        <img  src="/asset/images/PlayCircle.svg" alt="" className="play" />
                                                    </a>
                                                    </div>
                                                        <div className="imageContent fr">
                                                            <div className="cap">{cat.categoryName}</div>
                                                            <div className="heading">{cat.categoryName}</div>
                                                            <div className="cont">{cat.subcategories[0].contentDetails[0].contentDtl.contentDescription}</div>
                                                                <div className="timePoint">
                                                                    <span>
                                                                        <img src="/asset/images/WallClock1.svg" alt="" />
                                                                        {cat.subcategories[0].contentDetails[0].contentDtl.duration} Hr 0 Min
                                                                    </span>
                                                                    <span>
                                                                        <img src="/asset/images/Calendar1.svg" alt="" />
                                                                        June 2 2021
                                                                    </span>
                                                                </div>
                                                        </div>
                                                        <div className="clr"></div>
                                                    </div>
                                                </div>                                
                                )
                            })
                           :'' 
                        }
                        <div className="clr"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HerHim;
