import React, {Component} from 'react';
import { myConfig } from '../config';
import axios from 'axios';
import $ from 'jquery';
class SearchPage extends Component {
    constructor(props) {
        super(props);
        this.onInputchange = this.onInputchange.bind(this);
        this.state = {
            loading : true,
            categories:[],
            search:'',
            searchData:[],
            subCat:[],
            result:[]
        };
        this.appLog = this.appLog.bind(this);
             
        var arr =[]
        var arr2 = [];
        var url = myConfig.server_url+"listHomePage/"
        axios.get(URL,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
        .then(res => {
            this.appLog(url,res)
            for (let i = 0; i < res.data.homepage.length; i++) {
                for (let j = 0; j < res.data.homepage[i].categories.length; j++) {
                    for (let k = 0; k < res.data.homepage[i].categories[j].subcategories.length; k++) {
                        for (let v = 0; v < res.data.homepage[i].categories[j].subcategories[k].contentDetails.length; v++) {
                            res.data.homepage[i].categories[j].subcategories[k].contentDetails[v].contentDtl.catId = res.data.homepage[i].categories[j].categoryId;
                            res.data.homepage[i].categories[j].subcategories[k].contentDetails[v].contentDtl.subCatId = res.data.homepage[i].categories[j].subcategories[k].subcategoryId;
                                arr.push(res.data.homepage[i].categories[j].subcategories[k].contentDetails[v].contentDtl)
                        }
                    }
                }
            }
            console.log(arr)
            function userExists(id) {
                return arr2.some(function(el) {
                  return el.id === id;
                }); 
              }

            for (let m = 0; m < arr.length; m++) {
               var test =  userExists(arr[m].id)
               console.log(test);
                if (test == false) {
                    arr2.push(arr[m])
                }
            }
            
            this.state.result = arr2
            this.forceUpdate();
            console.log(this.state.result);
        })

      }

    appLog(url, res) {
        var browser = (function() {
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
            })();
            if(window.sessionStorage.getItem('userId') != undefined) {
                var gettime = new Date();
                var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
                var finalobj6 = {
                security : "col#c4",
                logData : createanotherurl
                }
                axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
            }
    }
    
    onInputchange(event) {
        console.log(event.target.value);
        this.setState({
            search: event.target.value
        });
    }

    handleClick = () => {
    console.log("click in")
    $('.loader').show()
    var arr =[]
    var arr2 = [];
    var url = myConfig.server_url+"listHomePage/"
    axios.get(url,
    {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
    .then(res => {
        this.appLog(url,res)
        for (let i = 0; i < res.data.homepage.length; i++) {
            for (let j = 0; j < res.data.homepage[i].categories.length; j++) {
                for (let k = 0; k < res.data.homepage[i].categories[j].subcategories.length; k++) {
                    for (let v = 0; v < res.data.homepage[i].categories[j].subcategories[k].contentDetails.length; v++) {
                        res.data.homepage[i].categories[j].subcategories[k].contentDetails[v].contentDtl.catId = res.data.homepage[i].categories[j].categoryId;
                        res.data.homepage[i].categories[j].subcategories[k].contentDetails[v].contentDtl.subCatId = res.data.homepage[i].categories[j].subcategories[k].subcategoryId;
                            arr.push(res.data.homepage[i].categories[j].subcategories[k].contentDetails[v].contentDtl)
                    }
                }
            }
        }
        console.log(arr)
        function userExists(id) {
            return arr2.some(function(el) {
                return el.id === id;
            }); 
            }

        for (let m = 0; m < arr.length; m++) {
            var test =  userExists(arr[m].id)
            if (test == false) {
                arr2.push(arr[m])
            }
        }
        
        this.state.result = arr2.filter(character => character.contentTitle.toLowerCase().includes(this.state.search.toLowerCase()));
        $('.loader').hide()
        this.forceUpdate();
        console.log(this.state.result);
    })



    // this.forceUpdate();
    };

    handleLogin(path) {
    var login = window.sessionStorage.getItem('isLogin');
    console.log(login)
    if (login == "true" || login == true) {
        window.location.href = path
    }else {
        window.sessionStorage.setItem("lastClick", path);
            $('#loginpopup').show();
    }
    }

    render() {

         const  onChangeOfInput =(name,e) =>{
             console.log(e.target.value);
            // this.setState({
            //     search: value
            //   });
        }
        
	    return (
            <div>
                <div class="loader" style={{display:"none"}}></div>
                <div class="searchVideos">
                    <div class="container">
                        <div class="row">
                            <div class="col searchParent">
                            
                                <input type="text" class="searchBar" onChange={this.onInputchange} />
                                <button onClick={this.handleClick}><img src="asset/images/Search.svg" alt="" /></button>
                            </div>
                        </div>
                        <div class="clr"></div>
                        <div class="row">
                            <div class="col">
                                <div class="title">Showing all results for <span>{this.state.search}</span></div>
                            </div>
                        </div>
                        <div class="clr"></div>
                        <div class="row">
                        {
                            this.state.result.length?
                            this.state.result.map((category,index)=>{                                
                                return (
                                    <div class="col255 fl">
                                        {console.log(category)}
                                        <div class="image">
                                            <a href="javascript:void(0)" onClick={this.handleLogin.bind(this,"/details/"+category.id+"/"+category.subCatId+"/"+category.catId)}>
                                                <div className="h3"><img src={category.thumbnilUrl} alt="" className="resize"/></div>
                                                <div class="caption">{category.contentTitle}</div>
                                            </a>
                                        </div>
                                    </div>
                                        
                                )
                            }):''           
                              }
                      <div class="clr"></div>
                        </div>
                    </div>
                </div>
            </div>
                )
    }
}

export default SearchPage;
