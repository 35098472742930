import React, {Component} from 'react';
import { myConfig } from '../config';
import axios from 'axios';
import $ from 'jquery';
class ViewMorePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading : true,
        };
    }
             
    render() {
        const backbutton = () => e => {
            window.location.href="/"
        }
        return (
                    <div class="searchVideos">
                    <div class="container">
                        
                        <div class="row">
                            <div class="col">
                                <div class="title"><i onClick={backbutton()} class="fa fa-arrow-left" aria-hidden="true"></i>{console.log(this.props)}</div>
                            </div>
                        </div>
                        <div class="clr"></div>
                        <div class="row">
                        {
                            //console.log(this.props.data.results)
                            this.props.data.results.length?
                            this.props.data.results.map((con)=>{
                                    return(
                                        <div class="col255 fl">
                                
                                            <div class="image">
                                                <a href={"/details/"+con.id+"/"+this.props.data.catId+"/"+this.props.data.subCatId}>
                                                    <div class="h5">
                                                        <img src={con.thumbnilUrl} alt="" class="resize" />
                                                    </div>
                                                    <div class="caption">{con.contentTitle}</div>
                                                </a>
                                            </div>
                                        </div>
                                    
                                    )
                            }):""
                        }
                            
                           
                            <div class="clr"></div>
                        </div>
                    </div>
                </div>
        
                 )
    }
}

export default ViewMorePage;
