import React, { useState, useRef, useEffect } from "react";

import axios from 'axios';
import { myConfig } from '../config';

function PrivacyPage(props) {
    const [faqs , setFaqs] = useState([]);
 
  return (
    <>
    <div className="faqHead">
       <div className="container">
           <div className="row">
               <div className="col">
                   <div className="heading">Privacy Policy</div>
               </div>
           </div>
       </div>
   </div>
   <div className="faqdata">
       <div className="container">
           <div className="row">
               <div className="col">
                   <div className="heading">Applicability</div>
                 
                        <div className="accordian">
                            <div className="accordian-title">
                            What we collect
                            </div>
                            <div className="accordian-content">
                                <p>
                                You acknowledge that we collect and process-
                                <br />
                                    • "personal information"(i.e. information that could be used to contact you, such as full name, postal address, phone number or e–mail address);<br />
                                    • "financial information" (i.e. credit card numbers, bank account information or passwords); and<br />
                                    • "demographic and usage information" (i.e. information that you submit, or that we collect, that is neither personal information nor financial information but necessary for the proper functioning and billing of our service, such as date regarding the start and end and the extent of your usage of the service) subject to the rest of this paragraph in order to operate this site.<br />
                                Once you choose to register with us and provide us with personal information, it will be used for the purposes of providing you with the requested service and in conformity with the provisions of the Electronic Communications and Transactions Act 25/2002. We respect your privacy and undertake not to sell, rent or lease your personal information to others.
                                </p>
                            </div>
                        </div>     
                        <div className="accordian">
                            <div className="accordian-title">
                            Collection of non-personal information and data
                            </div>
                            <div className="accordian-content">
                                <p>
                                Certain non-personal information may be collected while you are using our website. This will comprise of data about usage and service operation that is not associated with a specific personal identity. We may collect and use this non-personal information to evaluate how website is being used. Most non-personal data may be collected via cookies or other analysis technologies. The website pages normally use cookies for data analysis and potential personalization of the website and services offered. <br></br>
                                Cookies are alphanumeric identifiers that we transfer to your computer's hard drive through your Web browser to enable our systems to recognize your browser and to provide features that recognize your account as logged on and other website features. Further, to help us tailor the website to the needs and interests of our users, or for other purposes, we may gather aggregate, permanently non-personally identifiable information via cookies. Examples include the type of Internet browser our users are using, the frequency of visits to our site and which pages our users viewed. We may share these aggregate statistics with others. The use of cookies is standard on the Internet and many major web sites use them.<br></br>
                                Technologies may vary, but in many cases may involve a small file stored locally on the computer used to access the website, and may be used to store and collect information relating to your viewing and use of the website. Information collected through cookies is used to create a profile of our users. A profile is stored information we keep on individual users that details their preferences, their use and request history and behavioural activity, and can minimise the need for you to re-enter information. <br></br>
                                Most cookies expire after a defined period of time, or you can delete your cookie file at any time. Most web browser software is initially set up to accept cookies. You can reset your web browser software to refuse cookies or to indicate when a cookie is being sent. However, some parts of our website will not function properly or may be considerably slower if you refuse cookies. For example, without cookies, you will not be able to set personalized preferences, and/or may have difficulty completing transactions.<br/>
                                We may collect your computer's IP (Internet Protocol) address and domain name address, the identity of your Internet service or access provider, the type of web browsing software you use, your computers operating system, the URL of the web page from which you came to visit our website and the language you selected for your web browsing software (collectively referred to herein as clickstream data). We use these technologies to identify our users, to help diagnose problems with our server, and to administer the website and the services offered thereon.
                                </p>
                            </div>
                        </div>   
                        <div className="accordian">
                            <div className="accordian-title">
                            Why do we collect your personal information?
                            </div>
                            <div className="accordian-content">
                                <p>
                                We need and use your personal information to provide you with the services you request and to help us better understand your needs and interests in the future. Sensitive personal information if collected will only be used if you choose to allow us to do this in terms of your personal preferences options and will be shared only with your express permission. 
                                </p>
                            </div>
                        </div>  
                        <div className="accordian">
                            <div className="accordian-title">
                            How and why will we share your information?
                            </div>
                            <div className="accordian-content">
                                <p>
                                Personal data given to us may be transferred across state and country borders for the purposes of data consolidation, storage and simplified customer information management. By using the Service, you consent to any such transfer of information outside of your country. We may pass on your personal information to your cellular phone service provider to secure collection of fees.<br></br>
                                We will not share your personal information with third parties except in responding to your requests for products or services. Your permission will be requested when you submit your information. We may partner with other organizations who may provide you with the services requested through the website. These partner organisations are required to keep confidential the information received on behalf of us and may not use it for any purpose other than to carry out the services they are performing for us. <br></br>
                                We will not share personal information with any other third parties without your permission, unless required by law enforcement action, subpoena, or local law. 
                                </p>
                            </div>
                        </div>       
                        <div className="accordian">
                            <div className="accordian-title">
                            How long will we store your information
                            </div>
                            <div className="accordian-content">
                                <p>
                                Your personal information, financial and demographic information will be deleted no later than six months after termination of your subscription. We may store your information beyond this date if that is required by law or contract. 
                                </p>
                            </div>
                        </div>  
                        <div className="accordian">
                            <div className="accordian-title">
                            Your privacy preferences:
                            </div>
                            <div className="accordian-content">
                                <p>
                                We will not use your personal information for a different purpose than the one stated when you provided your data. Examples of unrelated uses include sending communications about updated information, new services and marketing messages. You can, however, choose to receive these types of non-subscription communications by ticking the box when you register.
                                </p>
                            </div>
                        </div>    
                        {/* <div className="accordian">
                            <div className="accordian-title">
                            Data Access, accuracy, amendments, retention and opting out:   
                            </div>
                            <div className="accordian-content">
                                <p>
                                We will try to keep your personal information accurate. We will provide you with access to your information on request, including making reasonable efforts to provide you with online access and the opportunity to change your information, if possible. To protect your privacy and security, when doing so we will also take reasonable steps to verify your identity, such as a password and user ID, before granting access to your data. 
                                <br></br>
                                Upon request, we will:
                                <br></br>
                                <ul>
                                    <li>Correct personal information that you state is incorrect; or</li>
                                    <li>Permit you to "opt out" of further contact (excepting notification of major changes to the service or as required to provide you with the service requested), either on the original site of registration or at any time subsequently;</li>
                                    <li>Remove you and your personal information provided electronically from our database, thereby cancelling your registration.</li>
                                </ul>
                                </p>
                            </div>
                        </div>     */}
               </div>
           </div>
           <div className="row">
               <div className="col">
                   <div className="heading">Security</div>
                 
                        <div className="accordian">
                            <div className="accordian-title">
                            Amendment of this Privacy Policy
                            </div>
                            <div className="accordian-content">
                                <p>
                                We reserve the right to add to or change the terms of this Privacy Policy in our discretion, without prior notice to you. If we change this Privacy Policy, we will post the new Privacy Policy on this website, and it will become effective from the time of posting. Please visit this Privacy Policy on a regular basis to make sure you have read the latest version and you understand what we do with your information.
                                </p>
                            </div>
                        </div>     
               </div>
           </div>
       </div>
   </div>
    </>
  );
}

export default PrivacyPage;