import React, {Component} from 'react';
import axios from 'axios';
import { myConfig } from '../config';
import codeJson from '../CountryCodes.json'
import { GoogleLogin } from 'react-google-login';
import $ from 'jquery';
class Footer extends Component {
    
    constructor(props) {
        super(props);
        this.handleClick= this.handleClick.bind(this);
        this.sendotp = this.sendotp.bind(this);
        this.divRef = React.createRef();
        this.state = {
          fields: {phone:'',otp:''},
          errors: {},
          phone:'',
          countryCode: '',
          countycount: '',
          language: /virgin/,
          language2: /dubai/,
          language3: /qatar/,
          language4: /beeline/,
          canLogin : false,
          phoneError:false,
          countryCodeError:false,
          otpError:false,
          show:true,
        };
        console.log(window.sessionStorage.getItem('dialCode'))
    }
     
      

    handleClick = () =>{
    if (this.state.fields.otp != 'undefined') {
        if(this.state.fields["otp"].length==6){
            axios.get(myConfig.server_url+"validateOTP/?countryId=91&mobileNo="+this.state.fields.phone+"&OTP="+this.state.fields.otp,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
                .then(Response => {
                console.log(Response)
                //var str = Response.data.split("|");
                if (Response.data.otpStatusCode== '4000') {
                    //https://billingapi.skysportsnetwork.com/mpg/updateLogin.php?operator_id=144&msisdn=9708447625077
                    axios.post(myConfig.billing_url + "updateLogin.php?operator_id=" + 144 + "&msisdn=" + this.state.fields.phone );

                    //this.state.canLogin
                    this.setState({canLogin:true})
                    window.sessionStorage.setItem("isLogin", true);
                    $('#loginpopup').hide()
                    $('#loginwithphone').hide()
                    $('#loginwithphonesendotp').hide()
                    $('body').removeClass('fixed')
                    var lastClick = window.sessionStorage.getItem('lastClick');
                    if (lastClick) {
                        window.location.href = lastClick
                    }else {
                        window.location.reload();
                    }
                }else{
                    this.setState({otpError:true})
                }
                })  
        }else{
            this.setState({otpError:true})
        }
    }else{
        this.setState({otpError:true})
    }
        
    // this.setState({ show:false });
    }

    async componentDidMount() {
        if (this.state.language2.test(window.location.pathname) == true || this.state.language.test(window.location.pathname) == true || this.state.language3.test(window.location.pathname) == true || this.state.language4.test(window.location.pathname) == true) { 
            var str = window.location.pathname
            const dataes = codeJson.filter(i => i.link === str)
            if (dataes.length > 0) {
                this.setState({
                    countryCode:"+"+dataes[0].dial_code.slice(1)
                })
            }else {
                this.setState({
                    countryCode:"+"+window.sessionStorage.getItem("dialCode")
                })
            }
        }else {

        axios.get(myConfig.billing_url+"countryCode.php")
        .then(res => {
            const dataArray = codeJson.filter(i => i.code === res.data.geoplugin_countryCode)
            if(dataArray.lenght > 0) {
                this.setState({
                    countryCode:"+"+dataArray[0].dial_code.slice(1)
                })
            }else {
                this.setState({
                    countryCode:"+"+window.sessionStorage.getItem("dialCode")
                })
            }
        },
        )
    }   
    }

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //phone
            if (!fields["phone"] || fields["phone"].length < 11) {
                formIsValid = false;
                errors["phone"] = "Please enter valide phone number.";
            }
    }

    handleChange(field, e) {
        console.log(e.target.value);
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
        // this.setState({
        //     phone: e.target.value
        //  });
        let status = 'disabled'
        if (typeof fields["otp"] !== "undefined" || typeof fields["otp"] !== undefined) {
            if(fields["otp"].length==6){
                this.setState({canLogin:true});
            }
        }
        else{
            //otpError
            this.setState({otpError:true})
        }
    }

    handleChangeCountry(field,e) {
        console.log(e.target.value);
        // e.target.value = e.target.value.replace(/[^0-9]/g, '')
        var str = e.target.value.replace(/[^0-9]/g, '')
        this.setState({
            countryCode:'+'+str,
        })
    }

    responseGoogle = async(response) => {
        window.sessionStorage.setItem("isLogin", true);
        var countrycode = window.sessionStorage.getItem('dialCode')
        const result = await axios(
            myConfig.server_url+'checkUser/'+response.profileObj.email,
            {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
          );
            if (result.data == false) {
                var addsub = {
                    "countryCode": countrycode,
                    "emailId": response.profileObj.email,
                    "loginType": "google",
                    "name": response.profileObj.givenName,
                    "phoneNumber": 0,
                    "userId": response.profileObj.email
                }
                axios.post(myConfig.server_url+"user/",addsub,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
                .then(Response => {
                    console.log(response)
                    window.sessionStorage.setItem("userId", response.profileObj.email);
                })   
            }else {
                const result = await axios(
                    myConfig.server_url+'getSubscribedUser/'+ response.profileObj.email,
                    {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
                  );                       
                  localStorage.setItem("name",result.data.name)
                localStorage.setItem("name",response.profileObj.givenName)
                localStorage.setItem("email", response.profileObj.email)
                localStorage.setItem("prfileImage", result.data.profileImage)
                window.sessionStorage.setItem("userId", response.profileObj.email);
                var lastClick = window.sessionStorage.getItem('lastClick');
                if (lastClick) {
                    window.location.href = lastClick
                }else {
                    window.location.reload();
                }
            }
        
    }
      
    responseGoogles = (response) => {
    console.log(response);
    //alert("error",response)
    }
      
    async sendotp() {
        if (this.state.countryCode.slice(1) != '') {
            this.setState({countryCodeError:false})   
        }else {
            this.setState({countryCodeError:true})
        }

        console.log(this.state.fields.phone);
        if (this.state.fields.phone != '') {
            this.setState({phoneError:false}) 
        }else {
            this.setState({phoneError:true}) 
        }

      

        if (this.state.fields.phone != '' && this.state.countryCode.slice(1) != '') {
            const result = await axios(
                myConfig.billing_url+"checkSubscription.php?msisdn="+this.state.countryCode.slice(1)+this.state.fields.phone+"&operator_id=144",
                {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
              );  
              
             if(result.data.status == "success") {
                const result = await axios(
                    myConfig.server_url+'checkUser/'+this.state.countryCode.slice(1)+this.state.fields.phone,
                    {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
                  );
                // return  console.log(result)
        
                if (result.data == false) {
                    var str = {
                        active:true,
                        countryCode:this.state.countryCode.slice(1),
                        loginType:"mobile",
                        name:"",
                        phoneNumber:this.state.fields.phone,
                        userId:this.state.countryCode.slice(1)+this.state.fields.phone
                    }
                    const result = await axios.post(
                        myConfig.server_url+'user/',str,
                        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
                      );    

                    window.sessionStorage.setItem("userId", this.state.countryCode.slice(1)+this.state.fields.phone);
                    this.setState({canLogin:true})
                    axios.post(myConfig.billing_url + "updateLogin.php?operator_id=" + 144 + "&msisdn=" + this.state.countryCode.slice(1)+this.state.fields.phone );
                    window.sessionStorage.setItem("isLogin", true);
                    $('#loginpopup').hide()
                    $('#loginwithphone').hide()
                    $('#loginwithphonesendotp').hide()
                    $('body').removeClass('fixed')
                    var lastClick = window.sessionStorage.getItem('lastClick');
                    if (lastClick) {
                        window.location.href = lastClick
                    }else {
                        window.location.reload();
                    }

                }else {
                    const result = await axios(
                        myConfig.server_url+'getSubscribedUser/'+this.state.countryCode.slice(1)+this.state.fields.phone,
                        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
                      );                       
                      
                      window.sessionStorage.setItem("userId", this.state.countryCode.slice(1)+this.state.fields.phone);
                      localStorage.setItem("name",result.data.name)
                      localStorage.setItem("prfileImage", result.data.profileImage)
                      this.setState({canLogin:true})
                      axios.post(myConfig.billing_url + "updateLogin.php?operator_id=" + 144 + "&msisdn=" + this.state.countryCode.slice(1)+this.state.fields.phone );
                        window.sessionStorage.setItem("isLogin", true);
                        $('#loginpopup').hide()
                        $('#loginwithphone').hide()
                        $('#loginwithphonesendotp').hide()
                        $('body').removeClass('fixed')
                        var lastClick = window.sessionStorage.getItem('lastClick');
                        if (lastClick) {
                            window.location.href = lastClick
                        }else {
                            window.location.reload();
                        }
                    //   axios.get(myConfig.server_url+"generateOTP/?countryId=91&mobileNo="+this.state.fields.phone,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64')}})
                    //   .then(Response => {
                    //    //  console.log("yes")
                    //      this.setState({phoneError:false})
                    //      $('#loginpopup').hide()
                    //      $('#loginwithphone').hide()
                    //      $('#otpVerify').show()
                    //      $('body').addClass('fixed')
                    //   })  
                }
             }else{
                //  if(window.sessionStorage.getItem("sCountryCode") != null) {
                //      const dataArray = codeJson.filter(i => i.code ===  window.sessionStorage.getItem("sCountryCode"))
                //      window.location.href= dataArray[0].link
                //  }else {
                    const dataArray = codeJson.filter(i => i.code === 'AE') //AE , GE , BD, QA,PL,OSL,PLO,PK
                    window.location.href= dataArray[0].link
                //  }
             }
        }
        
    }

    
    render() {
	    return (
            <div>
                <footer>
                {/*<div className="left fl">
                    <div className="logo">
                        <a href=""><img src="images/logo.svg" alt="" /></a>
                    </div>
                    <div className="content">
                        { /*Lorem ipsum dolor sit amet, consec tetur adipis cing elit, sed do eiusmod tempor incididunt ut labore et.
                    </div>
                    <div className="letterhead">
                        Join Newsletters
                    </div>
                    <div className="formFooter">
                        <input type="text" placeholder="Insert your mail here" />
                        <button></button>
                    </div>
                </div>*/}
                <div className="right fr" id='footer'>
                    {/* <div className="menucol fl">
                        <div className="head">Product</div>
                        <ul>
                            <li><a href="/tnc">T & C</a></li>
                            <li><a href="/privacy">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div className="menucol fl">
                        <div className="head">Media Group</div>
                        <ul>
                            <li><a href="">Studio</a></li>
                            <li><a href="">News</a></li>
                            <li><a href="">TV</a></li>
                        </ul>
                    </div>
                    <div className="menucol fl">
                        <div className="head">Sitemap</div>
                        <ul>
                            <li><a href="">About</a></li>
                            <li><a href="">Careers</a></li>
                            <li><a href="">Press</a></li>
                        </ul>
                    </div> */}
                    <div className="clr"></div>
                    <div className="lines">
                        {/* <span><img src="/asset/images/LocationMapMarker4.svg" alt="" />104, Ruislip Road, Greenford ,London. UK - UB6 9QH</span> */}
                        {/* <a href="mailto:info@skysportsnetwork.com" style={{textDecoration:"none",color:"white"}}>
                            <span><img src="/asset/images/Mail.svg" alt="" /> info@skysportsnetwork.com</span></a> */}
                       {/* <a href="tel:911-234-344-364" style={{textDecoration:"none",color:"white"}}><span><img src="/asset/images/Phone.svg" alt="" />+44(0) 2035002233</span></a>*/}
                          <a href="/tnc" style={{textDecoration:"none",color:"white"}}><span>T & C</span></a>
                          <a href="/privacy"style={{textDecoration:"none",color:"white"}}><span>Privacy Policy</span></a>
                    </div>
                </div>
                <div className="clr"></div>
            </footer>
            <div className="overlay"  id="loginpopup">
            <div className="loginscreen">
                <div className="loginHead">Login <img src="/asset/images/Cancel.svg" alt="" style={{marginLeft:"87%"}} className="closeLogin back openPhoneLogin" /></div>
                <div className="base">
                    <a href="javascript:void(0)" className="linksicons openPhoneLogin">Login with Mobile</a>
                    {/* <a href="" className="linksicons fb">Sign In with Facebook</a>
                    <GoogleLogin
                        clientId="116117069980-tpc4imv769nhlp1pvkp06fv4cgnv2jdn.apps.googleusercontent.com"
                        buttonText="Sign in with Google"
                        className="linksicons gp"
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className="linksicons gp" >Sign in with Google</button>
                          )}
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogles}
                        cookiePolicy={'single_host_origin'}
                    />, */}
                </div>
            </div>
        </div>
        
        <div className="overlay"  id="loginwithphone">
            <div className="loginscreen">
                <div className="loginHead">
                    {/* <img src="/asset/images/Back_Arrow1.svg" alt="" className="back openLogin" /> */} Login with Mobile <img src="/asset/images/Cancel.svg" alt="" className="closeLogin"  /></div>
                <div className="base">
                    <div>
                        <input type="text" id="countryid" value={this.state.countryCode != '' ? this.state.countryCode  : window.sessionStorage.getItem('dialCode')} style={{width:"18%",float:"left",marginRight:"5px"}} onChange={this.handleChangeCountry.bind(this, "countrycode")}/>
                        <input type="text" placeholder="Enter Mobile Number" style={{width:"78%",float:"right"}} id="phoneNo" onChange={this.handleChange.bind(this, "phone")}/>
                        <label className='text-danger' style={{color:"red"}}>{ this.state.phoneError ? "Please enter phone number" : ""}</label><br></br>
                        <label className='text-danger' style={{color:"red"}}>{ this.state.countryCodeError ? "Please enter country code" : ""}</label>
                    </div>
                    <div>
                        {/* <a href="javascript:void(0)" className="smallBtn fl">Resend</a> */}
                        <a href="javascript:void(0)" className="smallBtn sendOTP fr" id="sendOTP" onClick={this.sendotp} style={{marginTop:"10px"}}>Login</a>
                        <div className="clr"></div>
                    </div>
                    <div>
                    
                    </div>
                    {/* <div>
                        <input type="text" placeholder="Enter OTP" className="otp" pattern="\d*" maxlength="6" minLength="6" onChange={this.handleChange.bind(this, "otp")}/>
                    </div>
                    <div>
                        <button id="loginButton" className={ this.state.canLogin ? "enabled" : "disabled"}  onClick={this.handleClick} > Login </button>
                    </div> */}
                </div>
            </div>
        </div>

        <div className="overlay"  id="otpVerify">
            <div className="loginscreen">
                <div className="loginHead">
                    {/*<img src="/asset/images/Back_Arrow1.svg" alt="" className="back openPhoneLogin" />*/} OTP Verify <img src="/asset/images/Cancel.svg" alt="" className="closeLogin" /></div>
                    <div className="base">
                        <div>
                            <input type="text" placeholder="Enter OTP" className="otp " pattern="\d*" maxlength="6" minLength="6" onChange={this.handleChange.bind(this, "otp")}/>
                        </div>
                        <div>
                            <button id="loginButton" className={ this.state.canLogin ? "enabled" : "disabled"}  onClick={this.handleClick} > Login </button>
                        </div>
                        <div>
                    
                            <p className='text-danger'>{ this.state.otpError ? "Invalid OTP" : ""}</p>
                            </div>
                    </div>
            </div>
        </div>
        
        </div>
        )
    
    }
}

export default Footer;