import React,{ useEffect, useState }  from 'react';

import Header from './Pages/Header';
import SportsNewsPage from './Pages/SportsNewsPage';
import Footer from './Pages/Footer';
import axios from 'axios';
import { myConfig } from './config';

function SportsNews(props) {
    //const [contentData , setContentData] = useState([]);
 /*     useEffect(() => {
        const fetchData = async () => {
          const result = await axios(
            myConfig.server_url+'contentDetailBySubcategoryNCategory/?categoryId='+props.match.params.cId+'&subcategoryId='+props.match.params.scId+'&contentId='+props.match.params.id+'&contrycode=91',
          );
          //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
          setRelatedData(result.data.list);
        };
        fetchData();

      }, []);
*/

    return (
        [
            <Header />,
            <SportsNewsPage />,
            <Footer />
        ]
    );
}

export default SportsNews;