import React,{ useEffect, useState }  from 'react';

import Header from './Pages/Header';
import PlayerHeader from './Pages/PlayerHeader';
import RelatedVideo from './Pages/RelatedVideo';
import Highlights from './Pages/Highlights';
import Footer from './Pages/Footer';
import axios from 'axios';
import { myConfig } from './config';

function DetailPlayer(props) {
    const [contentData , setContentData] = useState([]);
    const [relatedData , setRelatedData] = useState([]);
    const [categories , setCategories] = useState([]);
    const [filters , setFilters] = useState([]);
    const [keyWord , setKeyWord] = useState('best');
      useEffect(() => {
        const fetchData = async () => {
          var urls = myConfig.server_url+'contentDetailBySubcategoryNCategory/?categoryId='+props.match.params.cId+'&subcategoryId='+props.match.params.scId+'&contentId='+props.match.params.id+'&contrycode=91'
          const result = await axios(
            urls,
            {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64')}}
          );
          //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
          setRelatedData(result.data.list);
          appLog(urls,result)
        };
        fetchData();

      }, []);
      useEffect(() => {
        const fetchData = async () => {
          var urlss = myConfig.server_url+'contentDetailByContentId/?contentId='+props.match.params.id
          const result = await axios(
            urlss,
            {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
          );
          //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
          setContentData(result.data.list[0]);
          appLog(urlss,result)
        };
        fetchData();
      }, []);
      useEffect(() => {
        var checkhomepagedata = JSON.parse(localStorage.getItem("listHomePage"))
        if(checkhomepagedata != null) {        
          console.log(checkhomepagedata)
          setCategories(checkhomepagedata.homepage[0].categories);
        }else {
        const fetchData = async () => {
          var urlses = myConfig.server_url+"listHomePage/"
          const result = await axios(
            urlses
            ,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
          );
          //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
          setCategories(result.data.homepage[0].categories);
          appLog(urlses,result)
          localStorage.setItem("listHomePage", JSON.stringify(result.data));

        };
        fetchData();
      }
      }, []);

      const series = categories.map(cat=>({
        ...cat,
        subcategories:cat.subcategories.map(sCat=>({
            ...sCat,
            contentDetails:sCat.contentDetails
        })).filter(sCat=>sCat.subcategoryId!=props.match.params.scId)
    })).filter(cat=>cat.categoryId==props.match.params.cId)

           /* const filt = categories.map(cat=>({
                ...cat,
                subcategories:cat.subcategories.map(sCat=>({
                    ...sCat,
                    contentDetails:sCat.contentDetails.filter(content=>content.contentDtl.contentTitle.toLowerCase().includes(keyWord))
                })).filter(sCat=>sCat.contentDetails.length>0)
            })).filter(cat=>cat.subcategories.length>0)*/

      function appLog(url, res) {
        var browser = (function() {
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
            })();
            if(window.sessionStorage.getItem('userId') != undefined) {
                var gettime = new Date();
                var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
                var finalobj6 = {
                security : "col#c4",
                logData : createanotherurl
                }
                axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
            }
        }
    return (
        [
            <Header />,
            <PlayerHeader content = {[contentData,series]} />,
            <RelatedVideo  relatedData = {[relatedData,props.match.params.cId,props.match.params.scId]} />,
            <Highlights />,
            <Footer />
        ]
    );
}

export default DetailPlayer;