import React, {Component} from 'react';

class FeaturedChannel extends Component {
    render() {
	    return (
            <div className="featuredChannel">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="title">Featured Channel</div>
                        </div>
                    </div>
                    <div className="clr"></div>
                    <div className="row">
                        <div className="col">
                            <a href="javascript:void(0)"><img src="/asset/images/Ellipse14.png" alt="" /></a>
                            <a href="javascript:void(0)"><img src="/asset/images/Ellipse15.png" alt="" /></a>
                            <a href="javascript:void(0)"><img src="/asset/images/Ellipse17.png" alt="" /></a>
                            <a href="javascript:void(0)"><img src="/asset/images/Ellipse19.png" alt="" /></a>
                            <a href="javascript:void(0)"><img src="/asset/images/Ellipse16.png" alt="" /></a>
                            <a href="javascript:void(0)"><img src="/asset/images/Ellipse18.png" alt="" /></a>
                            <a href="javascript:void(0)"><img src="/asset/images/Ellipse13.png" alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FeaturedChannel;
