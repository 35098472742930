import React, { useState, useRef, useEffect } from "react";

import axios from 'axios';
import { myConfig } from '../config';

function TncPage(props) {
    const [faqs , setFaqs] = useState([]);
    useEffect(() => {
        localStorage.setItem('activetab',"tnc")
    },[])
 
  return (
    <>
    <div className="faqHead">
       <div className="container">
           <div className="row">
               <div className="col">
               {localStorage.getItem('lang') == 'ploen' || localStorage.getItem('lang') == 'plppen' || localStorage.getItem('lang') == 'plpen' || localStorage.getItem('lang') == 'plten' ?
               <div className="heading">REGULAMIN SERWISU</div>:<div className="heading">Terms & Conditions</div>
                }   
               </div>
           </div>
       </div>
   </div>
   <div className="faqdata">
       <div className="container">
           <div className="row">
               <div className="col">
                  
                        {localStorage.getItem('lang') == 'ploen' || localStorage.getItem('lang') == 'plppen' || localStorage.getItem('lang') == 'plpen' || localStorage.getItem('lang') == 'plten' ?
                            <>
                             <div className="heading">REGULAMIN SERWISU</div>
                            <div className="accordian">
                            <div className="accordian-title">
                            I.	REZYGNACJA Z USŁUGI
                            </div>
                            <div className="accordian-content">
                                <p>
                                1.	Wyślij email na adres info-pl@mobileinfo.biz
                                <br></br>
                                <br></br>
                                2.	Zadzwoń na Infolinię 800 007 173 (opłata lokalna)
                                <br></br>
                                <br></br>
                                3.	Wyślij SMS o treści STOP SKY na numer:
                                <br></br>
                                <br></br>
                                a.	Dla operatora Orange: 80708 (0 zł)
                                lub
                                <br></br>
                                <br></br>
                                b.	Dla operatorów T-Mobile, Plus: 80425 (0 zł)
                                lub
                                <br></br>
                                <br></br>
                                c.	Dla operatora Play: 191 (0 zł)

                                </p>
                            </div>
                            </div> 
                            <div className="accordian">
                            <div className="accordian-title">
                            II.	POSTANOWIENIA OGÓLNE
                            </div>
                            <div className="accordian-content">
                                <p>
                                1.	Niniejszy regulamin (zwany dalej „Regulaminem”) określa ogólne zasady korzystania z płatnych usług serwisu multimedialnego Sky Sports Network, będącego serwisem, stanowiącym świadczenie dodatkowe (do usługi telekomunikacyjnej), w ramach usługi o podwyższonej opłacie, przeznaczonego na telefony komórkowe (zwany dalej „Usługi”). Serwis Sky Sports Network umożliwia podaj opis serwisu (zwany dalej „Serwis”).
                                <br></br>
                                2.	Usługodawcą (zwany dalej „Organizatorem”) i podmiotem realizującym dodatkowe świadczenie jest: IBU Digital Ltd, 104 Ruislip Road, Greenford London United Kingdom, zarejestrowany w Rejestrze Handlowym w London United Kingdom pod numerem 13614800, posiadająca NIP .390616881, WWW: www.ibu-digital.co.uk, adres e-mail: rajiv@ibu-digital.co.uk, nr telefonu: +44 7765266192, nr fax.: +44 7765266192.
                                <br></br>
                                3.	Z Usług oraz z określonego w niniejszym Regulaminie Serwisu mogą korzystać użytkownicy telefonów komórkowych bądź innych urządzeń o odpowiednich funkcjonalnościach, wynikających z Regulaminu bądź zamieszczonych na stronie www lub innych materiałach dotyczących Serwisu. Użytkownik powinien być uprawniony do korzystania z telefonu komórkowego, w zakresie umożliwiającym mu korzystanie z Serwisu. Z Serwisu mogą korzystać Użytkownicy posiadający aktywną kartę SIM w sieci Orange, Plus, T-Mobile & Play, których telefony komórkowe lub inne urządzenia są, kompatibilne z Wszystko iOS & version (e.g. Android x, iOS 11.1).
                                <br></br>
                                4.	Użytkownicy zobowiązani są do korzystania z Serwisów w granicach obowiązującego w Polsce prawa, zgodnie z postanowieniami niniejszego Regulaminu. 
                                <br></br>
                                5.	Każdy Użytkownik, który przystępuje do korzystania z Serwisu Sky Sports Network powinien zapoznać się z treścią Regulaminu, który ustanawia aktualne zasady i warunki korzystania z Serwisu. 
                                <br></br>
                                6.	Określone w Regulaminie zasady i warunki korzystania z Serwisu Sky Sports Network i świadczonych Usług, dotyczą wyłącznie użytku osobistego, niemającego charakteru komercyjnego (np. działalności gospodarczej). Użytkownik powinien korzystać z Serwisu zgodnie z przepisami obowiązującego prawa. 
                                <br></br>
                                7.	Treści udostępniane Użytkownikowi w ramach Serwisu i świadczonych Usług zawierają chronione prawem, w tym prawem autorskim oraz prawem własności przemysłowej materiały, w szczególności: znaki towarowe i utwory. Także sam układ i wybór prezentowanych w Serwisie treści może stanowić samoistny przedmiot ochrony na gruncie prawa polskiego, w tym w szczególności na gruncie ustawy z dnia 4 lutego 1994 roku o prawie autorskim i prawach pokrewnych (Dz. U. z 2006 roku, Nr 90, poz. 631 ze zm.), ustawy z dnia 21 lipca 2001 roku o ochronie baz danych (Dz. U. Nr 128, poz. 1402 ze zm.) oraz ustawy z dnia 30 czerwca 2000 roku Prawo własności przemysłowej (Dz. U. z 2003 roku, Nr 119, poz. 1117 ze zm.).
                                <br></br>
                                8.	Użytkownik jest uprawniony do wykorzystywania udostępnianych w ramach Serwisu treści, w szczególności Obiektów Multimedialnych wyłącznie w celach i zakresie określonym w ust. 6 powyżej oraz art. VI ust. 2 niniejszego Regulaminu. W szczególności utwory (Obiekty Multimedialne) nie mogą być kopiowane, publikowane, wyświetlane, przesyłane lub rozpowszechniane w jakikolwiek sposób bez uzyskania odrębnej pisemnej zgody Operatora, za wyjątkiem przypadków dozwolonego użytku przewidzianego prawem.
                                <br></br>
                                9.	Ilekroć w Regulaminie użyte są niżej wymienione poniżej sformułowania, należy je rozumieć w następujący sposób:
                                <ol style={{paddingLeft:"40px"}}>
                                <li>SMS/wiadomość SMS (Short Message Service) jest to krótka wiadomość tekstowa, składająca się z nie więcej niż 160 znaków (litery, cyfry, znaki szczególne), którą można przesłać na dowolny telefon komórkowy, zawierająca treść wpisaną przez nadawcę oraz umożliwiająca identyfikację numeru telefonu komórkowego jej nadawcy.</li>
                                <li>Rejestracja – zamówienie (aktywacja) Usługi, poprzedzona uzyskaniem przez Użytkownika informacji o Serwisie i wyrażeniem odpowiednich zgód, w trybie opisanym w art. III poniżej, przy czym aktywacja Serwisu następuje  poprzez Stronę Aktywacyjną na którą Użytkownik zostanie przekierowany. Aby dokonać zamówienia, Użytkownik musi zapoznać się z treścią przedstawioną na Stronie Aktywacyjnej i podążać za wskazanymi na niej instrukcjami. W przypadku Serwisu o charakterze cyklicznym (subskrypcji) Użytkownik przechodzi przez proces zamówienia treści tylko za pierwszym razem przy zamawianiu Serwisu. Dostęp do Serwisu będzie przedłużany automatycznie w kolejnych cyklach do czasu dezaktywacji. Rejestracja do Serwisu jest jednoznaczna z akceptacją Regulaminu i Polityki Prywatności. Po rejestracji do Serwisu, Użytkownik otrzyma bezpłatną wiadomość powitalną potwierdzającą, że rejestracja do Serwisu zakończyła się powodzeniem.</li>
                                <li>Serwis Sky Sports Network jest świadczony jako: Usługa subskrypcji cyklicznej.
                                    Serwis polega na unlimited dostępie do portalu z digital content for sports fans pod adresem https://m.skysportsnetwork.com/ , określonych w art. II ust. 1 Regulaminu.
                                    </li>
                                    <li>
                                    Obiekty Multimedialne - oznacza wszelkiego rodzaju treści cyfrowe o charakterze multimedialno/rozrywkowym, nie dostarczone na materialnym nośniku, w tym cyfrowe produkty, które mogą być dostępne w ramach Serwisów, w tym: tapety, grafiki, muzyka/Mp3, video itp. Obiekty Multimedialne zawierają chronione prawem, w tym prawem autorskim oraz prawem własności przemysłowej materiały, w szczególności: znaki towarowe i utwory. Postanowienia dotyczące Obiektów Multimedialnych stosuje się odpowiednio do innych treści, jeśli są dostępne w ramach Serwisu zgodnie z art. II ust. 1 Regulaminu, chyba ze z Regulaminu bądź ich charakterystyki wynika inaczej. Pobranie Obiektu Multimedialnego i dostęp do Serwisu jest możliwy type description of how content can be uploaded/accessed (e.g. directly from the web page confirming the activation of the service).
                                    </li>
                                <li>Użytkownik – pełnoletnia osoba fizyczna, korzystająca z Serwisu na podstawie niniejszego Regulaminu, przy czym osoby o ograniczonej zdolności do czynności prawnych mogą korzystać z Serwisu pod warunkiem wyrażenia zgody przez przedstawiciela ustawowego, a osoby nieposiadające zdolności do czynności prawnych, pod warunkiem działania w ich imieniu przedstawiciela ustawowego. </li>
                                <li>WAP (Wireless Application Protocol – Protokół Komunikacji Bezprzewodowej) – oznacza międzynarodowy standard dostarczania komunikatów internetowych oraz świadczenia zaawansowanych usług telefonicznych poprzez telefony komórkowe oraz inne terminale cyfrowe.</li>
                                <li>Operator – oznacza przedsiębiorcę telekomunikacyjnego w rozumieniu prawa telekomunikacyjnego, który wykonuje działalność gospodarczą polegającą na dostarczaniu publicznych sieci telekomunikacyjnych, udogodnień towarzyszących lub świadczeniu usług telekomunikacyjnych, dla którego abonentów dostępny jest Serwis.</li>
                                </ol>
                                <br></br>
                                10.	Udostępniane Użytkownikowi w ramach Serwisów Obiekty Multimedialne, stanowią treści cyfrowe nie zapisane/dostarczane na materialnym nośniku. 
                                </p>
                            </div>
                            </div>
                            <div className="accordian">
                            <div className="accordian-title">
                            I.	REJESTRACJA i DOSTĘP DO SERWISU
                            </div>
                            <div className="accordian-content">
                                <p>
                                1.	W celu skorzystania z Serwisu Użytkownik powinien dokonać odpowiedniego zamówienia / rejestracji na Stronie WWW lub WAP (zwanej dalej „Strona Aktywacyjna”) na którą Użytkownik zostanie przekierowany. Użytkownik musi zapoznać się z treścią przedstawioną na Stronie Aktywacyjnej i podążać za instrukcjami na niej wskazanymi. Rejestracja do Serwisu jest jednoznaczna z akceptacją Regulaminu i Polityki Prywatności.
                                <br></br>
                                <br></br>
                                Bezpośrednio po dokonaniu zamówienia Użytkownikowi jest prezentowana strona z potwierdzeniem zamówienia i z możliwością przejścia bezpośrednio do portalu.
                                <br></br><br></br>
                                Dodatkowo, w przypadku serwisu subskrypcyjnego/dostępu cyklicznego bezpośrednio po rejestracji do serwisu, Użytkownik otrzyma wiadomość powitalną potwierdzającą, że rejestracja do serwisu dostępu zakończyła się powodzeniem, warunkami Serwisu, ceną oraz instrukcją jak dokonać dezaktywacji Serwisu.
                                <br></br>
                                <br></br>
                                2.	W ramach Serwisu Użytkownik będzie otrzymywać unlimited do portalu z digital content for sports fanspod adresem https://m.skysportsnetwork.com, określonych w art. II ust. 1 Regulaminu.
                                <br></br>
                                <br></br>
                                USUŃ/ZMIEŃ WŁAŚCIWE
                                <br></br>
                                <br></br>
                                W przypadku aktywacji serwisu z jednorazowym dostępem, dostęp jest udzielany na 1 week Koszt dostępu do serwisu jednorazowego to 19 zł brutto.
                                <br></br>
                                <br></br>
                                Serwis cykliczny jest weekly serwisem subskrypcyjnym przedłużająca się automatycznie aż do momentu rezygnacji z Serwisu. Koszt dostępu do Serwisu subskrypcyjnego wynosi 19 zł brutto na week (pobierane będą 4 opłaty po 19 zł brutto na week. Opłata będzie naliczana w 1 week odstępach od momentu aktywacji Serwisu.
                                <br></br>
                                <br></br>
                                Opłata zostanie doliczona do rachunku Użytkownika u jego Operatora (telefon na abonament) lub odliczona od środków na koncie (telefon na kartę).
                                <br></br><br></br>
                                Połączenie Internetowe jest konieczne dla korzystania z otrzymanych w ramach subskrypcji treści multimedialnych. Do opłaty za dostęp do Serwisu, nie są wliczone opłaty za transmisję danych. W przypadku gdy korzystanie z Serwisu wymaga pobierania przez Użytkownika danych z wykorzystaniem transmisji GSM, opłaty te będą pobierane przez Operatora, zgodnie z aktualnym cennikiem zakontraktowanego Operatora. 
                                <br></br>
                                <br></br>
                                3.	W ramach Rejestracji, przed dokonaniem aktywacji Serwisu w sposób opisany w ust. 1 powyżej,  Organizator udostępni Użytkownikowi na Stronie WWW lub WAP następujące informacje oraz możliwości:
                                <ol>
                                a.	Organizator poda Użytkownikowi co najmniej informacje dotyczące:
                                    <li>
                                    i.	głównych cech (przedmiotu) Serwisu i sposobu porozumiewania się z Użytkownikiem
                                    </li>
                                    <li>
                                    ii.	oznaczenia Organizatora jako przedsiębiorcy
                                    </li>
                                    <li>
                                    iii.wysokości opłat za dostep jednorazowy i/lub serwis subskrypcyjny  
                                    </li>
                                    <li>
                                    iv.	czasu trwania umowy oraz możliwości rezygnacji z Serwisu w każdym czasie oraz bez dodatkowych opłat i sposobu jego dezaktywacji; 
                                    </li>
                                </ol>
                                <ol>
                                a.	Na Stronie Aktywacyjnej, Użytkownik będzie poproszony o potwierdzenie, iż Użytkownik:
                                    <li>
                                    i.	zdaje sobie sprawę, że zamówienie Serwisu pociąga za sobą obowiązek zapłaty poprzez rachunek telefonu komórkowego Użytkownika
                                    </li>
                                    <li>
                                    ii.	zapoznał się z treścią niniejszego Regulaminu organizatora i go wyraźnie akceptuje
                                    </li>
                                    <li>
                                    iii.	zapoznał się z treścią regulaminu operatora sieci komórkowej i go wyraźnie akceptuje
                                    </li>
                                    <li>
                                    iv.	wyraża zgodę  za natychmiastowe rozpoczęcie / dostarczenie świadczenia przed upływem terminu 14 dni do odstąpienia od umowy, w wyniku czego traci prawo do odstąpienia od umowy, które by mu w przeciwnym razie przysługiwało (Punkt V);
                                    </li>
                                </ol>
                                <br></br><br></br>
                                4.	Po wyraźnym potwierdzeniu przez Użytkownika oświadczeń wskazanych w ust. 3 pkt. b) powyżej oraz po dokonaniu aktywacji Serwisu w sposób opisany w ust. 1 powyżej, 
                                <ul>
                                -	w przypadku serwisu jednorazowego/dostępu jednorazowego Użytkownik otrzyma dodatkowo bezpośrednio po rejestracji do Serwisu, bezpłatne potwierdzenie opłaty potwierdzające że, rejestracja do Serwisu dostępu zakończyła się powodzeniem, ceną za week oraz link do niniejszego Regulaminu, stanowiący potwierdzenie zawarcia umowy o Serwis i zgody na dostarczenie treści cyfrowych w okolicznościach powodujących utratę prawa do odstąpienia od umowy. Użytkownik otrzyma również dostęp do strony WEB/WAP, skąd Użytkownik będzie mógł pobrać oferowane Obiekty Multimedialne w ramach serwisu jednorazowego.
                                </ul>
                                <ul>-	w przypadku serwisu subskrypcyjnego/dostępu cyklicznego Użytkownik otrzyma dodatkowo bezpośrednio po rejestracji do Serwisu, bezpłatną wiadomość powitalną potwierdzającą, że rejestracja do serwisu dostępu zakończyła się powodzeniem, ceną za week oraz instrukcją jak dokonać dezaktywacji Serwisu oraz link do niniejszego Regulaminu, stanowiący potwierdzenie zawarcia umowy dotyczącej Serwisu i zgody na dostarczenie treści cyfrowych w okolicznościach powodujących utratę prawa do odstąpienia od umowy. Użytkownik otrzyma również pierwszy płatny dostęp do strony WEB/WAP, skąd Użytkownik będzie mógł pobrać oferowane Obiekty Multimedialne w ramach subskrypcji.</ul>
                                <br></br>
                                <br></br>
                                5.	W przypadku, jeśli skorzystanie z danego Serwisu będzie wymagało sprzętu (w tym telefonu) o szczególnych funkcjonalnościach, jeśli Obiekty Multimedialne nie będą interoperacyjne z niektórymi typami telefonów i w innych tego typu sytuacjach dotyczących funkcjonalności treści cyfrowych bądź ich interoperacyjności z telefonem bądź innym sprzętem lub oprogramowaniem, Organizator przekaże Użytkownikowi jasne informacje w tym zakresie na Stronie WWW lub WAP.
                                <br></br>
                                <br></br>
                                6.	Dezaktywacji Serwisu subskrypcyjnego można dokonać w każdym czasie wysyłając SMS o treści STOP SKY na numer specjalny wskazany na Stronie WWW lub WAP oraz w wiadomości SMS wysłanej do Użytkownika po aktywacji Serwisu.
                                </p>
                            </div>
                            </div>
                            <div className="accordian">
                            <div className="accordian-title">
                            IV.	OPŁATY W SERWISIE Sky Sports Network
                            </div>
                            <div className="accordian-content">
                                <p>
                                Użytkownik obciążany jest opłatą za otrzymanie dostępu w ramach aktywowanego przez niego Serwisu zgodnie z wymienioną powyżej częstotliwością. 
                                <br></br>
                                <ol style={{paddingLeft:"40px"}}>
                                <li>Wiadomość SMS dezaktywująca Serwis może być wysłana wyłącznie z numeru MSISDN Użytkownika, z którego został aktywowany Serwis bądź który został podany na Stronie WWW lub WAP w ramach rejestracji. </li>
                                <li>Opłaty są pobierane przez Operatorów. Sposoby i terminy zapłaty zależą od treści umowy o świadczenie usług telekomunikacyjnych pomiędzy Użytkownikiem i Operatorem i są regulowane przepisami takiej umowy.</li>
                                <li>Opłata za Serwis nie obejmuje opłat za połączenia WAP, w przypadku, kiedy korzystanie z Serwisu wymaga ściągania przez Użytkownika danych z wykorzystaniem transmisji WAP. Opłaty za połączenia WAP będą pobierane przez Operatora oprócz kwot opłat za Serwis, zgodnie z aktualnym cennikiem danego Operatora.
                                    Serwis polega na unlimited dostępie do portalu z digital content for sports fans pod adresem https://m.skysportsnetwork.com/ , określonych w art. II ust. 1 Regulaminu.</li>
                                </ol>
                                </p>
                            </div>
                            </div>
                            <div className="accordian">
                            <div className="accordian-title">
                            V.	PRAWO DO ODSTĄPIENIA OD UMOWY
                            </div>
                            <div className="accordian-content">
                                <p>
                                1.	Użytkownik ma prawo do odstąpienia od umowy o udostępnianie Serwisu w terminie 14 dni bez podawania przyczyny. Termin ten jest liczony od dnia zawarcia umowy.
                                <br></br>
                                <br></br>
                                2.	Jednakże, Użytkownik traci przysługujące mu prawo odstąpienia od umowy w przypadku gdy:
                                <br></br>
                                <ol style={{paddingLeft:"40px"}}>
                                <li>Organizator nie dostarczył potwierdzenia zawarcia umowy o udostępnianie Serwisu na trwałym nośniku</li>
                                <li>Użytkownik żąda natychmiastowego rozpoczęcia / dostarczenia świadczenia Serwisu treści cyfrowych przed upływem terminu 14 dni do odstąpienia od umowy i Organizator informuje Użytkownika o tym, że w tym przypadku Użytkownik traci prawo do odstąpienia od umowy.</li>
                                </ol>
                                </p>
                            </div>
                            </div>  
                            <div className="accordian">
                            <div className="accordian-title">
                            I.	POZOSTAŁE ZASADY DOTYCZĄCE KORZYSTANIA Z SERWISU Sky Sports Network ORAZ USŁUG
                            </div>
                            <div className="accordian-content">
                                <p>
                                1.	W celu skorzystania przez Użytkownika z Serwisu i pobrania Obiektu Multimedialnego (bądź skorzystania z innej treści dostępnej w ramach Serwisu) Użytkownik powinien: 
                                <br></br>
                                <ol style={{paddingLeft:"40px"}}>
                                <li>zapewnić, by aparat telefoniczny, na którym Obiekt Multimedialny ma być odebrany, ściągnięty czy odtwarzany przez Użytkownika, był prawidłowo skonfigurowany (w szczególności miał włączoną możliwość transmisji danych i odbierania wiadomości WAP Push);</li>
                                <li>upewnić się przed pobraniem danego Obiektu Multimedialnego, co do kompatybilności (interoperacyjności) Obiektu Multimedialnego z modelem telefonu, na który Obiekt ten ma zostać odebrany, ściągnięty czy odtwarzany, przy czym jeśli Serwis będzie miał w tym zakresie ograniczenia, zostaną one wskazane Użytkownikowi w wyraźny sposób przed zamówieniem Serwisu;</li>
                                <li>zapewnić, by telefon Użytkownika posiadał wystarczająco dużo wolnej pamięci niezbędnej do pobrania, zainstalowania, odtwarzania wybranego przez Użytkownika Obiektu Multimedialnego.</li>
                                </ol>
                                <br></br>
                                <br></br>
                                2.	Użytkownik uzyskujący Obiekt Multimedialny w ramach Serwisu ma prawo do korzystania z Obiektu Multimedialnego  w zakresie pobrania Obiektu Multimedialnego udostępnianego jako plik mobilny do telefonu komórkowego lub innego urządzenia końcowego, o którym mowa w art. II ust. 3 Regulaminu i jego zapisu w pamięci urządzenia w celu wielokrotnego odtwarzania przez czas nieoznaczony albo oznaczony, w zależności od wybranego Obiektu (przy czym w przypadku ograniczeń czasowych lub innych niż określonych wprost w Regulaminie są one określone w opisie danego Obiektu Multimedialnego oraz w informacji przekazywanej Użytkownikowi zgodnie z art. III ust. 5 pkt. 1) Regulaminu). Użytkownik uzyskuje uprawnienie do korzystania z Obiektów Multimedialnych wyłącznie na użytek własny (tj. na użytek osoby, na której rzecz świadczony jest dany Serwis). Powyższe oznacza w szczególności, że niedopuszczalne jest wykorzystywanie Obiektów Multimedialnych w celach komercyjnych, w tym niedopuszczalne jest wykorzystywanie ich na potrzeby prowadzonej działalności gospodarczej, odsprzedaż, ani inne formy udostępniania osobom trzecim, w zakresie przekraczającym dozwolony użytek określony przepisami Ustawy o prawie autorskim i prawach pokrewnych.
                                </p>
                            </div>
                            </div>
                            <div className="accordian">
                            <div className="accordian-title">
                            VII.	JAKOŚĆ SERWISU I REKLAMACJE
                            </div>
                            <div className="accordian-content">
                                <p>
                                1.	Organizator zapewnia, iż Serwis, w tym Obiekty Multimedialne, będą udostępnione Użytkownikowi w sposób należyty i wolny od wad.
                                <br></br>
                                <br></br>
                                2.	Organizator ponosi odpowiedzialność za należytą realizację Serwisu na zasadach wynikających z kodeksu cywilnego. 
                                <br></br>
                                <br></br>
                                3.	Reklamacje rozpatrywane są przez Organizatora niezwłocznie, jednak nie później niż w terminie do 14 dni kalendarzowych od daty otrzymania. Reklamacje powinny być składane pocztą elektroniczną na adres e-mail: info-pl@mobileinfo.biz lub pisemnie na adres siedziby spółki z dopiskiem „Reklamacja – Sky Sports Network”. W razie pytań infolinia dostępna jest pod numerem telefonu: 800 007 173.
                                <br></br>
                                <br></br>
                                4.	W celu usprawnienia procesu zgłaszania i rozpatrywania reklamacji, reklamacja powinna zawierać numer MSISDN, krótki opis problemu będącego podstawa złożenia reklamacji, oraz dane  Użytkownika umożliwiające kontakt  z nim (w tym jego imię, nazwisko, adres pocztowy lub adres poczty elektronicznej). 
                                <br></br>
                                <br></br>
                                5.	Organizator udzieli odpowiedzi na reklamację Użytkownika na adres poczty elektronicznej lub adres pocztowy wskazany uprzednio przez Użytkownika w reklamacji.
                                <br></br>
                                <br></br>
                                6.	Organizator nie przewiduje świadczenia usług posprzedażowych
                                <br></br>
                                <br></br>
                                7.	Organizator oświadcza, iż przestrzega Kodeksu dobrych praktyk w zakresie bezpiecznego korzystania z telefonów komórkowych, którego treść jest dostępna na stronie http://www.gsma.com/gsmaeurope/wp-content/uploads/2012/04/polandcoc.pdf
                                <br></br>
                                <br></br>
                                8.	Organizator nie przewiduje obowiązku złożenia kaucji ani udzielenia innych gwarancji finansowych w związku z zamówieniem i korzystaniem z Serwisów MT. 
                                </p>
                            </div>
                            </div>  
                            <div className="accordian">
                            <div className="accordian-title">
                            VIII.	POSTANOWIENIA KOŃCOWE
                            </div>
                            <div className="accordian-content">
                                <p>
                                1.	Regulamin dostępny jest w siedzibie Organizatora i na stronie https://m.skysportsnetwork.com/tnc 
                                <br></br>
                                <br></br>
                                2.	W związku z funkcjonowaniem Serwisu Organizator będzie utrzymywać w swoich bazach danych numer MSISDN telefonu komórkowego, z którego zostało wykonane połączenie, przez okres wymagany przez odpowiednie przepisy prawa, a w szczególności przez okres umożliwiający rozpatrzenie zgłoszonych reklamacji lub innych roszczeń dotyczących Serwisu. Organizator nie ma możliwości identyfikacji Użytkownika za pośrednictwem jego numeru MSISDN.
                                <br></br>
                                <br></br>
                                3.	W przypadku podania przez Użytkownika danych osobowych, będą one przetwarzane przez Organizatora zgodnie z właściwymi przepisami prawnymi z zakresu ochorony danych osobowych, włącznie z przepisami ustawy o ochronie danych osobowych. Administratorem danych osobowych Użytkowników jest Organizator. Dane te mogą być również przetwarzane na zlecenie administratora przez DIMOCO Carrier Billing GmbH, z siedzibą w Brunn am Gebirge, Campus 21, Europaring F15/302, 2345 Brunn am Gebirge, Austria. Dane Uczestników będą przetwarzane w zakresie oraz celu niezbędnym do dostarczenia Serwisu (w tym do rozpatrzenia reklamacji), a po wykonaniu Serwisu zostaną usunięte, chyba, że konieczność przechowywania niektórych danych przez okres dłuższy niż dostarczenie Serwisu wynika z przepisów prawa. Podanie danych jest dobrowolne. Użytkownicy są uprawnieni przede wszystkim do wglądu do swoich danych oraz ich poprawiania, a także żądania ich usunięcia. Jeżeli Uczestnik żąda usunięcia lub sprostowania swoich danych osobowych, może to uczynić wysyłając wiadomości email na adres poczty elektronicznej: support@skysportsnetwork.com umieszczając w treści żądanie wraz z numerem MSISDN. Żądanie takie może być również przesłane w formie pisemnej na adres podany w art. II ust. 1 niniejszego Regulaminu
                                <br></br>
                                <br></br>
                                4.	Spory wynikające z umowy o udostępnianie Serwisów będą rozpoznawane przez właściwy sąd powszechny. W przypadku wyrażenia zgody przez Użytkownika i Organizatora, spór może zostać poddany rozstrzygnięciu w drodze pozasądowej, w szczególności sprawa może zostać skierowania do stałego polubownego sądu konsumenckiego, uzgodnionego przez Strony.
                                <br></br>
                                </p>
                            </div>
                            </div>
                            </>


                        :  
                       <>
                        <div className="heading">Terms and Conditions</div>
                       <div className="accordian">
                            <div className="accordian-title">
                            DESCRIPTION OF SERVICE
                            </div>
                            <div className="accordian-content">
                                <p>
                                We offer downloadable and streaming mobile entertainment content, such as Video, Ringtones, Games, Graphics other mobile data through its website, SMS (Short Message Service) services & USSD menus to certain compatible mobile devices (the "Service"). Sky Sports Network may also include access to products & services of third parties. After proper registration & payment, you will be permitted to stream and download certain products through the service to compatible mobile phones & other compatible devices. 
                                </p>
                            </div>
                        </div>     
                        <div className="accordian">
                            <div className="accordian-title">
                            REGISTRATION AND ACCESS TO THE SERVICE
                            </div>
                            <div className="accordian-content">
                                <p>
                                In order to use the Service, you must be at least 18 years of age & have the bill payer's permission. If you are under 21 (twenty-one) then your parent or guardian should read and accept these terms of use and the Privacy Policy before you use any of the Services. The contract is void where prohibited.<br></br>
                                In order to use the Service, you must also have a mobile communications subscription with a participating carrier or otherwise have access to a mobile communications network through which We makes the Service available, & pay any service fees associated with any such access.<br></br>
                                In addition, you must provide all equipment & software necessary to connect to the Service, including, but not limited to, a mobile handset or other mobile handset/device that's in working order & suitable for use in connection with the Service.<br></br>
                                You are responsible for ensuring that your equipment &/or software do not disturb or interfere with Our operations. Any equipment or software causing interference shall be immediately disconnected from the Service & We shall have the right to immediately terminate this Agreement. <br/>
                                If any upgrade in or to the Service requires changes in your equipment or software, you must effect these changes at your own expense. Unless explicitly stated otherwise, any new or additional features that augment or enhance the current Service, including the release of new products & services, shall be subject to the terms & conditions of this Agreement.<br></br>
                                Use of any of our services constitutes the following: - 
                                <ol>
                                    <li>
                                    Your use of any of the Service offered to you, will be subject to our most current version of these Terms & Conditions for our Services and the Privacy Policy on this website at the time at which you use the Service.
                                    </li>
                                    <li>
                                    You, the user, understand that by using any of our Services, you acknowledge that you have read, understood and accept these terms of use and any other TERMS including our Privacy Policy.
                                    </li>
                                    <li>
                                    These terms of use and the Privacy Policy are not without legal force against you. Thus, when using our services and in particular when downloading any of our content, you are entering into a legally binding and enforceable agreement with us. 
                                    </li>
                                </ol>
                                </p>
                            </div>
                        </div>   
                        <div className="accordian">
                            <div className="accordian-title">
                            CONTRACTS FOR OUR SERVICE.
                            </div>
                            <div className="accordian-content">
                                <p>
                                We offer our services as individual downloads or as a bundle of credits for a defined number of individual downloads on a renewable subscription basis. Unless otherwise expressly indicated, no information presented in the Service or in connection with any products & services shall be deemed as a binding offer by us, but as an invitation for you to order.<br></br>
                                The contract for the ongoing Subscription Service between you & us shall begin when we, upon your request, provide you with access to the Service (such access may be based on a personal username & password generated for that purpose or on other data that we deem sufficient for your identification). <br></br>
                                Access to the Service can be provided by delivering to you downloadable mobile entertainment content of the content category you subscribed to (e.g. by delivering a Ringtone or an text SMS, by your selecting a package on a USSD menu) or by enabling you to download the product (e.g. by delivering a WAP-Push link or a PIN for download of the downloadable mobile entertainment content on the website of the service in question) or by providing access to the mobile entertainment content (e.g. by enabling the MSISDN for this product). <br></br>
                                The subscription period is as chosen by you, and confirmed by us in the Welcome message, depending on the type of the subscription contract. The Subscription contract & the subscription period will be renewed as applicable & a new subscription fee shall become due for the new subscription period. <br></br>
                                The subscription contract shall remain in effect until terminated &/or cancelled by you or Us. Contracts for the products & services offered by Us or independent third parties which are made available through the Service shall begin when we or the third party has accepted your order for the same or has provided you with the product or service pursuant to your order. <br></br>
                                subscription contracts include a certain defined number of credits which entitle you to download, receive &/or access that defined number of individual downloads of mobile entertainment content for a predefined period as chosen by you at a set fee. <br></br>
                                After entering into your subscription contract, we will transfer download credits to your account as per the predefined period accepted by you. The type & number of credits you are entitled to is dependent on the type of subscription you choose. <br></br>
                                The provision of credits & the making available of the possibility to download, receive &/or access mobile entertainment content is offered for a flat fee. The flat fee shall become due at the beginning of the predefined period your subscription contract is valid irrespective of whether or not you actually download any content during any particular subscription period <br></br>
                                The number of credits for downloads shall be reduced by actual downloaded mobile entertainment content, in other words the download of mobile entertainment content is evaluated as redemption of one or more credits & thereby reduces the total number of downloadable mobile entertainment content you are entitled to download, receive &/or access with your subscription contract during that particular subscription period.<br></br>
                                Depending on the type of your subscription contract, if you do not use/redeem all your credits within the subscription period, the left over credits will either (1) be rolled over into the next subscription period & remain in your account until used/redeemed or until expiration or termination of your subscription contract or (2) will expire at the end of the respective subscription period. <br></br>
                                If you download mobile entertainment content in excess of the amount allowed by your selected subscription contract, then you agree to pay the applicable retail rate for such additional downloads. 
                                </p>
                            </div>
                        </div>  
                        <div className="accordian">
                            <div className="accordian-title">
                            RESPONSIBILITY FOR THE USE OF THE SERVICE
                            </div>
                            <div className="accordian-content">
                                <p>
                                You are fully responsible for all activities that occur under your password or account or through your Subscription. That responsibility includes, but is not limited to, the responsibility for any fees for the use of the Service or any products & services made available through the Service. <br></br>
                                You agree to notify us immediately in case you become aware of or suspect unauthorized use of your password or account or Subscription or any other breach of security, & to ensure that you log out of your account at the end of each session. <br></br>
                                We cannot & will not be liable for any loss or damage arising from your failure to comply with this Section. You acknowledge & agree that you will not use the Service for any commercial purposes.  
                                </p>
                            </div>
                        </div>       
                        <div className="accordian">
                            <div className="accordian-title">
                            REGISTRATION & REGISTRATION DATA.
                            </div>
                            <div className="accordian-content">
                                <p>
                                Upon your request, We may register you as a user & provide you with access to the Service by allocating you a personal username & password. We may need to change usernames allocated to certain aspects of its Services & reserves the right to do so (you will be informed if this is necessary). <br></br>
                                The same procedure may apply to products & services made available through the Service. <br></br>
                                You are solely responsible for maintaining the confidentiality of any possible password & account. In exchange for your use of the Service (in addition to the payment of applicable fees) you agree to: (1) provide true, accurate & complete information about yourself as prompted by the registration form ("Registration Data") & (2) maintain & promptly update the Registration Data to keep it true, accurate, current & complete. 
                                <br></br>
                                If We have reasonable grounds to suspect that the Registration Data is untrue, inaccurate or incomplete, We reserve the right to suspend or terminate your account & refuse any & all current or future use of the Service (or any portion thereof). Prior to any such withdrawal we will temporarily withdraw the Service & notify you of the grounds on which the Service has been withdrawn. If the position has not permanent.
                                <br></br>
                                ACCESS WITHOUT REGISTRATION. 
                                <ol>
                                    <li>
                                    We may provide you with access to some Services without you registering as a user. In that case your identification is based on other means of identification that We deem appropriate. Identification is typically based on data identifying your Subscription, such as your mobile phone number. You agree that such information may be collected & disclosed to us & used in accordance with Section 3. 
                                    </li>
                                </ol>
                                <br></br>
                                ASSIGNMENT.
                                <ol>
                                    <li>
                                    You may not assign or transfer in any way the contract or any of your contractual rights. We shall have the right to assign our rights & obligations under this contract & any receivables based on this contract to a third party upon written notice to you, provided such assignment is made under terms not less favourable to you than those in these TERMS. After a notice concerning the transfer of receivables, payments shall be valid only when made to the new service provider. 
                                    </li>
                                </ol>
                                LICENSE TO DOWNLOAD.
                                <ol>
                                    <li>
                                    You acknowledge & agree that the Download(s) made available as part of the Service are owned by us, its affiliate &/or licensors, as applicable, & are protected by intellectual property laws. We hereby grant, & you hereby accept, a limited, non-exclusive, non-transferable, revocable license to download & use the Download(s) & the Service on a designated compatible mobile device solely for your own personal non-commercial use. You further acknowledge & agree that you may not reproduce, modify, perform, transfer, distribute, sell, create derivative works of or otherwise use or make available the Download(s) except as expressly provided in this Agreement. 
                                    </li>
                                </ol>
                                </p>
                            </div>
                        </div>  
                        <div className="accordian">
                            <div className="accordian-title">
                            PRIVACY POLICY
                            </div>
                            <div className="accordian-content">
                                <p>
                                We are committed to protecting your privacy whenever personal information is requested from you because your privacy is important to us. Personal information identifies you as an individual rather than a number or name on a list. Please read this policy listed at the top of this page to understand how we collect and use information. 
                                </p>
                            </div>
                        </div>    
                        <div className="accordian">
                            <div className="accordian-title">
                            CHARGES
                            </div>
                            <div className="accordian-content">
                                <p>
                                The fees for your Subscription Service is available on any one of the following platforms: (1) Our USSD menus, (2) Print & TV adverts & web site. The fee shall be charged for every subscription period the contract is in effect. You shall pay the subscription fee for the Services & the use thereof in accordance with the price lists in force at the time of your order. Unless otherwise indicated, the charges shall be billed according to the billing method agreed upon by you & your mobile operator. Where applicable, the charges can also be paid by using credits, with your credit card, by bank transfer or through your user account. The different payment methods are specified during the order process for the product you chose. We may also invoice you for the charges of third parties whose products or services are available through the Service if this has been agreed upon with said third party. Such charges shall be invoiced in accordance with the fees of such third parties.
                                </p>
                            </div>
                        </div> 
                        <div className="accordian">
                            <div className="accordian-title">
                            FEE CHANGES 
                            </div>
                            <div className="accordian-content">
                                <p>
                                All fees, including fees for existing subscription contracts, are subject to change upon notice from Us. We will provide you with reasonable notice of such change. If you do not accept the new fees (which will be applicable on a prospective basis only), you may cancel your subscription &/or your account effective immediately upon termination. <br></br>
                                Although we endeavour to provide the highest level of customer service at all times, we cannot be held responsible for factors beyond our control, which may affect the delivery of the content downloaded. A list of compatible handsets/devices is available on this site. It is important that you check this list before attempting to download any content. 
                                <br></br>
                                When downloading mobile content using your mobile handset/device, all charges relating to such downloads will be levied directly against your mobile services account by your service provider, including Subscription fees.
                                <br></br>
                                If you download mobile entertainment content in excess of the amount allowed by your selected subscription contract, then you agree to pay the applicable retail rate for such additional downloads. 
                                </p>
                            </div>
                        </div>   
                        <div className="accordian">
                            <div className="accordian-title">
                            CHANGES IN TERMS
                            </div>
                            <div className="accordian-content">
                                <p>
                                We reserve the right to amend this agreement from time to time. Any new version of the Agreement will be displayed on our web site together with the date on which it will become effective, which will never be less than 30 (thirty) days after the date on which it is first published. It is your obligation to visit our web site on a regular basis in order to determine whether any amendments have been made. 
                                </p>
                            </div>
                        </div>   
                        <div className="accordian">
                            <div className="accordian-title">
                            SEVERABILITY.
                            </div>
                            <div className="accordian-content">
                                <p>
                                If any part of this Agreement is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining portions shall remain in full force and effect.
                                </p>
                            </div>
                        </div>   
                        <div className="accordian">
                            <div className="accordian-title">
                            WAIVER.
                            </div>
                            <div className="accordian-content">
                                <p>
                                Our failure to pursue any available claim or defence pursuant to this Agreement or otherwise will not be a waiver of such claim or defence. The headings used in this Agreement are for convenience only, and will have no effect on the interpretation or legal enforceability of the terms herein. 
                                </p>
                            </div>
                        </div>   
                        <div className="accordian">
                            <div className="accordian-title">
                            FORCE MAJEURE
                            </div>
                            <div className="accordian-content">
                                <p>
                                We will be excused any failure to perform or delay in performance of any or all of our obligations hereunder as a result of an event of force majeure, including without limitation acts of God (including flood, fire, earthquake or other similar events), riots, war, strikes, lock-outs or other industrial disputes, epidemics, failure of telecommunications systems, failure of the world wide web, any outages of the internet or the mobile or fixed telecommunications networks or connection thereto, governmental restraints and act(s) of legislature or any other cause outside our reasonable control.
                                </p>
                            </div>
                        </div>         
                       </>
                        }
               </div>
           </div>
           {/* <div className="row">
               <div className="col">
                   <div className="heading">Security</div>
                 
                        <div className="accordian">
                            <div className="accordian-title">
                            Amendment of this Privacy Policy
                            </div>
                            <div className="accordian-content">
                                <p>
                                We reserve the right to add to or change the terms of this Privacy Policy in our discretion, without prior notice to you. If we change this Privacy Policy, we will post the new Privacy Policy on this website, and it will become effective from the time of posting. Please visit this Privacy Policy on a regular basis to make sure you have read the latest version and you understand what we do with your information.
                                </p>
                            </div>
                        </div>     
               </div>
           </div> */}
       </div>
   </div>
    </>
  );
}

export default TncPage;