import React, { useState, useRef, useEffect } from "react";


function SportsNewsPage(props) {
  const { children, open, value } = props;

  return (
    <>
        <div class="gameTop">
            <img src="/asset/images/Group36692.png" alt="" class="full" />
            <div class="pos">
                <div class="container">
                    <div class="row">
                        <div class="col30 fl">
                            <a href="#"><img src="/asset/images/PlayButton.svg" alt="" class="playBtn" /></a>
                        </div>
                        <div class="col40 fr">
                            <div class="heading">Sports News</div>
                            <div class="caption">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</div>
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="searchVideos nopaddingBottom">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="title">Latest News</div>
                    </div>
                </div>
                <div class="clr"></div>
                <div class="row">
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Avengers Infinity War</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Panther</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Premi O Premi</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Pashan</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Avengers Infinity War</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Panther</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Premi O Premi</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Pashan</div>
                            </a>
                        </div>
                    </div>
                    
                    <div class="clr"></div>
                </div>
            </div>
        </div>
        <div class="highlights">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="title">Live News Channels
                        </div>
                    </div>
                </div>
                <div class="clr"></div>
                <div class="row">
                    <div class="col33 fl">
                        <div class="mocImage">
                            <div class="h2">
                                <img src="/asset/images/1220.jpg" alt="" class="resize" />
                            </div>
                            <div class="content">
                                <div class="timePoint">
                                    <span>
                                        <img src="/asset/images/WallClock1.svg" alt="" />
                                        1hr 24mins
                                    </span>
                                    <span>
                                        <img src="/asset/images/Eye.svg" alt="" />
                                        21.3K views
                                    </span>
                                </div>
                                <div class="heading"><a href="">Movies</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col33 fl">
                        <div class="mocImage">
                            <div class="h2">
                                <img src="/asset/images/1220.jpg" alt="" class="resize" />
                            </div>
                            <div class="content">
                                <div class="timePoint">
                                    <span>
                                        <img src="/asset/images/WallClock1.svg" alt="" />
                                        1hr 24mins
                                    </span>
                                    <span>
                                        <img src="/asset/images/Eye.svg" alt="" />
                                        21.3K views
                                    </span>
                                </div>
                                <div class="heading"><a href="">Spider Man-2</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="col33 fl">
                        <div class="mocImage">
                            <div class="h2">
                                <img src="/asset/images/1220.jpg" alt="" class="resize" />
                            </div>
                            <div class="content">
                                <div class="timePoint">
                                    <span>
                                        <img src="/asset/images/WallClock1.svg" alt="" />
                                        1hr 24mins
                                    </span>
                                    <span>
                                        <img src="/asset/images/Eye.svg" alt="" />
                                        21.3K views
                                    </span>
                                </div>
                                <div class="heading"><a href="">Mafia</a></div>
                            </div>
                        </div>
                    </div>
                    <div class="clr"></div>
                </div>
            </div>
            <div class="clr"></div>
        </div>
        <div class="searchVideos">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="title">Latest News</div>
                    </div>
                </div>
                <div class="clr"></div>
                <div class="row">
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Avengers Infinity War</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Panther</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Premi O Premi</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Pashan</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Avengers Infinity War</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Panther</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Premi O Premi</div>
                            </a>
                        </div>
                    </div>
                    <div class="col255 fl">
                        <div class="image">
                            <a href="">
                                <div class="h5">
                                    <img src="/asset/images/1220.jpg" alt="" class="resize" />
                                </div>
                                <div class="caption">Pashan</div>
                            </a>
                        </div>
                    </div>
                    
                    <div class="clr"></div>
                </div>
            </div>
        </div>
    </>
  );
}

export default SportsNewsPage;