import React, {Component, useState, useEffect  } from 'react';
import { myConfig } from '../config';
import axios from 'axios';
function PricePage() {
    const [count, setCount] = useState([]);
    useEffect(() => {
        var apiurl = myConfig.server_url+"pricePoints/?billingType=PG&countryId=91&operatorId=1"
        axios.get(apiurl,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
        .then(Response => {
           setCount(Response.data.list)
           appLog(apiurl,Response)
        })   
        localStorage.setItem("activetab",'price')
    },[]);

    function checkout(amount,month,id) {
        console.log(amount)
        var options = {
            "key": "rzp_test_WBQrVQz0EsLDRJ", // Enter the Key ID generated from the Dashboard
            "amount": parseInt(amount)*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "SSN",
            "description": "Test Transaction",
            "image": 'image/logo-512.png',
            // "order_id": "1", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response){
                var userdetails = [];
                axios.get(myConfig.server_url+"subscriptions?userId=ajeet&operatorId=0&countryCode=91",{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
                .then(Response => {
                    console.log(Response)
                    if (Response.data.list.length > 0 && Response.data.list[0].userId == '1' && Response.data.list[0].operatorId == '0'  && Response.data.list[0].countryCode == '91') {
                        var country = localStorage.getItem("sCountryCode");
                        const d = new Date();
                        var rewdate;
                        if (month == "Day") {
                            rewdate = window.moment().add(7,'d').format('YYYY-MM-DD');
                        }else if (month == "Week") {
                            rewdate = window.ent().add(7,'d').format('YYYY-MM-DD');
                        }else if(month == "Month") {
                            rewdate = window.moment().add(1,'M').format('YYYY-MM-DD');
                        }else {
                            rewdate = window.moment().add(1,'Y').format('YYYY-MM-DD');
                        }
                        var addsub = {
                            "amount": parseInt(amount),
                            "countryCode": "91",
                            "cpTransactionId":"111",
                            "currency": "INR",
                            "lastSuccessfullChargeDate": rewdate,
                            "nextRenewalDate": rewdate,
                            "operatorId": "0",
                            "operatorName": "PG",
                            "paymentGWDetail": "Razor Pay",
                            "paymentType":"Credit Cared",
                            "planType": month,
                            "pricePoint": "PG00001",
                            "subsType": "Year",
                            "subscriberType": "NEWREQUEST",
                            "subscriptionStatus": "SUBSCRIBED",
                            "transactionId": response.razorpay_payment_id,
                            "userId": "ajeet"
                        }
                        axios.put(myConfig.server_url+"subscription/",addsub,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
                        .then(Response => {
                        alert("changed")
                        })   
                    }else {
                        var country = localStorage.getItem("sCountryCode");
                        const d = new Date();
                        var rewdate;
                        if (month == "Day") {
                            rewdate = window.moment().add(7,'d').format('YYYY-MM-DD');
                        }else if (month == "Week") {
                            rewdate = window.moment().add(7,'d').format('YYYY-MM-DD');
                        }else if(month == "Month") {
                            rewdate = window.moment().add(1,'M').format('YYYY-MM-DD');
                        }else {
                            rewdate = window.moment().add(1,'Y').format('YYYY-MM-DD');
                        }
                        var addsub = {
                            "amount": parseInt(amount),
                            "countryCode": "91",
                            "cpTransactionId":"111",
                            "currency": "INR",
                            "lastSuccessfullChargeDate": rewdate,
                            "nextRenewalDate": rewdate,
                            "operatorId": "0",
                            "operatorName": "PG",
                            "paymentGWDetail": "Razor Pay",
                            "paymentType":"Credit Cared",
                            "planType": month,
                            "pricePoint": "PG00001",
                            "subsType": "Year",
                            "subscriberType": "NEWREQUEST",
                            "subscriptionStatus": "SUBSCRIBED",
                            "transactionId": response.razorpay_payment_id,
                            "userId": "ajeet"
                        }
                        axios.post(myConfig.server_url+"subscription/",addsub,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
                        .then(Response => {
                        alert("successfull")
                        })   
    
                    }
                })   
                
            },
            "prefill": {
                "name": "",
                "email": "",
                "contact": ""
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        var rzp1 = window.Razorpay(options);
        rzp1.on('payment.failed', function (response){
                alert(response.error.code);
                alert(response.error.description);
                alert(response.error.source);
                alert(response.error.step);
                alert(response.error.reason);
        });
        rzp1.open();
            // document.getElementById('').onclick = function(e){
            
            // }
    }
    
    function appLog(url, res) {
        var browser = (function() {
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
            })();
            if(window.sessionStorage.getItem('userId') != undefined) {
                var gettime = new Date();
                var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
                var finalobj6 = {
                security : "col#c4",
                logData : createanotherurl
                }
                axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
            }
        }

        const updateFieldChanged = index => e => {
           this.setState({catIndex: index});
          }
	    return (
            <div>
                <div class="subsTop">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="text">
                                    Award Winning Films &<br />Documentaries.
                                </div>
                                <div class="images">
                                    <img src="asset/images/s1.png" alt="" />
                                    <img src="asset/images/s2.png" alt="" />
                                    <img src="asset/images/s3.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subs">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                            <div class="subsIn">
                                {count.map((price,i) =>
                                    <div class={i % 2 == 0 ? "Rcol border fl":"Rcol fl"}>
                                        <div class="head">{price.pricePointTitle}</div>
                                        <div class="cap">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</div>
                                        <div class="price" style={{padding:"-1px 20%"}}>₹ {price.pricePointAmount} / {price.pricePointType}</div>
                                        <div class="images">
                                            <img src="asset/images/Ellipse14.png" alt="" />
                                            <img src="asset/images/Ellipse15.png" alt="" />
                                            <img src="asset/images/Ellipse17.png" alt="" />
                                        </div>
                                        <a href="#" class="link" onClick={() => checkout(price.pricePointAmount,price.pricePointType,price.id)}>Start {price.pricePointType == "Day" ? "7 day" : ""}  {price.pricePointType == "Week" ? "1 Week" : ""} {price.pricePointType == "Month" ? "1 Month" : ""} {price.pricePointType == "Year" ? "1 Year" : ""} Subscription</a>
                                        </div>
                                    
                                )}
                                    <div class="clr"></div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tic">
                    <div class="ticContent fl">
                        <div class="heading">The World of Sport in the Palm of your Hand.</div>
                        <div class="content"> {/* Amet minim mollit non deserunt ullamco est sit<br />aliqua dolor do amet sint.*/}</div>
                        <div class="imageFols">
                            <a href="javascript:void(0);"><img src="asset/images/Group(1).svg" alt=""/></a>
                            <img src="asset/images/Line5.svg" alt=""/>
                            <a href="javascript:void(0);"><img src="asset/images/Group.svg" alt=""/></a>
                        </div>
                    </div>
                    <div class="image fr">
                        <img src="asset/images/Group36665.png" alt=""/>
                    </div>
                    <div class="clr"></div>
                </div>
                <div class="featuredChannel">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="title">Featured Channel</div>
                            </div>
                        </div>
                        <div class="clr"></div>
                        <div class="row">
                            <div class="col">
                                <a href=""><img src="asset/images/Ellipse14.png" alt=""/></a>
                                <a href=""><img src="asset/images/Ellipse15.png" alt=""/></a>
                                <a href=""><img src="asset/images/Ellipse17.png" alt=""/></a>
                                <a href=""><img src="asset/images/Ellipse19.png" alt=""/></a>
                                <a href=""><img src="asset/images/Ellipse16.png" alt=""/></a>
                                <a href=""><img src="asset/images/Ellipse18.png" alt=""/></a>
                                <a href=""><img src="asset/images/Ellipse13.png" alt=""/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                )
    
}

export default PricePage;
