import React from 'react';

import Header from './Pages/Header';
import PlayerHeader from './Pages/PlayerHeader';
import RelatedVideo from './Pages/RelatedVideo';
import Highlights from './Pages/Highlights';
import SearchPage from './Pages/SearchPage';
import Footer from './Pages/Footer';

function Search() {
    return (
        [
            <Header />,
            <SearchPage />,
            <Footer />
        ]
    );
}

export default Search;