import React, { useState, useRef, useEffect } from "react";

import axios from 'axios';
import { myConfig } from '../config';

function FaqPage(props) {
    const [faqs , setFaqs] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
        var apiurl = myConfig.server_url+'listAllFAQ/'
      const result = await axios(
        apiurl,
        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
      );
      //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
      setFaqs(result.data.listData);
      appLog(apiurl,result)
    };
    fetchData();
  }, []);


  function appLog(url, res) {
    var browser = (function() {
        var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
        switch (true) {
            case test(/edg/i): return "Microsoft Edge";
            case test(/trident/i): return "Microsoft Internet Explorer";
            case test(/firefox|fxios/i): return "Mozilla Firefox";
            case test(/opr\//i): return "Opera";
            case test(/ucbrowser/i): return "UC Browser";
            case test(/samsungbrowser/i): return "Samsung Browser";
            case test(/chrome|chromium|crios/i): return "Google Chrome";
            case test(/safari/i): return "Apple Safari";
            default: return "Other";
        }
        })();
        if(window.sessionStorage.getItem('userId') != undefined) {
            var gettime = new Date();
            var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
            var finalobj6 = {
            security : "col#c4",
            logData : createanotherurl
            }
            axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
        }
    }
    
  return (
    <>
        <div className="faqHead">
       <div className="container">
           <div className="row">
               <div className="col">
                   <div className="heading">How can we help you today?</div>
                   <div className="caption">{/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut<br />labore et dolore magna aliqua. Ut enim ad minim veniam.  */}<a href="#footer">Contact Us</a></div>
               </div>
           </div>
       </div>
   </div>
   <div className="faqdata">
       <div className="container">
           <div className="row">
               <div className="col">
                   <div className="heading">Top Categories FAQ’s</div>
                   {
                       faqs.map((faq)=>{
                            return(
                                <div className="accordian">
                                    <div className="accordian-title">
                                       {faq.question}
                                    </div>
                                    <div className="accordian-content">
                                        <p>{faq.answer}</p>
                                    </div>
                                </div>  
                            )
                       })
                   }
                                   
                <div className="needhelp">Need more help <a href="#footer">Contact Us</a></div>
               </div>
           </div>
       </div>
   </div>
    </>
  );
}

export default FaqPage;