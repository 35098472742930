import React,{useEffect} from 'react';

import HeaderStill from './Pages/Header';
import PlayerHeader from './Pages/PlayerHeader';
import RelatedVideo from './Pages/RelatedVideo';
import Highlights from './Pages/Highlights';
import PricePage from './Pages/PricePage';
import Footer from './Pages/Footer';

function Price() {
  //  const [priceList , setPriceList] = useState([]);
  /*  useEffect(() => {
        const fetchData = async () => {
          const result = await axios(
            myConfig.server_url+'contentDetailBySubcategoryNCategory/?categoryId='+props.match.params.cId+'&subcategoryId='+props.match.params.scId+'&contentId='+props.match.params.id+'&contrycode=91',
          );
          //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
          setRelatedData(result.data.list);
        };
        fetchData();

      }, []);*/

      useEffect(() => {
        localStorage.setItem("activetab",'pricing')
        console.log("in");
      },[])
    return (
        [
            <HeaderStill />,
            <PricePage />,
            <Footer />
        ]
    );
}

export default Price;