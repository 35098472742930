import React, {Component} from 'react';
import { myConfig } from '../config';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import $ from 'jquery';
class TopSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading : true,
            Banners:[],
            nav1: null,
            nav2: null
        };
        this.appLog = this.appLog.bind(this);
      }

      handleLogin(path) {
        var login = window.sessionStorage.getItem('isLogin');
        
        if (login == "true" || login == true) {
            window.location.href = path
        }else {
            window.sessionStorage.setItem("lastClick", path);
             $('#loginpopup').show();
        }
      }

     

      componentDidMount() {
          var urls = myConfig.server_url+"homepageBanerDetail?paramName=HOMEPAGE_STATIC_BANNER" 
        axios.get(urls,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
        .then(res => {
            console.log('bannerAPI',res)
            this.appLog(urls, res)
           this.setState({ Banners: res.data.banner,
            nav1: this.slider1,
            nav2: this.slider2
            });
        })
    }

    appLog(url, res) {
        var browser = (function() {
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
            })();
            if(window.sessionStorage.getItem('userId') != undefined) {
                var gettime = new Date();
                var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
                var finalobj6 = {
                security : "col#c4",
                logData : createanotherurl
                }
                axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
            }
    }
    render() {
        const settings = {
            slidesToShow: 4,
            asNavFor: 'bgBanner',
            focusOnSelect: true,
          };
        const bgBanner = {
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: 'settings',
            arrows: false,
            autoplay: true,
            autoplaySpeed: 5000,
            draggable:false,
            // dots:true,
            // dotsClass: "button__bar",
            responsive: [
            {
                breakpoint: 768,
                settings: {
                // dots:true
                }
            }
            ]
        }
//http://13.126.46.199:8080/ssn/homepageBanerDetail?paramName=HOMEPAGE_STATIC_BANNER
        return (
            <div class="topSlider">
                <div class="sliderTop">
                    <ul class="sliderTopSlider">
                        <Slider {...bgBanner} asNavFor={this.state.nav2}
          ref={slider => (this.slider1 = slider)} >
                        {
                            this.state.Banners.map((banner)=>{
                                    return(
                                            <li key="1">
                                                <img src={banner.banner_url} alt="" />
                                                <div class="content">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col30 fl">
                                                                <a href="javascript:void(0)" onClick={this.handleLogin.bind(this,"/details/"+banner.content_id+"/"+banner.subCategory_id+"/"+banner.category_id)}><img src="/asset/images/PlayButton.svg" alt="" /></a>
                                                                <div class="clr"></div>
                                                            </div>
                                                            <div class="col50 fr">
                                                                <div class="head1">
                                                                    {banner.title}
                                                                </div>
                                                                <div class="con2">
                                                                    {banner.description}
                                                                </div>
                                                                <div class="con3">
                                                                {banner.gener?"Genre :":""} {banner.gener}
                                                                </div>
                                                                <div class="con3">
                                                                {banner.duration?"Duration :":""} {banner.duration}
                                                                </div>
                                                                <div class="con3">
                                                                    {banner.ratings?"Ratings :":""} {banner.ratings}
                                                                </div>
                                                            </div>
                                                            <div class="clr"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                    )
                            })
                        }
                        
                       </Slider> 
                    </ul>
                </div>
                <div class="sliderNav">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="title">
                                  {/*}  Trailers {*/}
                                </div>
                            </div>
                        </div>
                        <div class="row show">
                                <Slider {...settings} asNavFor={this.state.nav1}
          ref={slider => (this.slider2 = slider)}>
                                {
                                        this.state.Banners.map((banner,index)=>{
                                            return(
                                                <div class="col25">
                                                    <img src={banner.thumb_url} alt="" />
                                                    <div class="number">{banner.sequence}</div>
                                                </div>
                                            )
                                            
                                        })
                                }
                                </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TopSlider;