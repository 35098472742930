import React, { useState, useRef, useEffect } from "react";
import $ from 'jquery';
import axios from 'axios';
import { myConfig } from '../config';
import Header from './Header';
import { SettingsPhone } from "@material-ui/icons";
import Footer from "./Footer";
import lang from './lang.json'

function SubscribePage(props) {
    const [faqs , setFaqs] = useState([]);
    const [lan, setlan] = useState({})  
    const [phone,getphone] = useState('');
    const [otp , getotp] = useState('');
    const [dubai, setdubai] = useState(/dubai/)
    const [voda, setvoda] = useState(/voda/)
    const [virgin, setvirgin] = useState(/virgin/)
    const [beeline, setbeeline] = useState(/beeline/)
    const [tmobile, settmobile] = useState(/tmobile/)
    const [orange, setorange] = useState(/orange/)
    const [play, setplay] = useState(/play/)
    const [plus, setplus] = useState(/plus/)
    const [grameen, setgrameen] = useState(/grameen/)

   
    useEffect(()=> {
      localStorage.setItem("activetab","welcome")
      var str = localStorage.getItem("lang")
      if (dubai.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","en")
        }
      }else if(virgin.test(window.location.pathname) == true){
        if (str == undefined) {
          str = localStorage.setItem("lang","ven")
        }
      }else if(voda.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","voen")
        }
      }else if(beeline.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","boen")
        }
      }else if(tmobile.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","plten")
        }
      }else if(orange.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","ploen")
        }
      }else if(play.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","plpen")
        }
      }else if(plus.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","plppen")
        }
      }else if(grameen.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","en_gr")
        }
      }

      if(str == 'en') {
        setlan(lang.en);
      }else if(str == 'ar') {
        setlan(lang.ar)
      }else if(str == 'var') {
        setlan(lang.var)
      }else if(str == 'ven') {
        setlan(lang.ven)
      }else if(str == 'voen') {
        setlan(lang.voen)
      }else if(str == 'boen') {
        setlan(lang.boen)
      }else if(str == 'boar') {
        setlan(lang.boar)
      }else if(str == 'plten') {
        setlan(lang.plten)
      }else if(str == 'ploen') {
        setlan(lang.ploen)
      }else if(str == 'plpen') {
        setlan(lang.plpen)
      }else if(str == 'plppen') {
        setlan(lang.plppen)
      }else if(str == 'en_gr') {
        setlan(lang.en_gr)
      }
    })


  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        myConfig.server_url+'listAllFAQ/',
        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
      );
      //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
      setFaqs(result.data.listData);
    };
    fetchData();
  }, []);
  
  return (
    <>
      <Header></Header>
       <div className="landingPage">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="heading">{lan.product_name}</div>
                      <div className="subheading" style={{marginBottom:"10%"}}><span>{lan.productaleeady} </span></div>
                      <div className="termsData"> <a href="/">click here</a>  to access the SSN.</div>
                      <div className="termsData"> <a href="/">click here</a> for support and help</div>
                </div>
            </div>
        </div>
    </div>  
    <Footer></Footer>
    </>
  );
}

export default SubscribePage;