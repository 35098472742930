import React,{ useEffect, useState }  from 'react';

import Header from './Pages/Header';
import TopSlider from './Pages/TopSlider';
import Highlights from './Pages/Highlights';
import Tic from './Pages/Tic';
import FeaturedChannel from './Pages/FeaturedChannel';
import HerHim from './Pages/HerHim';
import Testimonial from './Pages/Testimonial';
import Footer from './Pages/Footer';
import { myConfig } from './config';
import axios from 'axios';
import ReactDOMServer from 'react-dom/server';

function Home() {
    const [categories , setCategories] = useState([]);
      useEffect(() => {
        var checkhomepagedata = JSON.parse(localStorage.getItem("listHomePage"))
        const fetchData = async () => {
          const result = await axios(
            myConfig.server_url+"listHomePage/",
            {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
          );
          //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
          setCategories(result.data.homepage[0].categories);
          localStorage.setItem("listHomePage", JSON.stringify(result.data));
        };
        fetchData();
        localStorage.setItem("activetab","home")
      },[]);
    return (
        [
            <div>{console.log('react:',ReactDOMServer)}</div>,
            <Header />,
            <TopSlider />,
            <Highlights />,
            <Tic />,
            <FeaturedChannel />,
            <HerHim cats = {categories} />,
            <Footer />
        ]
    );
}

export default Home;