import React, {Component} from 'react';

class RelatedVideo extends Component {
    render() {
	    return (
            <div class="relatedVideos">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="title">Related Videos</div>
                        </div>
                    </div>
                    <div class="clr"></div>
                    <div class="row">
                        {console.log(this.props.relatedData)}
                        {
                            this.props.relatedData[0].map((relatedVideo)=>{
                                    return(
                                        <div class="col255 fl">
                                            <div class="image">
                                                <a href={"/details/"+relatedVideo.id+"/"+this.props.relatedData[2]+"/"+this.props.relatedData[1]}>
                                                    <img src={relatedVideo.thumbnilUrl} alt=""/>
                                                    <div class="caption">{relatedVideo.contentTitle}</div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                            })
                        }
                        <div class="clr"></div>
                    </div>
                </div>
            </div>
        )
    }
}
export default RelatedVideo;
