import React, { useState, useRef, useEffect } from "react";
import axios from 'axios';
import $ from 'jquery';
import { useHistory } from "react-router-dom";
import { myConfig } from '../config';
import Header from "./Header";
import Footer from "./Footer";

function Profile(props) {
    const [faqs , setFaqs] = useState([]);
    const [trsdetails, settrsdetails] = useState([])
    const [active, setactive] = useState(false)
    let history = useHistory();

  useEffect(() => {
      var getuserId = window.sessionStorage.getItem('userId')
      var countycode = window.sessionStorage.getItem('dialCode')
      var login =  window.sessionStorage.getItem('isLogin')

      if (login == null) {
        history.push('/')
      }
    const fetchData = async () => {
      var apiurl = myConfig.server_url+'getSubscribedUser/'+getuserId
      const result = await axios(
        apiurl,
        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
      );
      //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?catego  ryId=1&subcategoryId=1&contentId=1&contrycode=91
      setFaqs(result.data);
      appLog(apiurl,result)

      var anotherapi = myConfig.billing_url+"checkSubscription.php?msisdn="+getuserId+"&operator_id=144"
      const results = await axios(
        anotherapi,
      );
    console.log(results);
    setactive(results.data.status == "success" ? "Active" :"In Active" )

    var anotherapis = myConfig.billing_url+"transactionHistory.php?msisdn="+getuserId+"&opId=144"
        const resultsss = await axios(
          anotherapis,
        );
      console.log(resultsss);
        appLog(anotherapis, resultsss)
        settrsdetails(resultsss.data.data)
    };
    fetchData();
  }, []);

  function appLog(url, res) {
    var browser = (function() {
        var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
        switch (true) {
            case test(/edg/i): return "Microsoft Edge";
            case test(/trident/i): return "Microsoft Internet Explorer";
            case test(/firefox|fxios/i): return "Mozilla Firefox";
            case test(/opr\//i): return "Opera";
            case test(/ucbrowser/i): return "UC Browser";
            case test(/samsungbrowser/i): return "Samsung Browser";
            case test(/chrome|chromium|crios/i): return "Google Chrome";
            case test(/safari/i): return "Apple Safari";
            default: return "Other";
        }
        })();
        if(window.sessionStorage.getItem('userId') != undefined) {
            var gettime = new Date();
            var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
            var finalobj6 = {
            security : "col#c4",
            logData : createanotherurl
            }
            axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
        }
    }

  function edituser() {
    history.push("/editprofile");
  }
 
  function logout() {
      window.sessionStorage.removeItem('userId')
      window.sessionStorage.removeItem('isLogin')
      history.push('/')
  }

  async function Unsubscribe() {
    if (window.confirm("Are you sure you want to Unsubscribe") == true) {
      var urls = myConfig.billing_url+"unsubscription.php?mode=WAP&msisdn="+window.sessionStorage.getItem('userId')+"&product_id=SEVb8b9511"
      var res = await axios(urls)
      appLog(urls, res)
      console.log(res);
      if (res.data.status == "FAIL") {
        $('#transectiohistory3').show()
        $('body').addClass('fixed')
      }else {
        $('#transectiohistory2').show()
        $('body').addClass('fixed')
      }
      // window.location.href = "/"
    } else {
      $('#transectiohistory1').hide()
      $('body').removeClass('fixed')
    }
  }

  function closesubscribe() {
    $('#transectiohistory2').hide()
    $('body').removeClass('fixed')
    window.sessionStorage.removeItem('userId')
    window.sessionStorage.removeItem('isLogin')
    history.push('/')
  }
  return (
    <>
      <Header></Header>
      <div class="profilebase">
          <div class="container">
              <div class="row">
                  <div class="col">
                      <div class="top">
                          <div class="image" onClick={edituser}>
                            
                              <img src= {localStorage.getItem('prfileImage')==null || localStorage.getItem('prfileImage') == "null" ?"/asset/images/user.png":`data:image/png;base64,${localStorage.getItem('prfileImage')}`}  style={{borderRadius:"50%",width:"70px",height:"70px"}}  alt="user" />
                              <a>Edit</a>
                          </div>
                        <div class="name">{faqs.name != null ? faqs.name : ""}</div>
                        <div class="mob"> { faqs.phoneNumber != null ? faqs.phoneNumber : ""} </div>
                        {/* +{faqs.countryCode != null ? faqs.countryCode : 91} */}
                      </div>
                      <div class="outSkirt">
                          {faqs.subscription != null ? 
                          <>
                          <div class="pass">{faqs.subscription.planType} Pass <span>₹ {faqs.subscription.amount}/ {faqs.subscription.planType}</span></div>
                          <div class="valid">Valid till {window.moment(faqs.nextRenewalDate).format('ll')}</div>
                          </>
                          : ""}
                          <ul class="links">
                              <li><a href="javascript:void(0)" class="upgrade noArrow">Subscription Status({active})</a></li>
                              <li><a href="" onClick={edituser}>Edit Profile</a></li>
                              <li><a href="javascript:void(0)" class="opntransectionhistory">Transaction History</a></li>
                              {/* <li><a href="javascript:void(0)" class="opntransectionhistory1 noArrow">Unsubscribe</a></li> */}
                              <li><a href="javascript:void(0)" class="noArrow" onClick={() => Unsubscribe()}>Unsubscribe</a></li>
                              <li><a href="" class="noArrow" onClick={logout}>Logout</a></li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="overlay" id="transectiohistory">
        <div class="table" style={{textAlign:"center"}}>
            <div class="heading">Transaction History <img src="/asset/images/Cancel2.svg" alt="" class="closetransection" /></div>
            <div class="tableHead">
                <div class="cola2 fl">BILL Tnx ID</div>
                <div class="cola1 fl">OPERATOR</div>
                <div class="cola3 fl">TNX AMOUNT</div>
                <div class="cola4 fl">BILLING TYPE</div>
                <div class="cola5 fl">STATUS</div>
                <div class="cola6 fl">TNX DATE</div>
                <div class="clr"></div>
            </div>
            <div class="tableBody">
            {trsdetails.length > 0 ? trsdetails.map((f,i) => {
                                return (
                                  <div class="rowTb">
                                    <div class="cola2 fl">{f.BILL_TRANSID}</div>
                                    <div class="cola1 fl">{f.OPERATOR}</div>
                                    <div class="cola3 fl">{f.RATE}</div>
                                    <div class="cola4 fl">{f.BILLING_TYPE}</div>
                                    <div class="cola5 fl">{f.STATUS}</div>
                                    <div class="cola6 fl">{f.ADDED_ON}</div>
                                    <div class="clr"></div>
                                  </div>
                                )
                            }): ""}
              
            </div>
        </div>
      </div>

      <div class="overlay" id="transectiohistory1">
        <div class="table" style={{textAlign:"center",width:"40%",marginTop:"50vh"}}>
        <div class="heading"><img src="/asset/images/Cancel2.svg" alt="" class="closetransection1" style={{float:"right"}}/></div>  
            <div class="heading">Unsubscribe</div>
            {/* dubai */}
            {/* <div>To Unsubscribe, send C SKY to 1111</div> */}
            {/* beeline */}
            <div>To Unsubscribe, UNSUB SKY to 1406</div>
        </div>
      </div>


      <div class="overlay" id="transectiohistory2">
        <div class="table" style={{textAlign:"center",width:"40%",marginTop:"50vh"}}>
        <div class="heading"><img src="/asset/images/Cancel2.svg" alt="" onClick={() => closesubscribe()} style={{float:"right"}}/></div>  
            <div class="heading">Success</div>
            {/* dubai */}
            {/* <div>To Unsubscribe, send C SKY to 1111</div> */}
            {/* beeline */}
            <div>You Successfully Unsubscribe</div>
        </div>
      </div>

      <div class="overlay" id="transectiohistory3">
        <div class="table" style={{textAlign:"center",width:"40%",marginTop:"50vh"}}>
        <div class="heading"><img src="/asset/images/Cancel2.svg" alt="" class="closetransection2" style={{float:"right"}}/></div>  
            <div class="heading">Fail</div>
            {/* dubai */}
            {/* <div>To Unsubscribe, send C SKY to 1111</div> */}
            {/* beeline */}
            <div>You not Unsubscribe</div>
        </div>
      </div>
    <Footer></Footer>
    </>
  );
}
export default Profile;