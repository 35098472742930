

import React, { Component } from 'react';
import { BrowserRouter, Route, withRouter } from "react-router-dom";
import { myConfig } from '../config';
import axios from 'axios';
import codeJson from '../CountryCodes.json';

class Header extends Component {
    constructor(props) {
        super(props);
        // this.handleClick= this.handleClick.bind(this);
        this.state = {
            loading: true,
            isLogin: false,
            isActiveTab: localStorage.getItem("activetab"),
            isloginname: localStorage.getItem("name"),
            islogincheck: window.sessionStorage.getItem("isLogin"),
            showlanguage: /promo/,
            language: /virgin/,
            language2: /dubai/,
            language3: /qatar/,
            language4: /beeline/,
            language5: /tmobile/,
            language6: /orange/,
            language7: /play/,
            language8: /plus/,
        }
        console.log(window.location.pathname);
        console.log(this.state)
        console.log(this.state.language.test(window.location.pathname))
        console.log(this.state.language2.test(window.location.pathname))
        console.log(this.state.language3.test(window.location.pathname))
        console.log(this.state.language4.test(window.location.pathname))
        console.log(this.state.language5.test(window.location.pathname))
        console.log(this.state.language6.test(window.location.pathname))
        console.log(this.state.language7.test(window.location.pathname))
        console.log(this.state.language8.test(window.location.pathname))
    }
    async setCountryCode() {

    }
    async componentDidMount() {
        if (this.state.language2.test(window.location.pathname) == true || this.state.language.test(window.location.pathname) == true || this.state.language3.test(window.location.pathname) == true || this.state.language4.test(window.location.pathname) == true) {
            var str = window.location.pathname
            const dataes = codeJson.filter(i => i.link === str)
            if (dataes.length > 0) {
                window.sessionStorage.setItem("dialCode", dataes[0].dial_code.slice(1))
                window.sessionStorage.setItem("path", "")
            }
        } else {
            axios.get(myConfig.billing_url + "countryCode.php")
                .then(res => {
                    window.sessionStorage.setItem("sCountryCode", res.data.geoplugin_countryCode)
                    const dataArray = codeJson.filter(i => i.code === res.data.geoplugin_countryCode)
                    if (dataArray.length > 0) {
                        window.sessionStorage.setItem("dialCode", dataArray[0].dial_code.slice(1))
                        if (window.sessionStorage.getItem("path") == undefined) {
                            window.sessionStorage.setItem("path", "")
                        }
                    } else {
                        window.sessionStorage.setItem("dialCode", "91")
                        if (window.sessionStorage.getItem("path") == undefined) {
                            window.sessionStorage.setItem("path", "")
                        }
                    }
                },
                    window.sessionStorage.getItem("isLogin") ? this.setState({ isLogin: true }) : this.setState({ isLogin: false })
                )
        }
    }

    handleClick = (s) => {
        console.log(s)
        localStorage.setItem("activetab", s)
    }

    async languageChnage() {
        var lan = document.getElementById('languages').value
        console.log(lan);
        // this.props.history.push("/about");
        this.props.history.push(window.location.pathname)
        localStorage.setItem("lang", lan)
        // window.location.href="/landingpage/"+lan
    }


    openprofile = () => {
        window.location.href = "/profile"
    }
    render() {
        return (
            this.state.isLogin ?
                <header class="still">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="logo fl">
                                    <a href="/"><img src="/asset/images/logo.svg" alt="" /></a>
                                </div>
                                <div class="navigation fl">
                                    <ul>
                                        {/* <li className={this.state.isActiveTab == "home" ? "active" : ""}><a href="/" onClick={() => this.handleClick("home")}>Home</a></li> */}
                                        { /*  <li className={this.state.isActiveTab == "sport" ? "active" : ""}><a href="sports-news" onClick={() => this.handleClick("sport")}>Sports News</a></li>
                            <li className={this.state.isActiveTab == "game" ? "active" : ""}><a href="/games" onClick={() => this.handleClick("game")}>Games</a></li> */ }
                                        {/* <li className={this.state.isActiveTab == "video" ? "active" : ""}><a href="/categories/1/0" onClick={() => this.handleClick("video")}>Video</a></li> */}
                                        {/* <li className={this.state.isActiveTab == "faq" ? "active" : ""}><a href="/faq" onClick={() => this.handleClick("faq")}>FAQ</a></li> */}
                                        {/* <li className={this.state.isActiveTab == "pricing" ? "active" : ""}><a href="/price" onClick={() => this.handleClick("pricing")}>Pricing</a></li> */}
                                        {/* <li className={this.state.isActiveTab == "contact" ? "active" : ""}><a href="#footer" >Contact Us</a></li> */}
                                    </ul>
                                </div>
                                <div class="menuIcon fr">
                                    <button>menu</button>
                                </div>
                                <div class="searchIcon fr" style={{ marginLeft: "20px" }}>
                                    <a href="/search"><img src="/asset/images/Search.svg" alt="" /></a>
                                </div>

                                <div class="afterLogin fr">
                                    <img src={localStorage.getItem('prfileImage') == null || localStorage.getItem('prfileImage') == "null" ? "/asset/images/user.png" : `data:image/png;base64,${localStorage.getItem('prfileImage')}`} alt="" style={{ borderRadius: "50%", width: "50px" }} onClick={() => this.openprofile()} />
                                    {this.state.islogincheck === true || this.state.islogincheck === "true" ? this.state.isloginname : "Jerome Bell"}
                                </div>
                                <div class="clr"></div>
                            </div>
                        </div>
                    </div>
                </header> :
                <header>
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <div class="logo fl">
                                    <a href=""><img src="/asset/images/logo.svg" alt="" /></a>
                                </div>
                                <div class="navigation fl">
                                    <ul>
                                        {/* <li className={this.state.isActiveTab == "home" ? "active" : ""}><a href="/" onClick={() => this.handleClick("home")}>Home</a></li> */}
                                        {/* <li className={this.state.isActiveTab == "sport" ? "active" : ""}><a href="sports-news" onClick={() => this.handleClick("sport")}>Sports News</a></li>
                            <li className={this.state.isActiveTab == "game" ? "active" : ""}><a href="/games" onClick={() => this.handleClick("game")}>Games</a></li>*/}
                                        {/* <li className={this.state.isActiveTab == "video" ? "active" : ""}><a href="/categories/1/0" onClick={() => this.handleClick("video")}>Video</a></li> */}
                                        {/* <li className={this.state.isActiveTab == "faq" ? "active" : ""}><a href="/faq" onClick={() => this.handleClick("faq")}>FAQ</a></li> */}
                                        {/* <li className={this.state.isActiveTab == "pricing" ? "active" : ""}><a href="/price" onClick={() => this.handleClick("pricing")}>Pricing</a></li> */}
                                        {/* <li className={this.state.isActiveTab == "contact" ? "active" : ""}><a href="#footer" >Contact Us</a></li> */}
                                    </ul>
                                </div>
                                <div class="menuIcon fr">
                                    <button>menu</button>
                                </div>
                                <div class="beforelogin fr">
                                    <ul>
                                        <li>{this.state.showlanguage.test(localStorage.getItem("activetab")) == true || localStorage.getItem("activetab") == "welcome" || localStorage.getItem("activetab") == "subscribe" || localStorage.getItem("activetab") == "subscribe" || localStorage.getItem("activetab") == "fail" ? "" : <a href="javascript:void(0)" class="openLogin">Login</a>}</li>
                                        {/* <li><a href="">Register</a></li>*/}
                                        {this.state.language3.test(window.location.pathname) == false || this.state.language5.test(window.location.pathname) == false || this.state.language6.test(window.location.pathname) == false || this.state.language7.test(window.location.pathname) == false || this.state.language8.test(window.location.pathname) == false || this.state.showlanguage.test(localStorage.getItem("activetab")) == true || localStorage.getItem("activetab") == "welcome" || localStorage.getItem("activetab") == "subscribe" || localStorage.getItem("activetab") == "subscribe" || localStorage.getItem("activetab") == "fail" ?
                                            <li style={{ marginTop: "10px" }}>
                                                {this.state.language3.test(window.location.pathname) == false && this.state.language5.test(window.location.pathname) == false && this.state.language6.test(window.location.pathname) == false && this.state.language7.test(window.location.pathname) == false && this.state.language8.test(window.location.pathname) == false && this.state.showlanguage.test(localStorage.getItem("activetab")) == true ? <select placeholder="Choose a Language..." id="languages" onChange={() => this.languageChnage()} defaultValue={localStorage.getItem("lang") || 'ar'}>
                                                    <option value="">Language</option>
                                                    <div className=''>عربي</div>
                                                    {(this.state.language2.test(window.location.pathname) == true || this.state.language2.test(window.sessionStorage.getItem("path")) == true) ?
                                                        <>
                                                            <option value="en">English</option>
                                                            <option value="ar">Arabic</option>
                                                            {/* <option value="ar">
                                                                {localStorage.getItem("lang") === 'en' ? 'Arabic' : 'عربي'}
                                                            </option> */}
                                                        </>
                                                        : ""}

                                                    {this.state.language.test(window.location.pathname) == true || this.state.language.test(window.sessionStorage.getItem("path")) == true ?
                                                        <>
                                                            <option value="ven">English</option>
                                                            <option value="var">عربي</option>
                                                        </>
                                                        : ""}

                                                    {this.state.language4.test(window.location.pathname) == true || this.state.language4.test(window.sessionStorage.getItem("path")) == true ?
                                                        <>
                                                            <option value="boen">English</option>
                                                            <option value="boar">Georgian</option>
                                                        </>
                                                        : ""}

                                                    {/* {this.state.language5.test(window.location.pathname) == true || this.state.language5.test(window.sessionStorage.getItem("path")) == true ? 
                                    <>
                                        <option value="plten">polish</option>
                                    </>
                                    : ""}

                                    {this.state.language6.test(window.location.pathname) == true || this.state.language6.test(window.sessionStorage.getItem("path")) == true ? 
                                    <>
                                        <option value="ploen">polish</option>
                                    </>
                                    : ""}

                                    {this.state.language7.test(window.location.pathname) == true || this.state.language7.test(window.sessionStorage.getItem("path")) == true ? 
                                    <>
                                        <option value="plpen">polish</option>
                                    </>
                                    : ""}

                                    {this.state.language8.test(window.location.pathname) == true || this.state.language8.test(window.sessionStorage.getItem("path")) == true ? 
                                    <>
                                        <option value="plppen">polish</option>
                                    </>
                                    : ""} */}
                                                </select> : ""}
                                            </li> : ""}

                                    </ul>
                                    {/* <li className="language-spacing">
                                        {this.state.language2.test(window.location.pathname) && <span>عربي</span>}
                                    </li> */}
                                </div>
                                <div class="searchIcon fr">
                                    <a href="/search"><img src="/asset/images/Search.svg" alt="" /></a>
                                </div>
                                <div class="clr"></div>
                            </div>
                        </div>
                    </div>
                </header>
        )
    }
}

export default withRouter(Header);
