import React, { useState, useRef, useEffect } from "react";
import $ from 'jquery';
import axios from 'axios';
import { myConfig } from '../config';
import Header from './Header';
import { SettingsPhone } from "@material-ui/icons";
import Footer from "./Footer";
import lang from './lang.json'

function PageNotFound(props) {
    const [faqs , setFaqs] = useState([]);
    const [lan, setlan] = useState({})
    const [phone,getphone] = useState('');
    const [otp , getotp] = useState('');

    useEffect(()=> {
      localStorage.setItem("activetab","pagenotfound")
      var str = localStorage.getItem("lang")
      if (str == undefined) {
       str = localStorage.setItem("lang","en")
      }
      if(str == undefined) {
        setlan(lang.en);
      }else if(str == 'en') {
        setlan(lang.en);
      }else if(str == 'ar') {
        setlan(lang.ar)
      }
    })

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        myConfig.server_url+'listAllFAQ/',
        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64')}}
      );
      //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
      setFaqs(result.data.listData);
    };
    fetchData();
  }, []);


  const checknumber = (e) => {

    if (e.target.value.length == 10) {
      getphone(e.target.value)
      $("#msisdn").removeClass("buttondis");
    }else {
      $("#msisdn").addClass("buttondis"); 
    }
  }

    const checkotp = (e) => {
      if (e.target.value == 6) {
        getotp(e.target.value)
        $("#msisdn").removeClass("buttondis");
      }else {
        $("#msisdn").addClass("buttondis");
      }
    }

    const verifyotp = () => {
      if (otp != 'undefined') {
        if(otp.length==6){
            axios.get(myConfig.server_url+"validateOTP/?countryId=91&mobileNo="+phone+"&OTP="+otp,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
                .then(Response => {
                console.log(Response)
                //var str = Response.data.split("|");
                if (Response.data.otpStatusCode== '4000') {
                    //this.state.canLogin
                    $("#mobotp").hide()
                }else{
                  $("#mobotp").hide()
                }
                })  
        }else{
            $("#mobotp").show();
        }
    }else{
      $("mobotp").show();
    }
     
    // this.setState({ show:false });
      }

    const sendotp = () => {
      if (phone != 'undefined') {
        if(phone.length==10){
            axios.get(myConfig.server_url+"generateOTP/?countryId=91&mobileNo="+phone,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
            .then(Response => {
            //  console.log("yes")
              console.log(Response);
              if (Response.data.status == 201) {
                $('#verify').show()
                $('#subscribeMsisdn').hide()
              }
              
            })   
            $('#mob').hide();  
        }else{
          console.log("in");
          $('#mob').show();
        }
    }else{
        
        
    }
    }
  
  return (
    <>
      <Header></Header>
       <div className="landingPage">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="heading">404</div>
        <div className="subheading" style={{marginBottom:"10%"}}><span>Page Not Found</span></div>
            <div className="termsData"> <a href="/">click here</a>  to access the SSN.</div>
<div className="termsData"> <a href="/">click here</a> for support and help</div>
                </div>
            </div>
        </div>
    </div>  
    <Footer></Footer>
    </>
  );
}

export default PageNotFound;