import React, { useState, useRef, useEffect } from "react";
import $ from 'jquery';
import { useParams,useHistory } from "react-router-dom"
import axios from 'axios';
import { myConfig } from '../config';
import Header from "./Header";
import Footer from "./Footer";


function EditProfile(props) {
    const [faqs , setFaqs] = useState([]);
    const [active, setactive] = useState();
    const [countryCode, setcountryCode] = useState();
    const [createdBy, setcreatedBy] = useState();
    const [emailId, setemailId] = useState();
    const [loginType, setloginType] = useState();
    const [ids, setid] = useState();
    const [name, setname] = useState();
    const [image, setimage] = useState();
    const [phoneNumber, setphoneNumber] = useState();
    const [subscription, setsubscription] = useState();
    const [userId, setuserId] = useState();//setprofileId
    const [profileId, setprofileId] = useState();//
    const [imageSize, setimageSize] = useState(0);//
    const [imageShow, setImageShow] = useState(false);//
    let { id } = useParams();
    let history = useHistory();
    useEffect(() => {
        var login =  window.sessionStorage.getItem('isLogin')
        if (login == null) {
            history.push('/')
          }
    const fetchData = async () => {
        var apiurl = myConfig.server_url+'getSubscribedUser/'+window.sessionStorage.getItem("userId")
      const result = await axios(
        apiurl,
        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
      );
      //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
    //   setFaqs(result.data.listData);api
    appLog(apiurl, result)
    setcountryCode(result.data.countryCode)
    setemailId(result.data.emailId)
    setloginType(result.data.loginType)
    setname(result.data.name)
    setphoneNumber(result.data.phoneNumber)
    setuserId(result.data.userId)
    setprofileId(result.data.id)
    setimage(result.data.profileImage)
    };
    fetchData();
  }, []);

  function change(e) {
    if (e.target.name == "name") {
        setname(e.target.value)
    }
    if (e.target.name == "email") {
        setemailId(e.target.value)
    }
    if (e.target.name == "phone") {
        setphoneNumber(e.target.value)
    }
  }

  async function saveprofile () {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailId.match(mailformat)) {
        $('#emailerror').hide()
    }else {
        $("#emailerror").show()
        return
    }
    if(imageSize>51000){
        $("#imageerror").show()
        return
    }else{
        $('#imageerror').hide()
    }
    var addsub = {
        "countryCode":countryCode,
        "name": name,
        "emailId": emailId,
        "loginType":loginType,
        "profileImage":image,
        "phoneNumber": phoneNumber,
        "userId": ""+window.sessionStorage.getItem("userId"),//profileId
        "id":profileId
    }
    var apiurl = myConfig.server_url+"user/"
    axios.put(apiurl,addsub,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
    .then(Response => {
        localStorage.setItem('prfileImage',image)
        history.push('/profile')
        appLog(apiurl,Response) 
    })   
  }

  function uploadfile(e) {
    const file = e.target.files[0];
    setimageSize(e.target.files[0].size);
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
                .replace('data:', '')
                .replace(/^.+,/, '');
        setimage(base64String);
        setImageShow(true);
        $('#imageerror').hide();
        // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(file);
  }

  function back() {
    //   history.push('/profile')
      window.location.href = '/profile'
  }

  function appLog(url, res) {
    var browser = (function() {
        var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
        switch (true) {
            case test(/edg/i): return "Microsoft Edge";
            case test(/trident/i): return "Microsoft Internet Explorer";
            case test(/firefox|fxios/i): return "Mozilla Firefox";
            case test(/opr\//i): return "Opera";
            case test(/ucbrowser/i): return "UC Browser";
            case test(/samsungbrowser/i): return "Samsung Browser";
            case test(/chrome|chromium|crios/i): return "Google Chrome";
            case test(/safari/i): return "Apple Safari";
            default: return "Other";
        }
        })();
        if(window.sessionStorage.getItem('userId') != undefined) {
            var gettime = new Date();
            var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
            var finalobj6 = {
            security : "col#c4",
            logData : createanotherurl
            }
            axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
        }
    }

  return (
    <>
      <Header></Header>
      <div class="profilebase">
       <div class="container">
           <div class="row">
               <div class="col">
                   <div class="top">
                    <div class="image-upload image">
                        {
                            imageShow==false? <label for="file-input">
                            <img src={localStorage.getItem('prfileImage') == null || localStorage.getItem('prfileImage') == "null" ? "/asset/images/user.png" : `data:image/png;base64,${localStorage.getItem('prfileImage')}`} alt="" style={{borderRadius:"50%",width:"70px",height:"70px"}} />
                            <a>Upload</a>
                        </label>
                        : <label for="file-input">
                            <img src={`data:image/png;base64,${image}`} alt="" style={{borderRadius:"50%",width:"70px",height:"70px"}} />
                            <a>Upload</a>
                        </label>
                        }
                       

                        <input id="file-input" type="file" onChange={uploadfile}/>
                    </div>
                       {/* <div class="image">
                           <img src="/asset/images/USerImage.png" alt="" />
                           <a>Upload</a>
                       </div> */}
                   </div>
                   <div class="outSkirt">
                    <div>
                        <div class="label">Name</div>
                        <input type="text" name="name" value={name} onChange={change}/>
                    </div>
                    <div>
                        <div class="label">Email <label id="emailerror" style={{color:"#F02020",fontSize:"12px",display:"none"}}>Please Enter valid email</label></div>
                        <input type="email" name="email" onChange={change} value={emailId}/>                        
                    </div>
                    <div>
                        <div class="label">Phone Number</div>
                        <input type="label" name="phone" onChange={change} max={10} value={phoneNumber} readOnly />
                    </div>
                    <label id="imageerror" style={{color:"#F02020",fontSize:"12px",display:"none"}}>Image is too large, upload less then 50Kb.</label>
                    <div>
                        <button class="fl" onClick={back}>Back</button>
                        <button class="save fr" onClick={saveprofile}>Save</button>
                        <div class="clr"></div>
                    </div>
                   </div>
               </div>
           </div>
       </div>
     </div>
     <Footer></Footer>
    </>
  );
}
export default EditProfile;