import React, { useState, useRef, useEffect } from "react";
import { useParams,useHistory } from "react-router-dom"
import axios from 'axios';
import { myConfig } from '../config';
import Header from "./Header";
import Footer from "./Footer";


function TrasDetails(props) {
    const [faqs , setFaqs] = useState([]);
    const [active, setactive] = useState();
    const [countryCode, setcountryCode] = useState();
    const [createdBy, setcreatedBy] = useState();
    const [emailId, setemailId] = useState();
    const [loginType, setloginType] = useState();
    const [ids, setid] = useState();
    const [name, setname] = useState();
    const [phoneNumber, setphoneNumber] = useState();
    const [subscription, setsubscription] = useState();
    const [userId, setuserId] = useState();
    let { id } = useParams();
    let history = useHistory();
    useEffect(() => {
        var login =  window.sessionStorage.getItem('isLogin')
        if (login == null) {
            history.push('/')
          }
    const fetchData = async () => {
        var getuserId = window.sessionStorage.getItem('userId')
        var dialcode = window.sessionStorage.getItem('dialCode')
        var anotherapi = myConfig.billing_url+"transactionHistory.php?msisdn="+getuserId+"&opId=144"
        const result = await axios(
          anotherapi,
        );
      console.log(result);
        appLog(anotherapi, result)
        setFaqs(result.data.data)
    };
    fetchData();
  }, []);

  function change(e) {
    if (e.target.name == "name") {
        setname(e.target.value)
    }
    if (e.target.name == "email") {
        setemailId(e.target.value)
    }
    if (e.target.name == "phone") {
        setphoneNumber(e.target.value)
    }
  }

  async function saveprofile () {
    var addsub = {
        "countryCode":countryCode,
        "name": name,
        "emailId": emailId,
        "loginType":loginType,
        "phoneNumber": phoneNumber,
        "userId": window.sessionStorage.getItem("userId")
    }
    var apiurl = myConfig.server_url+"user/"
    axios.put(apiurl,addsub,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
    .then(Response => {
        history.push('/profile')
        appLog(apiurl,Response) 
    })   
  }

  function back() {
      history.push('/profile')
  }

  function appLog(url, res) {
    var browser = (function() {
        var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
        switch (true) {
            case test(/edg/i): return "Microsoft Edge";
            case test(/trident/i): return "Microsoft Internet Explorer";
            case test(/firefox|fxios/i): return "Mozilla Firefox";
            case test(/opr\//i): return "Opera";
            case test(/ucbrowser/i): return "UC Browser";
            case test(/samsungbrowser/i): return "Samsung Browser";
            case test(/chrome|chromium|crios/i): return "Google Chrome";
            case test(/safari/i): return "Apple Safari";
            default: return "Other";
        }
        })();
        if(window.sessionStorage.getItem('userId') != undefined) {
            var gettime = new Date();
            var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent + '|'+ url + '|' + JSON.stringify(res)
            var finalobj6 = {
            security : "col#c4",
            logData : createanotherurl
            }
            axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
        }
    }

  return (
    <>
      <Header></Header>
      <div>
       <div class="container">
           <div class="row">
               <div class="col">
                   <div class="top" style={{fontWeight: "900",textAlign: "center",marginTop: "10px",marginBottom: "39px",fontSize: "34px"}}>
                      
                           {/* <img src="/asset/images/USerImage.png" alt="" /> */}
                           <a style={{color:"white",textAlign:"center"}}>Transaction Details</a>

                   </div>
                   <div class="tbl-content">
                   <table cellpadding="0" cellspacing="0" border="0">
                        <thead>
                            <tr>
                            <th scope="col">#</th>
                            <th colspan="2" scope="col">BILL Tnx ID</th>
                            <th scope="col">TNX AMOUNT</th>
                            <th colspan="2" scope="col">OPERATOR</th>
                            <th scope="col">BILLING TYPE</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">TNX DATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            {faqs.length > 0 ? faqs.map((f,i) => {
                                return (
                                    <tr>
                                        <td scope="row">{i+1}</td>
                                        <td colspan="2">{f.BILL_TRANSID}</td>
                                        <td>{f.RATE}</td>
                                        <td colspan="2">{f.OPERATOR}</td>
                                        <td>{f.BILLING_TYPE}</td>
                                        <td>{f.STATUS}</td>
                                        <td>{f.ADDED_ON}</td>
                                    </tr>
                                )
                            }): ""}
                        </tbody>
                    </table>
                    </div>
               </div>
           </div>
       </div>
     </div>
     <Footer></Footer>
    </>
  );
}
export default TrasDetails;