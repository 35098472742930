import React, { useState, useRef, useEffect } from "react";
import $ from 'jquery';
import axios from 'axios';
import { myConfig } from '../config';
import Header from './Header';
import { SettingsPhone } from "@material-ui/icons";
import Footer from "./Footer";
import lang from './lang.json'

function InProgressPage(props) {
    const [faqs , setFaqs] = useState([]);
    const [lan, setlan] = useState({})
    const [phone,getphone] = useState('');
    const [otp , getotp] = useState('');
    const [dubai, setdubai] = useState(/dubai/)
    const [virgin, setvirgin] = useState(/virgin/)
    const [voda, setvoda] = useState(/voda/)
    const [beeline, setbeeline] = useState(/beeline/)
    const [tmobile, settmobile] = useState(/tmobile/)
    const [orange, setorange] = useState(/orange/)
    const [play, setplay] = useState(/play/)
    const [plus, setplus] = useState(/plus/)

    useEffect(()=> {
      localStorage.setItem("activetab","welcome")
      var str = localStorage.getItem("lang")
      if (dubai.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","en")
        }
      }else if(virgin.test(window.location.pathname) == true){
        if (str == undefined) {
          str = localStorage.setItem("lang","ven")
        }
      }else if(voda.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","voen")
        }
      }else if(beeline.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","boar")
        }
      }else if(tmobile.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","plten")
        }
      }else if(orange.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","ploen")
        }
      }else if(play.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","plpen")
        }
      }else if(plus.test(window.location.pathname) == true) {
        if (str == undefined) {
          str = localStorage.setItem("lang","plppen")
        }
      }
      
      if(str == 'en') {
        setlan(lang.en);
      }else if(str == 'ar') {
        setlan(lang.ar)
      }else if(str == 'var') {
        setlan(lang.var)
      }else if(str == 'ven') {
        setlan(lang.ven)
      }else if(str == 'voen') {
        setlan(lang.voen)
      }else if(str == 'boen') {
        setlan(lang.boen)
      }else if(str == 'boar') {
        setlan(lang.boar)
      }else if(str == 'plten') {
        setlan(lang.plten)
      }else if(str == 'ploen') {
        setlan(lang.ploen)
      }else if(str == 'plpen') {
        setlan(lang.plpen)
      }else if(str == 'plppen') {
        setlan(lang.plppen)
      }
    })

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
        myConfig.server_url+'listAllFAQ/',
        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64')}}
      );
      //http://13.126.46.199:8080/ssn/contentDetailBySubcategoryNCategory/?categoryId=1&subcategoryId=1&contentId=1&contrycode=91
      setFaqs(result.data.listData);
      
      const results = await axios(
        myConfig.server_url+'getSubscribedUser/'+window.sessionStorage.getItem("userId"),
        {headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}}
      );                       
      
      if (results.status == 200) {
        localStorage.setItem("name",results.data.name)
        localStorage.setItem("prfileImage", results.data.profileImage)
      }
    };
    fetchData();

    // setTimeout(() => {
    //   window.sessionStorage.setItem("isLogin", true);
    //   window.location.href = '/'
    // }, 3000);
  }, []);


  const checknumber = (e) => {

    if (e.target.value.length == 10) {
      getphone(e.target.value)
      $("#msisdn").removeClass("buttondis");
    }else {
      $("#msisdn").addClass("buttondis"); 
    }
  }

    const checkotp = (e) => {
      if (e.target.value == 6) {
        getotp(e.target.value)
        $("#msisdn").removeClass("buttondis");
      }else {
        $("#msisdn").addClass("buttondis");
      }
    }

    const verifyotp = () => {
      if (otp != 'undefined') {
        if(otp.length==6){
            axios.get(myConfig.server_url+"validateOTP/?countryId=91&mobileNo="+phone+"&OTP="+otp,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
                .then(Response => {
                console.log(Response)
                //var str = Response.data.split("|");
                if (Response.data.otpStatusCode== '4000') {
                    //this.state.canLogin
                    $("#mobotp").hide()
                }else{
                  $("#mobotp").hide()
                }
                })  
        }else{
            $("#mobotp").show();
        }
    }else{
      $("mobotp").show();
    }
     
    // this.setState({ show:false });
      }

    const sendotp = () => {
      if (phone != 'undefined') {
        if(phone.length==10){
            axios.get(myConfig.server_url+"generateOTP/?countryId=91&mobileNo="+phone,{headers:{"Authorization":"Basic "+Buffer.from(`${myConfig.user}:${myConfig.password}`, 'utf8').toString('base64'),"X-Content-Type-Options": "nosniff"}})
            .then(Response => {
            //  console.log("yes")
              console.log(Response);
              if (Response.data.status == 201) {
                $('#verify').show()
                $('#subscribeMsisdn').hide()
              }
              
            })   
            $('#mob').hide();  
        }else{
          console.log("in");
          $('#mob').show();
        }
    }else{
        
        
    }
    }
  
  return (
    <>
      <Header></Header>
       <div className="landingPage">
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="heading">{lan.product_name}</div>
        <div className="subheading" style={{marginBottom:"10%"}}><span>{lan.welcomeinprogress} </span>
        {lan.callback} </div>
            <div className="termsData"> <a href="/">click here</a>  to access the SSN.</div>
<div className="termsData"> <a href="/">click here</a> for support and help</div>
                </div>
            </div>
        </div>
    </div>  
    <Footer></Footer>
    </>
  );
}

export default InProgressPage;