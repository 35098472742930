import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";
import { myConfig } from '../src/config';
import axios from 'axios';
import Home from './Home';
import DetailPlayer from './DetailPlayer';
import Price from './Price';
import Search from './Search';
import ViewMore from './ViewMore';
import Games from './Games';
import Faq from './Faq';
import SportsNews from './SportsNews';
import Categories from './Categories';
import Privacy from './Privacy';
import Tnc from './Tnc';
import Landing from './Landing';
import LandingNew from './LandingNew';
import WelcomePage from './Pages/WelcomePage';
import Profile from './Pages/Profile'
import EditProfile from './Pages/EditProfile'
import FailResponse from './Pages/FailResponse';
import Subscribe from './Pages/Subscribe';
import PageNotFound from './Pages/PageNotFound';
import QueryMsisdn from './Pages/QueryMsisdn';
import TrasDetails from './Pages/TrsDetails';
import InProgressPage from './Pages/InProgressPage';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browser : '',
            isLogin:false,
        }
        console.log(this.state.islogincheck)
    }

    async componentDidMount() {
        var browser = (function() {
            var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
            switch (true) {
                case test(/edg/i): return "Microsoft Edge";
                case test(/trident/i): return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i): return "Mozilla Firefox";
                case test(/opr\//i): return "Opera";
                case test(/ucbrowser/i): return "UC Browser";
                case test(/samsungbrowser/i): return "Samsung Browser";
                case test(/chrome|chromium|crios/i): return "Google Chrome";
                case test(/safari/i): return "Apple Safari";
                default: return "Other";
            }
            })();
            if(window.sessionStorage.getItem('userId') != undefined) {
                var gettime = new Date();
                var createanotherurl = gettime + '|' + window.sessionStorage.getItem('userId') +'|'+window.location.pathname +'|'+1111+'|'+browser+'|'+222222+'|'+111111+'|'+navigator.userAgent
                var finalobj6 = {
                security : "col#c4",
                logData : createanotherurl
                }
                axios.post(myConfig.billing_url+"visitorLog.php",finalobj6)
            }
       }
       
    render() {
      return <Router>
          <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/details/:id/:scId/:cId" component={DetailPlayer} />
              {/* <Route exact path="/pl_tmobile/:pro" component={TmobilePage} />
              <Route exact path="/pl_orange/:pro" component={OrangePage} />
              <Route exact path="/pl_play/:pro" component={PlayPage} />
              <Route exact path="/pl_plus/:pro" component={PlusPage} />
              <Route exact path="/voda_qatar/:pro" component={VodaQatarPage} />
              <Route exact path="/virgin_saudi/:pro" component={VirginPage} />
              <Route exact path="/beeline_georgia/:pro" component={BeelinePage} />
              <Route exact path="/etisalat_dubai/:pro" component={DubaiPage} />
              <Route exact path="/grameen/:pro" component={GrameenPage} /> */}
              <Route exact path="/price" component={Price} />
              <Route exact path="/search" component={Search} />
              <Route exact path="/viewmore/:cId/:scId" component={ViewMore} />
              <Route exact path="/games" component={Games} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/sports-news" component={SportsNews} />
              <Route exact path="/categories/:cId/:ind" component={Categories} />
              <Route exact path="/welcome" component={WelcomePage} />
              <Route exact path="/progress" component={InProgressPage} />
              <Route exact path="/fail" component={FailResponse} />
              <Route exact path="/subscribe" component={Subscribe} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/tnc" component={Tnc} />
              <Route exact path="/:id/:pro" component={Landing} />
              <Route exact path="/landingPage/:id" component={Landing} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/editprofile" component={EditProfile} />
              <Route exact path="/transdetails" component={TrasDetails} />
              <Route exact path="/consentGateway/queryMsisdn/:vendorId/:serviceId/:tid" component={QueryMsisdn} />
              <Route exact path="" component={PageNotFound} />
          </Switch>
      </Router>
  }
}

export default App;